<template>
  <VRow class="w-100 pl-3" align-h="center" align-v="center">
    <VCol class="button-col">
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goTo('trade')">New FX Trade</VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsPayments" @click="goTo('payment')">Payments</VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goToCollateral"> Collateral Management </VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsPayments" @click="goTo('beneficiary-management')">
          Beneficiary Management
        </VButton>
      </div>
      <div>
        <VButton :disabled="!clientAllowsTrades" @click="goTo('trades')">All Trades</VButton>
      </div>
      <div>
        <VButton @click="goTo('documents')">Document Management</VButton>
      </div>
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { AuthorityType, Client, FeatureFlag, Permission } from 'ah-api-gateways';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { useFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';

type OboAction =
  | 'trade'
  | 'trades'
  | 'payment'
  | 'collateral-management'
  | 'beneficiary-management'
  | 'documents'
  | 'limits-collateral';

/**
 * On behalf of client actions
 *
 * Used by clients to executed action on behalf of a client
 *
 */
@Component
export default class ClientOnBehalfOfActions extends Vue {
  @Prop({ required: true }) client!: Client;

  @Prop({ default: [] }) clientPermissions!: Permission[];

  get clientAllowsTrades() {
    const permission = this.clientPermissions.find(
      (permission) => permission.authority === AuthorityType.TRADE_ON_BEHALF_OF
    );
    return permission ? permission.allow : false;
  }

  get clientAllowsPayments() {
    const permission = this.clientPermissions.find(
      (permission) => permission.authority === AuthorityType.PAY_ON_BEHALF_OF
    );
    return permission ? permission.allow : false;
  }

  private goTo(location: OboAction) {
    const baseUrl = `/admin/activity/clients/obo`;
    this.$router.push({ path: `${baseUrl}/${this.client.id}/${location}` });
  }

  private goToCollateral() {
    const { isActive: isVariationMarginFeatureActive, loadFlag: loadVariationMarginFeatureFlag } = useFeatureFlag({
      featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
    });
    loadVariationMarginFeatureFlag().then(() => {
      this.goTo(isVariationMarginFeatureActive.value ? 'limits-collateral' : 'collateral-management');
    });
  }
}
</script>

<style scoped lang="scss">
.button-col {
  div {
    text-align: center;
    padding: 25px 0;
    .btn {
      font-size: 16px;
      width: 12rem;

      @include tablet {
        position: relative;

        width: 14rem;
        ::v-deep div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
