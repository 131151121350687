<template>
  <div class="top-nav-holder public-nav">
    <div class="logo">
      <AppLogo class="logo-inner" />
    </div>
    <div class="login-button">
      <VButton v-if="isRegisterRoute || hasRegistrationSession" label="Login" @click="gotoLogin" class="btn-primary" />
      <span v-else><RouterLink to="/register" class="plain-color-link mr-4"> Register </RouterLink></span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AppLogo from '@/app/components/common/AppLogo.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    AppLogo,
  },
})
export default class PublicTopNav extends Vue {
  private authStore = useAuthStore();

  gotoLogin() {
    if (this.$route.path !== '/login') {
      this.$router.push('/login');
    }
  }

  get hasRegistrationSession() {
    return this.authStore.hasRegistrationSession;
  }

  get isRegisterRoute() {
    let isRegisterRoute = false;
    ['/email-confirm', '/register', '/landing-register', '/approve'].forEach((path) => {
      if (this.$route.path.includes(path)) isRegisterRoute = true;
    });
    return isRegisterRoute;
  }
}
</script>

<style lang="scss" scoped>
.public-nav {
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: 'icon nav-links user-icon-menu';

  .logo-title {
    @include responsive_font($min: 1, $max: 1.25);
    display: inline-block;
    vertical-align: middle;
    line-height: 44px;
    font-weight: $font-weight-semibold;
    padding: 0.25rem math.div($padded-space, 4);

    @include desktop {
      padding: math.div($padded-space, 4) math.div($padded-space, 2);
    }
  }

  .login-button {
    grid-area: user-icon-menu;
    vertical-align: middle;
    padding-right: 0.5em;
    align-self: center;

    a {
      text-decoration: none !important;
    }
  }

  .logo {
    width: $side-nav-width;
    height: $side-nav-width;
    margin-bottom: 1rem;
    position: relative;

    .logo-inner {
      width: $side-nav-width * 0.8;
      height: $side-nav-width * 0.8;
      position: absolute;
      top: $side-nav-width * 0.1;
      right: $side-nav-width * 0.1;
    }
  }
}
</style>
