<template>
  <div>
    <h4 v-if="titlesShown" class="col-inner-title">Notify To</h4>
    <span class="edit-icon-block">
      <NotificationTargetEditModal
        :config="config"
        :notification="notification"
        @update:notification="onNotificationUpdate"
        v-slot="{ show }"
      >
        <span @click="readonly === false && show()"
          ><IconPencil :class="['edit-icon', { disabled: readonly !== false }]"
        /></span>
      </NotificationTargetEditModal>
    </span>
    <span class="user-names"> <TargetListDescription :targets="notificationRecipients" /> </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { NotificationSetting, TargetSelection, NotificationSettingConfig } from 'ah-api-gateways';
import NotificationTargetEditModal from '@/app/components/settings/notifications/NotificationTargetEditModal.vue';
import TargetListDescription from '@/app/components/user/TargetListDescription.vue';

@Component({
  components: {
    NotificationTargetEditModal,
    TargetListDescription,
  },
})
export default class SingleNotificationTargetEditForm extends Vue {
  @Prop({ required: true }) config!: NotificationSettingConfig;

  @Prop({ required: true }) notification!: NotificationSetting;

  @Prop({ default: false }) showTitles!: boolean | string;

  @Prop({ default: false }) readonly!: boolean | string;

  notificationRecipients: TargetSelection[] = [];

  @Watch('notification', { immediate: true })
  resetData() {
    this.notificationRecipients = [...this.notification.to];
  }

  onNotificationUpdate(notification: NotificationSetting) {
    this.$emit('update:notification', notification);
    this.notificationRecipients = [...notification.to];
  }

  get hasTargets() {
    return this.notificationRecipients.length > 0;
  }

  get titlesShown() {
    return this.showTitles !== false;
  }
}
</script>

<style lang="scss" scoped>
.edit-icon-block {
  position: absolute;
  left: 5px;
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.circle-icon-wrapper {
  @include themedBackgroundColor($color-main-bg);
  width: 1.4em;
  height: 1.4em;
  display: inline-block;
  border-radius: 1.4em;
  text-align: center;
  position: relative;
  vertical-align: middle;

  svg {
    position: absolute;
    top: 10%;
    left: 10%;
    display: block;
    width: 80%;
    height: 80%;
  }
}

.user-names {
  display: block;
  padding-left: 1.7em;

  ::v-deep .target-list-item {
    word-break: break-word;
    display: inline-block;
  }
}
</style>
