<template>
  <VRow class="align-items-center single-rate-alert-settings">
    <VCol class="rate-alert-edit-col">
      <span class="edit-icon-block">
        <RateAlertSettingsEditModal
          :rateAlert="rateAlert"
          @update:rateAlert="$emit('update:rateAlert', $event)"
          @delete-rate-alert="$emit('delete-rate-alert')"
          v-slot="{ show }"
        >
          <span @click="readonly === false && show()"
            ><IconPencil :class="['edit-icon', { disabled: readonly !== false }]"
          /></span>
        </RateAlertSettingsEditModal>
      </span>
    </VCol>
    <VCol class="rate-alert-pair-col"> {{ rateAlertFromCurrency }} -> {{ rateAlertToCurrency }} </VCol>
    <VCol class="rate-alert-rate-col">
      {{ rateAlertInequalitySymbol }} {{ rateAlertRate }} {{ rateAlert.currencyPair }}
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { RateAlertSetting, RateAlertInequalityType } from 'ah-api-gateways';
import RateAlertSettingsEditModal from '@/app/components/settings/notifications/RateAlertSettingsEditModal.vue';
import { useSettingsStore } from '@/app/store/settingsModule';

@Component({
  components: {
    RateAlertSettingsEditModal,
  },
})
export default class SingleNotificationRateAlertSettingEditForm extends Vue {
  @Prop({ required: true }) rateAlert!: RateAlertSetting;

  @Prop({ default: false }) readonly!: boolean | string;

  get rateAlertFromCurrency() {
    return this.rateAlert.currencyPair.substr(0, 3);
  }

  get rateAlertToCurrency() {
    return this.rateAlert.currencyPair.substr(3);
  }

  get rateAlertRate() {
    return this.rateAlert.rate.toFixed(this.settingsStore.ratePrecision);
  }

  get rateAlertInequalitySymbol() {
    return this.rateAlert.inequality === RateAlertInequalityType.HIGHER_THAN ? '>' : '<';
  }

  get settingsStore() {
    return useSettingsStore();
  }
}
</script>

<style lang="scss">
.edit-icon-block {
  cursor: pointer;

  .edit-icon {
    @include themedTextColor($color-text-secondary);

    &:not(.disabled):hover {
      @include themedTextColor($color-primary);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}
</style>
