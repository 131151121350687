<script setup lang="ts">
import { useFeatureFlag } from 'ah-common-stores';
import { FeatureFlag } from 'ah-api-gateways/models/featureFlag';
import OldTradePriceMarginInfo from './tradePriceMarginInfoViews/OldTradePriceMarginInfo.vue';
import TradePriceMarginInfo from './tradePriceMarginInfoViews/TradePriceMarginInfo.vue';

/**
 * FIXME - This component serves the purpose to render the new or old flow of forward trades (US's PT-1828 and PT-1829)
 * This will exist until we remove the VARIATION_MARGIN_AND_LIMITS_FEATURE feature flag
 * After that the old behavior will be removed and we will only keep the new one, but for now we need a fallback
 * */

defineProps({
  boxGridBlockProps: {
    type: Object,
    required: true,
  },
});

const { isActive: isVariationMarginFeatureActive } = useFeatureFlag({
  featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
});
</script>

<template>
  <TradePriceMarginInfo v-if="isVariationMarginFeatureActive" v-bind="$attrs" v-on="$listeners" />
  <div class="w-100" v-else>
    <BoxGridBlock v-bind="boxGridBlockProps">
      <h3>Margin Requirements</h3>
      <OldTradePriceMarginInfo v-bind="$attrs" v-on="$listeners" />
    </BoxGridBlock>
  </div>
</template>
