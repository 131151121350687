<template>
  <span v-if="!targetDescriptions.length" class="target-list no-targets">{{ noTargetsText }}</span>
  <span v-else class="target-list">
    <span class="target-list-item" v-for="(target, index) in targetDescriptions" :key="index">
      {{ target }}<span v-if="index < targetDescriptions.length - 1">,</span>
    </span>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  TargetSelection,
  IndividualTargetSelection,
  GroupTargetSelection,
  PhoneTargetSelection,
  EmailTargetSelection,
  targetSelectionName,
  targetSelectionId,
} from 'ah-api-gateways';

type RecipientType = 'group' | 'user' | 'phone' | 'email' | 'allUsers';

const RecipientTypeCountLabels: Record<RecipientType, string> = {
  group: 'group',
  user: 'user',
  phone: 'phone number',
  email: 'email',
  allUsers: 'All team members',
};

@Component
export default class TargetListDescription extends Vue {
  @Prop({ default: () => [] }) targets!: TargetSelection[];

  @Prop({ default: 4 }) max!: number;

  @Prop({ default: `No targets found` }) noTargetsText!: string;

  get isAllUsers() {
    return this.targets.length === 0;
  }

  get userRecipients() {
    return this.targets.filter((i) => (i as IndividualTargetSelection).individual) as IndividualTargetSelection[];
  }

  get groupRecipients() {
    return this.targets.filter((i) => (i as GroupTargetSelection).group) as GroupTargetSelection[];
  }

  get phoneRecipients() {
    return this.targets.filter((i) => (i as PhoneTargetSelection).phoneNumber) as PhoneTargetSelection[];
  }

  get emailRecipients() {
    return this.targets.filter((i) => (i as EmailTargetSelection).email) as EmailTargetSelection[];
  }

  get targetDescriptions() {
    const out: string[] = [];

    let parts: RecipientType[] = ['phone', 'email'];
    if (this.isAllUsers) {
      parts = this.sortByAmount(parts);
      parts.unshift('allUsers');
    } else {
      parts.push('group', 'user');
      parts = this.sortByAmount(parts);
    }

    if (parts[0] === 'allUsers') {
      out.push(RecipientTypeCountLabels[parts[0]]);
      parts.splice(0, 1);
    }

    while (parts.length) {
      if (out.length + parts.reduce((count, part) => this.getRecipentArray(part).length + count, 0) > this.max) {
        let count = this.getRecipentArray(parts[0]).length;
        out.push(`${count} ${RecipientTypeCountLabels[parts[0]]}${count > 1 ? 's' : ''}`);
      } else {
        let array = this.getRecipentArray(parts[0]);
        array.forEach((el) => {
          out.push(targetSelectionName(el));
        });
      }

      parts.splice(0, 1);
    }

    return out;
  }

  targetSelectionId(el: TargetSelection) {
    return targetSelectionId(el);
  }

  getRecipentArray(name: RecipientType) {
    return (this as any)[name + 'Recipients'] as any[] as TargetSelection[];
  }

  sortByAmount(arr: RecipientType[]) {
    return [...arr]
      .filter((i) => this.getRecipentArray(i).length > 0)
      .sort((a, b) => this.getRecipentArray(b).length - this.getRecipentArray(a).length);
  }
}
</script>
