<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { of, Observable, timer } from 'rxjs';
import { PaginatedQuery, PaginatedResponse, PaginatedParams, DocumentExport } from 'ah-api-gateways';
import { RequestManager } from 'ah-requests';
import { take, map } from 'rxjs/operators';
import { cloneDeep, isEqual, noop, keysIn, isEmpty, DebouncedFunc, debounce } from 'lodash';
import { editRoute } from '../../../helpers/route';
import { onAsyncFileDownload } from '../../../listing/listingConfig';

export interface ListingTriggers<T> {
  downloadData: (type: string, options?: { [key: string]: any }) => void;
  loadData: () => void;
  loadDataRequest: (query: PaginatedQuery) => Observable<PaginatedResponse<T>>;
}

// FIXME delete file after moving all files to composition api
/**
 * Base Listing component
 *
 * Used by extending and setting the correct data load and download requests, as well as any initial setup
 *
 * Emits:
 * - download-requested (payload - download response): emitted when data download request finishes. In async downloads, response is a Document object
 * - download-request-error (no payload): emitted when download request fails
 * - data-loaded (no payload): emitted when data finishes loading
 * - data-load-error (no payload): emitted when data fails to load
 * - update:dataLoadState: emitted when data loading state changes. Can be used with .sync (prop is ignored)
 * - update:dataDownloadState: emitted when data downloading state changes. Can be used with .sync (prop is ignored)
 * - update:tableData: emitted when `tableData` changes. Can be used with .sync
 * - update:filter: emitted when `filter` changes. Can be used with .sync
 * - update:sortAndPageParams: emitted when `sortAndPageParams` changes. Can be used with .sync
 * - update:triggers: emitted on load, exposing object with load and download trigger methods. Can be used with .sync
 */
@Component
export default class Listing<T> extends Vue {
  protected reqManager = new RequestManager();

  /**
   * Filter to apply to the table
   *
   * Only changes to the filter will result in a refresh of the data:
   * if the filter is kept the same, even if the object reference is changed, table will not refresh
   *
   * Any sorting/pagination keys, and the data prop, are ignored.
   *
   * Synchronizable via .sync
   */
  @Prop({ default: () => ({}) }) filter!: any;

  protected filterInner: any = {};

  /**
   * Sorting to apply to the table
   *
   * Only value changes to the sorting will result in a refresh of the data:
   * if the sort values are kept the same, even if the object reference is changed, table will not refresh
   *
   * Only sorting and pagination keys will be checked and imported.
   *
   * Synchronizable via .sync
   */
  @Prop({ default: () => ({}) }) sortAndPageParams!: Partial<PaginatedParams>;

  private sortAndPageParamsInner: Partial<PaginatedParams> = {};

  /**
   * Table data
   *
   * Setting this value will update the table data, minus filters or pagination
   *
   * Synchronizable via .sync
   */
  @Prop({ default: () => ({}) }) tableData!: Partial<PaginatedResponse<T>>;

  /**
   * Whether to save pagination in URL via a querystring parameter
   *
   * May be a string representing the prefix under which to save data, or an empty string to disable this feature
   * Defaults to empty string
   */
  @Prop({ default: '' }) paginationQueryParam!: string;

  /**
   * Whether to save filter in URL via a querystring parameter
   *
   * May be a string representing the prefix under which to save data, or an empty string to disable this feature
   * Defaults to empty string
   */
  @Prop({ default: '' }) filterQueryParam!: string;

  /**
   * Whether to push, rather than replace to, the browser history on filter change
   */
  @Prop({ default: false }) protected pushRouteOnFilter!: boolean;

  /**
   * Whether to push, rather than replace to, the browser history on sorting change
   */
  @Prop({ default: false }) protected pushRouteOnSort!: boolean;

  /**
   * Which items are currently selected
   *
   * Selection is passed on to the DynamicTable, but selection behavior is expected to be managed externally
   */
  @Prop({ default: () => [] }) selectedItems!: string[];

  // Currently loaded query, for checking if a reload should happen
  private currentQuery: any = null;

  data: PaginatedResponse<T> = {
    total: 0,
    list: [],
  };

  /**
   * Debounced method to check for changes to the filters/query and trigger a load
   *
   * This method is debounced to allow multiple update sources to act before triggering the load requests
   * (url query string, external defaults, etc.)
   */
  private loadDataIfChanged!: DebouncedFunc<() => void>;

  created() {
    this.loadDataIfChanged = debounce(() => {
      if (!isEqual(this.currentQuery, this.makeQuery())) {
        this.loadData();
      } else {
        this.syncSorting();
        this.syncFilter();
        this.syncRouteFromParams(true);
      }
    }, 50);

    this.$emit('update:triggers', {
      downloadData: (type: string, options?: { [key: string]: any }) => this.downloadData(type, options),
      loadData: () => this.loadData(),
      loadDataRequest: (query: PaginatedQuery) => this.loadDataRequest(query),
    } as ListingTriggers<T>);
  }

  /**
   * Before mount, we sync data as such:
   * - load data from props
   * - override with data stored in query string, if any
   * - load data after sync
   */
  beforeMount() {
    this.updateInternalSortAndPageParams();
    this.updateInternalFilter();
    this.syncParamsFromRoute();
    this.onDataChange();
    this.loadDataIfChanged();
  }

  beforeDestroy() {
    this.reqManager.clear();
  }

  @Watch('filterQueryParam')
  @Watch('paginationQueryParam')
  onQueryParamChange() {
    this.updateInternalSortAndPageParams();
    this.updateInternalFilter();
    this.syncParamsFromRoute();
    this.loadDataIfChanged();
  }

  @Watch('$route')
  onRouteChange() {
    this.syncParamsFromRoute();
    this.loadDataIfChanged();
  }

  @Watch('filter', { deep: true })
  onFilterChange() {
    this.updateInternalFilter(true);
  }

  @Watch('sortAndPageParams', { deep: true })
  onSortAndPageParamsChange() {
    this.updateInternalSortAndPageParams(true);
  }

  syncParamsFromRoute() {
    if (this.paginationQueryParam && typeof this.$route.query[this.paginationQueryParam] === 'string') {
      const paginationStr = this.$route.query[this.paginationQueryParam];
      if (typeof paginationStr === 'string') {
        try {
          const qStringParams = JSON.parse(paginationStr);
          if (!isEqual(qStringParams, this.sortAndPageParamsInner)) {
            this.sortAndPageParamsInner = qStringParams;
            this.syncSorting();
          }
        } catch (e) {}
      }
    }
    if (this.filterQueryParam && typeof this.$route.query[this.filterQueryParam] === 'string') {
      const filterStr = this.$route.query[this.filterQueryParam];
      if (typeof filterStr === 'string') {
        try {
          const qStringParams = JSON.parse(filterStr);
          if (!isEqual(qStringParams, this.filterInner)) {
            this.filterInner = qStringParams;
            this.syncFilter();
          }
        } catch (e) {}
      }
    }
  }

  /**
   * Syncs the route from the existing query and sorting parameters
   *
   * no history step is added (use of router.replaced is forced) if:
   *  - softUpdate is true
   *  - useReplace prop is true
   *  - no query string params exist yet
   *
   */
  syncRouteFromParams(softUpdate = false) {
    setTimeout(() => {
      const queryParams: any = {};
      if (this.paginationQueryParam) {
        if (!isEmpty(this.sortAndPageParamsInner)) {
          queryParams[this.paginationQueryParam] = JSON.stringify(this.sortAndPageParamsInner);
        } else {
          queryParams[this.paginationQueryParam] = undefined;
        }
      }
      if (this.filterQueryParam) {
        if (!isEmpty(this.filterInner)) {
          queryParams[this.filterQueryParam] = JSON.stringify(this.filterInner);
        } else {
          queryParams[this.filterQueryParam] = undefined;
        }
      }

      const route = editRoute(this.$route, {
        query: { add: queryParams },
      });
      if (isEqual(this.$route.query, route.query)) return;

      const pushFilter = this.pushRouteOnFilter !== false && this.$route.query[this.filterQueryParam];

      const pushSort = this.pushRouteOnSort !== false && this.$route.query[this.paginationQueryParam];

      if (!softUpdate && (pushFilter || pushSort)) {
        this.$router.push(route).catch(noop);
      } else {
        this.$router.replace(route).catch(noop);
      }
    });
  }

  @Watch('tableData', { deep: true })
  onDataChange() {
    this.data = {
      ...this.data,
      ...this.tableData,
    };
  }

  updateInternalFilter(loadDataIfChanged = false) {
    const { sort, sortDirection, pageNumber, pageSize, total, data, ...filterIn } = this.filter;
    const transformed = this.filterTransform(filterIn);
    if (!isEqual(transformed, this.filterInner)) {
      this.filterInner = transformed;
      if (loadDataIfChanged) {
        this.loadDataIfChanged();
      }
    }
  }

  updateInternalSortAndPageParams(loadDataIfChanged = false) {
    const sortIn: Partial<PaginatedParams> = {};
    keysIn(this.sortAndPageParams).forEach((k) => {
      if (['sort', 'sortDirection', 'pageNumber', 'pageSize'].includes(k)) {
        const key: keyof PaginatedParams = k as keyof PaginatedParams;
        sortIn[key] = this.sortAndPageParams[key] as any;
      }
    });

    const newSort = {
      ...this.sortAndPageParamsInner,
      ...sortIn,
    };
    if (!isEqual(newSort, this.sortAndPageParamsInner)) {
      this.updateSortingFromData(newSort);
      if (loadDataIfChanged) {
        this.loadDataIfChanged();
      }
    }
  }

  @Watch('dataLoadState', { immediate: true })
  onDataLoadStateChange() {
    this.$emit('update:dataLoadState', this.dataLoadState);
  }

  @Watch('dataDownloadState', { immediate: true })
  onDataDownloadStateChange() {
    this.$emit('update:dataDownloadState', this.dataDownloadState);
  }

  protected get dataLoadState() {
    return this.reqManager.requestStates.loadData;
  }

  protected get dataDownloadState() {
    return this.reqManager.requestStates.downloadData;
  }

  /**
   * Filter transformation function - this will transform filters before they are saved, and will affect things link url-based persistence.
   * May be overwritten in components extending Listing - by default, does no transformation to the filter;
   */
  protected filterTransform(filterIn: any) {
    return { ...filterIn };
  }

  /**
   * Query transformation function - this will transform the query prior tomaking the request, and will NOT affect things link url-based persistence.
   * May be overwritten in components extending Listing - by default, does no transformation to the query;
   */
  protected queryTransform(query: any, _forDownload: boolean) {
    return { ...query };
  }

  /**
   * Request for downloading data. Should be overwritten in components extending Listing
   */
  protected downloadDataRequest(
    _type: string,
    _query: { [key: string]: any },
    _options: { [key: string]: any }
  ): Observable<DocumentExport | void> {
    return timer(5000)
      .pipe(take(1))
      .pipe(map(() => undefined));
  }

  /**
   * Request for loading data. Should be overwritten in components extending Listing
   */
  protected loadDataRequest(_query: PaginatedQuery): Observable<PaginatedResponse<T>> {
    return of({
      total: 0,
      list: [],
    });
  }

  protected makeQuery(forDownload = false, newQuery?: PaginatedQuery) {
    const { list, total, ...query } = {
      pageNumber: this.data?.pageNumber ?? 0,
      pageSize: this.data?.pageSize ?? 10,
      ...this.data,
    };

    /**
     * Delete from current query any undefined filter properties in the new query
     */
    Object.keys(query).forEach((key) => {
      if (['sort', 'sortDirection', 'pageNumber', 'pageSize'].includes(key)) return;
      if (this.filterInner[key] === undefined) {
        delete (query as any)[key];
      }
    });

    /**
     * Assign, in order of priority:
     * - currently set page and sort params
     * - currently set filters
     * - newQuery (either from programatic usage or table sorting/pagination)
     */
    Object.assign(query, this.sortAndPageParamsInner, this.filterInner, newQuery || {});

    if (forDownload) {
      delete (query as any).pageNumber;
      delete (query as any).pageSize;
    }

    return this.queryTransform(query, forDownload);
  }

  // loadDataIfChanged() {
  //   if (!isEqual(this.currentQuery, this.makeQuery())) {
  //     this.loadData();
  //   } else {
  //     this.syncSorting();
  //     this.syncFilter();
  //     this.syncRouteFromParams(true);
  //   }
  // }

  cancelLoadRequest(key: 'loadData' | 'downloadData') {
    this.reqManager.cancel(key);
  }

  loadData(newQuery?: PaginatedQuery, backgroundLoading = false, isReQuery = false): Promise<PaginatedResponse<T>> {
    const query = this.makeQuery(false, newQuery);

    return new Promise((resolve, reject) => {
      this.currentQuery = query;
      this.reqManager.cancel('loadBackgroundData');
      this.reqManager
        .sameOrCancelAndNew(backgroundLoading ? 'loadBackgroundData' : 'loadData', this.loadDataRequest(query), query)
        .subscribe(
          (data) => {
            const newData: PaginatedResponse<T> = {
              sort: query.sort || undefined,
              sortDirection: query.sort ? query.sortDirection || 'DESC' : undefined,
              pageNumber: (data as any).page ?? data.pageNumber ?? query.pageNumber,
              pageSize: (data as any).size ?? data.pageSize ?? query.pageSize,
              ...data,
            };

            this.data = newData;

            const maxPage = this.data.pageSize
              ? Math.max(0, Math.ceil((this.data.total ?? 0) / this.data.pageSize) - 1)
              : 0;

            // Protecting against pagination issues:
            // if loading page X+1 or higher and there are only X pages,
            // reset to highest available page number a re-query
            if (!isReQuery && (this.data.pageNumber ?? 0) > maxPage) {
              resolve(this.loadData({ pageNumber: maxPage }, backgroundLoading, true));
            } else {
              this.updateSortingFromData(this.data);
              this.currentQuery = this.makeQuery();
              this.syncTableData();
              this.syncSorting();
              this.syncFilter();
              this.syncRouteFromParams();
              this.$emit('data-loaded');
              resolve(this.data);
            }
          },
          () => {
            this.data = {
              ...this.data,
              list: [],
            };
            this.$emit('data-load-error');
            reject();
          },
          () => {
            reject();
          }
        );
    });
  }

  syncTableData() {
    this.$emit('update:tableData', cloneDeep(this.data));
  }

  updateSortingFromData(data: Partial<PaginatedParams>) {
    this.sortAndPageParamsInner = {
      sort: data.sort,
      sortDirection: data.sortDirection,
      pageNumber: data.pageNumber,
      pageSize: data.pageSize,
    };
  }

  syncSorting() {
    if (!isEqual(this.sortAndPageParams, this.sortAndPageParamsInner)) {
      this.$emit('update:sortAndPageParams', { ...this.sortAndPageParamsInner });
    }
  }

  syncFilter() {
    if (!isEqual(this.filter, this.filterInner)) {
      this.$emit('update:filter', cloneDeep(this.filterInner));
    }
  }

  downloadData(type: string, options: { [key: string]: any } = {}) {
    const query = this.makeQuery(true);

    this.reqManager.sameOrCancelAndNew('downloadData', this.downloadDataRequest(type, query, options), query).subscribe(
      (response) => {
        this.$emit('download-requested', response);
        if ((response as DocumentExport)?.type) {
          onAsyncFileDownload(response as DocumentExport);
        }
      },
      (error) => this.$emit('download-request-error', error)
    );
  }
}
</script>
