<script setup lang="ts">
import { RawLocation } from 'vue-router';
import AmountDisplay from '@/app/components/wallets/AmountDisplay.vue';
import { computed, onBeforeMount } from 'vue';
import { useAhTradesState } from '../..';
import { useRouter } from 'vue-router/composables';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = defineProps<{
  redirectTo: RawLocation;
}>();

const tradeState = useAhTradesState();

const onBehalfOf = useOnBehalfOf();

const router = useRouter();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: loadCollaterals,
}).manager;

function loadCollaterals() {
  requestManager.sameOrNewPromise(
    'loadCollaterals',
    () => tradeState.store.useWalletsStore().loadCollaterals({ force: true, clientId: onBehalfOf.value?.id }),
    null
  );
}

onBeforeMount(loadCollaterals);

const collaterals = computed(() => {
  const clientId = onBehalfOf.value
    ? onBehalfOf.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

function goToCollateral() {
  router.push(props.redirectTo);
}
</script>

<template>
  <LoadingOverlay :state="requestManager.currentStates">
    <div v-if="collaterals">
      <AmountDisplay
        :currency="collaterals.currency"
        :value="collaterals.effectiveCollateral"
        label="Effective collateral"
      />
      <AmountDisplay
        :currency="collaterals.currency"
        :value="collaterals.totalMarginRequirement"
        label="Total Margin Requirement (in use)"
      />
      <AmountDisplay
        :currency="collaterals.currency"
        :value="collaterals.marginCollateralAvailable"
        label="Margin Collateral Available (to trade)"
      />
      <DashboardViewAllButton @click="goToCollateral" v-if="redirectTo" />
    </div>
  </LoadingOverlay>
</template>
