<template>
  <div>
    <div class="rows-block d-md-block d-sm-none" v-if="client">
      <DataRow xl="5" lg="6" md="6" sm="7" label="Onboarding Date" class="text-wrap mb-2 client-onboarding-date">
        <span class="text-nowrap"> {{ formatDate(client.createdAt, SIMPLE_DATE_FORMAT) }} </span>
      </DataRow>
      <DataRow xl="5" lg="6" md="6" sm="7" label="Assignees" class="mb-2 client-assignees">
        {{ clientAssignees.length }}
      </DataRow>
      <DataRow xl="5" lg="6" md="6" sm="7" label="Users" class="mb-5 client-users">
        <ClientAssigneesList class="fee-words-wrap mr-2" :client="client" ref="assigneesList" />
      </DataRow>
    </div>
    <div class="text-center">
      <ClientAssigneesModal @saved-assignees="loadAssignees" :client="client" v-slot="{ show }">
        <VButton @click="show" class="btn-secondary">Edit Assignees</VButton>
      </ClientAssigneesModal>
    </div>
  </div>
</template>

<script lang="ts">
import { Client, AssigneeReference } from 'ah-api-gateways';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import ClientAssigneesModal from '../ClientAssigneesModal.vue';
import ClientAssigneesList from '../ClientAssigneesList.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { formatDate, SIMPLE_DATE_FORMAT } from 'ah-common-lib/src/helpers/time';

/**
 * Client Assignees Info block
 *
 * Emits:
 * - saved-assignees (payload: AssigneeReference[]) - remitted from ClientAssigneesModal
 */
@Component({
  components: {
    ClientAssigneesModal,
    ClientAssigneesList,
  },
})
export default class ClientAssigneeInfo extends Mixins(WithRequestManager) {
  @Prop({ default: null }) client!: Client | null;

  clientAssignees: AssigneeReference[] = [];

  formatDate = formatDate;

  SIMPLE_DATE_FORMAT = SIMPLE_DATE_FORMAT;

  $refs!: {
    assigneesList: ClientAssigneesList;
  };

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      this.loadAssignees();
    }
  }

  @Watch('client.id', { immediate: true })
  loadAssignees() {
    if (this.client) {
      this.requestManager
        .sameOrCancelAndNew('getAssignees', this.$services.client.getClientAssignees(this.client.id), this.client.id)
        .subscribe((clientAssignees) => {
          this.clientAssignees = clientAssignees;
          this.$refs.assigneesList.loadAssignees();
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.fee-words-wrap {
  white-space: break-spaces;
}
</style>
