<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="clientAssignees && requestManager.requestStates.getAssignees !== 'error'">
      <span v-if="sortedAssignees.length === 0" class="text-secondary"> No Assignees </span>
      <span v-else-if="maxItems === 0">{{ extraAssigneeCount }} Assignees</span>
      <template v-else>
        <span v-for="(assignee, index) in sortedAssignees" :key="assignee.individual.id">
          {{ assignee.individual.firstName }} {{ assignee.individual.lastName }}
          <span v-if="index < sortedAssignees.length - 1">,</span>
        </span>
        <span v-if="extraAssigneeCount > 0">+{{ extraAssigneeCount }} others</span>
      </template>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import UserIcon from '@/app/components/user/UserIcon.vue';
import { Client, AssigneeReference } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component({
  components: {
    UserIcon,
    LoadingIcon,
  },
})
export default class ClientAssigneesList extends Mixins(WithRequestManager) {
  @Prop({ required: true }) client!: Client;

  /**
   * Max number of items shown. If 0, shows only count of assignees
   */
  @Prop({ default: 3 }) maxItems!: number;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getAssignees') {
      this.loadAssignees();
    }
  }

  clientAssignees: AssigneeReference[] = [];

  get sortedAssignees() {
    if (this.clientAssignees) {
      const primary = this.clientAssignees.find((a) => a.primary === true)!;
      return [...(primary ? [primary] : []), ...this.clientAssignees.filter((a) => a.primary === false)].slice(
        0,
        this.maxItems
      );
    }
    return [];
  }

  get extraAssigneeCount() {
    if (this.clientAssignees) {
      return this.clientAssignees.length - this.sortedAssignees.length;
    }
    return 0;
  }

  @Watch('client.id', { immediate: true })
  loadAssignees() {
    this.requestManager
      .sameOrCancelAndNew(
        'getAssignees',
        this.$services.client.getClientAssignees(this.client.id, { errors: { silent: true } }),
        this.client.id
      )
      .subscribe((clientAssignees) => {
        this.clientAssignees = clientAssignees;
      });
  }
}
</script>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
