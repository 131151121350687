<script setup lang="ts">
import { makeFormModel, setState, getChildModel, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { financialAmountField } from 'ah-common-lib/src/form/models';
import { optional, smallerThanOrEqualsParam } from 'ah-common-lib/src/form/validators';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, reactive, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const transactionFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'transactionForm',
    title: '',
    fieldType: 'form',
    fields: [
      financialAmountField(
        'amount',
        '',
        {
          availableBalance: 0,
          errorMessages: {
            operationMaximum: "You don't have enough funds to perform this operation.",
          },
        },
        {
          operationMaximum: optional(smallerThanOrEqualsParam('amount', 'availableBalance')),
        }
      ),
    ],
  }),
  validation: null,
});

const props = withDefaults(
  defineProps<{
    type: 'post' | 'withdraw';
    currency: string;
    model?: { amount?: number };
  }>(),
  {
    model: () => ({}),
  }
);

const emit = defineEmits<{
  (e: 'update:model', value: { amount?: number }): void;
}>();

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const isPartnerUser = computed(() => !onBehalfOfClient.value && !tradeState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  }
  return (
    tradeState.store.useAuthStore().loggedInIdentity?.client?.id ||
    tradeState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

const wallet = computed(() => {
  return tradeState.store.useWalletsStore().getWallet(props.currency, {
    isPartner: isPartnerUser.value,
    id: ownerId.value,
  });
});

const availableBalance = computed(() => {
  if (!wallet.value) {
    return 0;
  }
  return (props.type === 'post' ? wallet.value.availableBalance : wallet.value.collateralBalance) ?? 0;
});

watch(
  () => [wallet, props.type],
  () => {
    const amountField = getChildModel(transactionFormDef.form, 'amount')!;

    if (wallet.value) {
      transactionFormDef.form.amount = Math.min(transactionFormDef.form.amount, wallet.value.availableBalance);
    }

    setState(amountField, 'readonly', availableBalance.value === 0);
    setState(amountField, 'availableBalance', availableBalance.value);
    setState(amountField, 'errorMessages', {
      operationMaximum:
        props.type === 'post'
          ? `${!onBehalfOfClient.value ? "You don't" : "Client doesn't"} have enough funds to perform this operation.`
          : 'Exceeded the amount of collateral available in this currency.',
    });
  },
  { immediate: true }
);

watch(
  () => props.currency,
  () => {
    tradeState.store.useWalletsStore().loadCurrencyWallet({
      currency: props.currency,
      force: true,
      owner: onBehalfOfClient.value ? { isPartner: isPartnerUser.value, id: ownerId.value } : undefined,
    });
  },
  { immediate: true }
);

watch(
  () => props.model,
  () => {
    if (props.model) {
      updateModel(transactionFormDef.form, props.model);
    }
  },
  { immediate: true }
);

function onFormEvent() {
  emit('update:model', toDataModel(transactionFormDef.form));
}
</script>

<template>
  <VRow align-h="end">
    <VCol cols="4" class="text-left pt-1">
      <span>{{ currency }} {{ formatCurrencyValue(availableBalance) }}</span>
    </VCol>
    <VCol cols="8">
      <ValidatedForm
        :fm="transactionFormDef.form"
        v-if="transactionFormDef.form"
        @form-event="onFormEvent"
        @update:validation="$emit('update:validation', $event)"
      />
    </VCol>
  </VRow>
</template>
