<script setup lang="ts">
import CollateralWalletFundActions from './CollateralWalletFundActions.vue';
import CollateralTransaction from './CollateralTransaction.vue';
import { ClientCollaterals, SpotLiveRate } from 'ah-api-gateways';
import { combineLatest } from 'rxjs';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, reactive, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

/**
 * Collateral Actions
 *
 * Re-emits CollateralTransaction events
 */

const state = reactive<{
  actionType: 'choose' | 'withdraw' | 'post';
  currencies: string[];
  marketRates: SpotLiveRate[] | null;
}>({
  actionType: 'choose',
  currencies: ['GBP', 'EUR', 'USD'],
  marketRates: null,
});

const requestManager = useRequestManager({ exposeToParent: false });

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const isPartnerUser = computed(() => !onBehalfOfClient.value?.id && !tradeState.store.useAuthStore().isClientUser);

const owner = computed(() => {
  return {
    isPartner: isPartnerUser.value && !onBehalfOfClient.value,
    id: onBehalfOfClient.value?.id || tradeState.store.useAuthStore().loggedInIdentity?.client?.id,
  };
});

const totalEquivalent = computed(() => {
  let total = 0;

  state.currencies.forEach((c) => {
    const wallet = tradeState.store.useWalletsStore().getWallet(c, owner.value);
    if (wallet) {
      if (state.actionType === 'post') {
        const rate = state.marketRates && state.marketRates.find((rate) => rate.currencyPair.startsWith(c));
        total += wallet.balance * (rate?.spotRate ?? 1);
      } else {
        total +=
          wallet.balance * ((collaterals.value && (collaterals.value as any)[`${c.toLowerCase()}ConversionRate`]) ?? 1);
      }
    }
  });

  // Round to 2 decimal places
  if (total) {
    total = Math.round(total * 100) / 100;
  }
  return total;
});

const notEnoughFunds = computed(
  () => (collaterals.value?.recommendedRecapitalizationAmount ?? 0) >= totalEquivalent.value
);

const displayWarning = computed(
  () => notEnoughFunds.value && !onBehalfOfClient.value && state.actionType !== 'withdraw'
);

function actionTypeChanged(actionType: 'choose' | 'withdraw' | 'post') {
  state.actionType = actionType;
}

function loadLiveRates() {
  const currencyPairs = state.currencies
    .filter((curr) => curr !== collaterals.value!.currency)
    .map((curr) => `${curr}${collaterals.value!.currency}`);

  requestManager.manager
    .sameOrCancelAndNew(
      'loadLiveRates',
      combineLatest(currencyPairs.map((pair) => tradeState.services.rates.spotLiveRate(pair)))
    )
    .subscribe((rates) => {
      state.marketRates = rates;
    });
}

watch(
  collaterals,
  (newVal?: ClientCollaterals | null, oldVal?: ClientCollaterals | null) => {
    /**
     * Rates are loaded on page load (when oldVal is non-existent) and whenever collaterals are updated (comparing updatedAt values)
     */
    if (newVal && (!oldVal || newVal.updatedAt !== oldVal?.updatedAt)) {
      loadLiveRates();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="collateral-actions">
    <CollateralWalletFundActions
      class="wallet-funds"
      v-if="displayWarning"
      :totalEquivalent="totalEquivalent"
      :collateras="collaterals"
      v-bind="$attrs"
    />
    <CollateralTransaction
      class="collateral-section"
      @action-changed="actionTypeChanged($event)"
      @transaction-executed="$emit('transaction-executed', $event)"
      @update:txOperationState="$emit('update:txOperationState', $event)"
      :collateras="collaterals"
      v-bind="$attrs"
    />
  </div>
</template>

<style lang="scss" scoped>
.wallet-funds {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 1em;
  padding-bottom: 1em;

  @include themedBorderColor($color-input-border);
}
</style>
