<script setup lang="ts">
import { Observable } from 'rxjs';
import { formatBytes, checkMIMEType } from '../../../helpers/file';
import { UploadedFile } from 'ah-api-gateways';
import { UploadedFileUpdate } from 'ah-requests';
import FileUploadStatus from './FileUploadStatus.vue';
import UploadButton from './UploadButton.vue';
import FileUploadInvalid from './FileUploadInvalid.vue';
import { BModal } from 'bootstrap-vue';
import { format } from 'date-fns';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

/**
 * File Uploader component
 *
 * Emits:
 * - update:uploaded - .sync-able event to relay updates to the currently uploaded file. Will emit `null` after file deletion.
 * - update:selected (payload: File | null) - emitted when file is selected and ready to be uploaded or null if cancelled.
 */

const props = defineProps({
  sectionTitle: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  hideTitle: {
    type: [Boolean, String],
    default: false,
  },
  uploadNotAllowed: {
    type: [Boolean, String],
    default: false,
  },
  deleteNotAllowed: {
    type: [Boolean, String],
    default: false,
  },
  downloadNotAllowed: {
    type: [Boolean, String],
    default: false,
  },
  hideWarning: {
    type: [Boolean, String],
    default: false,
  },
  hideFooter: {
    type: [Boolean, String],
    default: false,
  },
  hideActions: {
    type: [Boolean, String],
    default: false,
  },
  tooltip: {
    type: [Boolean, String],
    default: false,
  },
  /**
   * Accept string for MIME types
   *
   * Should be a list of MIME types (not extensions) to allow for better JS file type vetting
   *
   */
  accept: {
    type: String,
    default: '',
  },
  acceptString: {
    type: String,
    default: '',
  },
  maxSize: {
    type: Number,
    default: -1,
  },
  /**
   * Whether to auto upload the inputed file
   *
   * Default is set to true
   */
  autoUpload: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether to force loading state
   *
   * Default is set to false
   */
  loading: {
    type: Boolean,
    default: false,
  },

  /**
   * Currently uploaded file
   *
   * Updatable via .sync
   */
  uploaded: {
    type: Object as PropType<UploadedFile | null>,
    default: null,
  },

  /**
   * Currently selected file
   *
   * Updatable via .sync
   */
  selected: {
    type: Object as PropType<File | null>,
    default: null,
  },
  uploadRequest: {
    type: Function as PropType<(file: Blob) => Observable<UploadedFileUpdate<UploadedFile>>>,
    default: () => () => null,
  },
  downloadRequest: {
    type: Function as PropType<(file: UploadedFile) => Observable<void>>,
    default: () => () => null,
  },
  deleteRequest: {
    type: Function as PropType<(file: UploadedFile) => Observable<void>>,
    default: () => () => null,
  },
  downloadButtonClass: {
    type: String,
    default: 'btn-stroked',
  },
  uploadButtonClass: {
    type: String,
    default: 'btn-stroked',
  },
  deleteButtonClass: {
    type: String,
    default: 'btn-danger-secondary',
  },
  hideUpdatedBy: {
    type: [String, Boolean],
    default: true,
  },
});

const emit = defineEmits({
  'update:selected': (_file: File | null) => true,
  'update:uploaded': (_date: UploadedFile | null) => true,
  'update:uploading': (_date: UploadedFile | null) => true,
});

const deleteConfirmModal = ref<InstanceType<typeof BModal>>();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'downloadFile') {
      downloadFile();
    }
    if (k === 'deleteFile') {
      deleteFile();
    }
    if (k === 'uploadFile') {
      uploadFile();
    }
  },
}).manager;

const dragingOver = ref<boolean>(false);

const selectedFile = ref<File | null>(null);

const uploadStatus = ref<UploadedFileUpdate<UploadedFile> | null>(null);

const errorMessage = ref<{ title: string; description: string[] } | null>(null);

const fileName = computed(() => props.uploaded?.name || selectedFile.value?.name || '');

const shouldHideActions = computed(() => props.hideActions !== false);

const readableAccept = computed(() => props.acceptString || props.accept.toUpperCase().split(',').join(', '));

const shouldHideFooter = computed(() => props.hideFooter !== false);

const withTooltip = computed(() => props.tooltip !== false);

const maxSizeFormatted = computed(() => formatBytes(props.maxSize));

const isLoading = computed(() => props.loading !== false);

const isUploading = computed(() => requestManager.requestStates.uploadFile === 'pending');

const isDownloading = computed(() => requestManager.requestStates.downloadFile === 'pending');

const isDeleting = computed(() => requestManager.requestStates.deleteFile === 'pending');

const deleteAllowed = computed(() => props.deleteNotAllowed === false && props.deleteRequest.length > 0);

const downloadAllowed = computed(() => props.downloadNotAllowed === false && props.downloadRequest.length > 0);

const uploadAllowed = computed(() => props.uploadNotAllowed === false && props.uploadRequest.length > 0);

const fileReadyToBeUploaded = computed(() => props.autoUpload !== true && selectedFile.value);

const hideUploadedByInformation = computed(
  () => !props.uploaded || !props.uploaded.createdByName || !props.uploaded.createdAt || props.hideUpdatedBy !== false
);

function getFileType(name?: string) {
  const fileNameAux = name || fileName.value;
  const nameSplitted = fileNameAux.split('.');
  return nameSplitted.length > 1 ? nameSplitted[nameSplitted.length - 1] : 'FILE';
}

function cancelUpload() {
  requestManager.cancel('uploadFile');
  selectedFile.value = null;
  uploadStatus.value = null;
  emit('update:selected', null);
}

function downloadFile() {
  if (props.uploaded) {
    requestManager.sameOrNew('downloadFile', props.downloadRequest(props.uploaded), props.uploaded.link).subscribe();
  }
}

function deleteFile() {
  if (props.uploaded) {
    const confirm = () => {
      requestManager
        .sameOrNew('deleteFile', props.deleteRequest(props.uploaded!), props.uploaded!.link)
        .subscribe(() => {
          setTimeout(() => {
            selectedFile.value = null;
            uploadStatus.value = null;
            emit('update:uploaded', null);
          }, 0);
        });
    };

    const modal = deleteConfirmModal.value;
    modal!.show();
    modal!.$once('ok', confirm);
    modal!.$once('hidden', () => {
      modal!.$off('ok', confirm);
    });
  }
}

function uploadFile(eventTimeout = 1000, clearTimeout = 1000) {
  if (selectedFile.value) {
    requestManager.currentOrNew('uploadFile', props.uploadRequest(selectedFile.value)).subscribe(
      (progress) => {
        uploadStatus.value = progress;
        if (progress.finished) {
          setTimeout(() => emit('update:uploaded', progress.file), eventTimeout);
          setTimeout(() => {
            selectedFile.value = null;
            uploadStatus.value = null;
          }, clearTimeout);
        }
      },
      () => {
        setTimeout(() => {
          selectedFile.value = null;
          uploadStatus.value = null;
        }, clearTimeout);
      }
    );
  }
}

function onFilesDropped(event: DragEvent) {
  dragingOver.value = false;
  if (event.dataTransfer && !isUploading.value) {
    const out: File[] = [];
    Array.from(event.dataTransfer.files).forEach((file) => out.push(file));
    onFileSelection(out);
  }
}

function onFileSelection(files: FileList | File[]) {
  const file = files[0];
  if (file) {
    if (props.maxSize > 0 && file.size > props.maxSize) {
      errorMessage.value = {
        title: 'File size limit',
        description: ['The file you tried to upload is too large.', 'Please reduce the file size and try again.'],
      };
    } else if (props.accept && !checkMIMEType(file, props.accept)) {
      errorMessage.value = {
        title: 'Invalid file format',
        description: ['Please upload document with supported format'],
      };
    } else {
      selectedFile.value = file;
      emit('update:selected', file);
      if (props.autoUpload === true) {
        uploadFile();
      }
    }
  }
}

function changeFileName(event: any) {
  if (event?.srcElement?.value) {
    let newValue = event?.srcElement?.value as string;
    const type = getFileType();
    if (getFileType(newValue) !== type) newValue = newValue.concat('.' + type);
    props.uploaded!.name = newValue;
    emit('update:uploading', props.uploaded);
  }
}

watch(() => props.selected, onSelectedChange, { immediate: true });

function onSelectedChange() {
  selectedFile.value = props.selected;
}

defineExpose({
  isLoading: isLoading,
  uploadFile: uploadFile,
  isUploading: isUploading,
});
</script>

<template>
  <div class="file-uploader">
    <div class="files-list">
      <div class="files-list-element mt-4">
        <h3 class="mb-2" v-if="sectionTitle">{{ sectionTitle }}</h3>
        <span class="file-title" v-if="hideTitle === false">
          <div>
            {{ title }}
            <InfoTooltip class="pb-1 ml-2" v-if="withTooltip && description" :text="description" />
            <p
              class="no-file-warning float-right"
              v-if="!uploaded && !fileReadyToBeUploaded && !isLoading && hideWarning === false"
            >
              <slot name="empty-field-warning"> No document uploaded yet </slot>
            </p>
          </div>
          <p
            class="text-secondary description"
            v-if="!withTooltip && description && !uploaded && !fileReadyToBeUploaded && !isLoading"
          >
            {{ description }}
          </p>
        </span>
        <div class="file-list-inner" v-if="uploaded || (!uploaded && fileReadyToBeUploaded && !isUploading)">
          <LoadingOverlay :loading="isLoading" variant="box">
            <div class="mt-2">
              <FileBadge class="d-inline" :type="getFileType()" />
              <input class="file-name-input" :value="fileName" @blur="changeFileName($event)" />
            </div>
            <template v-if="!shouldHideActions">
              <div class="buttons" v-if="uploaded">
                <VButton
                  v-if="downloadAllowed"
                  :class="downloadButtonClass"
                  @click="downloadFile()"
                  :loading="isDownloading"
                >
                  <slot name="download-button-text"> Download <DownloadIcon /> </slot>
                </VButton>
                <VButton v-if="deleteAllowed" :class="deleteButtonClass" @click="deleteFile()" :loading="isDeleting">
                  <slot name="delete-button-text"> Delete </slot>
                </VButton>

                <div
                  class="ml-auto text-right text-secondary"
                  v-if="!hideUploadedByInformation && uploaded.createdByName"
                >
                  Updated by {{ uploaded.createdByName }}
                  <div>{{ format(new Date(uploaded.createdAt), 'dd-MM-yyyy HH:mm') }}</div>
                </div>
              </div>
              <div class="buttons" v-else>
                <VButton disabled class="mr-3">
                  <slot name="download-button-text"> Download </slot>
                </VButton>
                <VButton :class="deleteButtonClass" @click="cancelUpload">
                  <slot name="delete-button-text"> Delete </slot>
                </VButton>
              </div>
            </template>
          </LoadingOverlay>
        </div>
      </div>
    </div>

    <div class="file-input" v-if="uploadAllowed && !uploaded && (!fileReadyToBeUploaded || isUploading)">
      <LoadingOverlay :loading="isLoading" variant="box">
        <FileUploadInvalid
          class="file-upload-error-message p-3"
          v-if="errorMessage"
          :reason="errorMessage"
          @ok="errorMessage = null"
        />
        <FileUploadStatus
          class="file-upload-status px-4"
          v-else-if="selectedFile"
          :file="selectedFile"
          :uploadStatus="uploadStatus"
          @cancel="cancelUpload"
        />
        <div
          :class="['file-input-default', { isOver: dragingOver }]"
          @dragover.prevent
          @drop.prevent
          @drop="onFilesDropped"
          @dragover="dragingOver = true"
          @dragleave="dragingOver = false"
          v-else
        >
          <VRow align-v="center">
            <VCol class="text-center drag-drop-text px-3" cols="6">
              <slot name="drag-drop-text"> Drag and drop file here </slot>
            </VCol>
            <VCol class="text-center text-small" cols="1"> or </VCol>
            <VCol class="text-center" cols="5">
              <UploadButton
                class="text-nowrap"
                :buttonClass="uploadButtonClass"
                :accept="accept"
                @files-selected="onFileSelection"
              >
                Upload <slot name="upload-icon"> <UploadIcon /></slot>
              </UploadButton>
            </VCol>
          </VRow>
        </div>
      </LoadingOverlay>
    </div>
    <div
      class="my-3"
      v-if="(maxSize > 0 || accept) && !shouldHideFooter && !uploaded && !fileReadyToBeUploaded && !isLoading"
    >
      <p class="text-secondary small-text mb-0" v-if="maxSize > 0">Max size: {{ maxSizeFormatted }}</p>
      <p class="text-secondary small-text mb-0" v-if="accept">
        Supported formats: <span class="inline small-text">{{ readableAccept }}</span>
      </p>
      <p class="text-secondary small-text mb-0">
        <slot name="description-after" />
      </p>
    </div>

    <BModal v-if="uploaded" ref="deleteConfirmModal" class="deleteModal" centered hide-header>
      <div class="text-center">
        <slot name="delete-confirm-text"> Are you sure you want to delete {{ uploaded.name }}? </slot>
      </div>
    </BModal>
  </div>
</template>

<style lang="scss" scoped>
.file-uploader {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  .file-counter {
    .file-counter-icon {
      .icon {
        display: inline;
        vertical-align: top;
        margin-right: 12px;
        svg {
          width: 30px;
          height: 30px;
          @include themedTextColor($color-primary, $color-dark-primary);
          @include themedProp('fill', $color-primary, $color-dark-primary);
        }
      }

      .file-list-counter {
        font-size: 10px;
        color: $common-color-darkGrey;
      }
    }
  }

  .files-list {
    .file-list-inner {
      position: relative;
    }
    .file-title {
      font-weight: $font-weight-semibold;

      .no-file-warning {
        display: inline;
        font-weight: $font-weight-semibold;
        font-size: $font-size-xs;
        color: $common-color-red;
      }

      .description {
        font-weight: $font-weight-regular;
      }
    }

    .file-name-input {
      position: relative;
      overflow: hidden;
      width: 80%;
      text-overflow: ellipsis;
      margin-left: 8px;
      border: none;
      background: transparent;
      @include themedTextColor($color-dark-primary, $color-primary);
      &:focus {
        outline: none;
      }
    }

    .buttons {
      display: inline-flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      .download-button {
        margin-right: 12px;
        white-space: nowrap;
        color: $common-color-white;
        ::v-deep path {
          fill: $common-color-white;
        }
      }
    }
  }

  .file-input {
    position: relative;
    border: 1px solid;
    border-radius: 3px;
    margin-top: auto;
    @include themedTextColor($color-dark-primary, $color-primary);
    .file-input-default {
      .drag-drop-text {
        font-weight: $font-weight-semibold;
        margin-right: -17px;
      }
      &.isOver {
        background-color: $common-color-grey;
        opacity: 0.4;
      }
    }

    .file-upload-status,
    .file-input-default {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.small-text {
  font-weight: $font-weight-regular;
  font-size: $font-size-xs;
}

::v-deep .deleteModal {
  padding: 100px !important;
}
</style>
