<template>
  <div>
    <div class="d-flex justify-content-between cursor-pointer ml-3">
      <div class="d-flex align-items-center">
        <UserIcon :user="user" class="mr-3" />
        <p class="semi-bold m-0">{{ user.firstName }} {{ user.lastName }}</p>
      </div>
      <span @click="toggleShow"><IconChevronDown :class="['menu-toggle', { open: show }]" /></span>
    </div>
    <ExpandTransition appear>
      <div class="user-menu mt-3" v-if="show">
        <ul>
          <RouterLink class="menu-link" tag="li" :to="`${settingsBase}/account`">
            <span class="menu-link-text"> Account Settings </span>
          </RouterLink>
          <RouterLink class="menu-link" tag="li" :to="`${settingsBase}/personal-settings`">
            <span class="menu-link-text"> Personal Settings </span>
          </RouterLink>
          <li class="menu-link" @click="logout">
            <span class="menu-link-text"> Log out </span>
          </li>
        </ul>
      </div>
    </ExpandTransition>

    <!-- MODALS -->
    <BModal
      :centered="true"
      ok-title="Yes, log out"
      cancel-variant="secondary"
      body-class="py-5 px-5"
      footer-class="justify-content-start pl-5 pb-5"
      ref="deleteModal"
      >Are you sure you want to log out?
    </BModal>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { IndividualFileCategories } from 'ah-api-gateways';
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import UserIcon from './UserIcon.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';

@Component({
  components: {
    AsyncImage,
    UserIcon,
  },
})
export default class UserIconMenuMobile extends Vue {
  $refs!: {
    userMenuDropdown: Vue;
    deleteModal: BModal;
  };
  show = false;

  created() {
    if (this.authStore.isClientUser) {
      this.individualSettingsStore.loadIndividualDocuments();
    }
  }

  toggleShow() {
    this.show = !this.show;
  }

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get photo() {
    return this.individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE);
  }

  get settingsBase() {
    if (this.authStore.isAgent) {
      return '/admin/settings';
    }
    return '/settings';
  }

  logout() {
    this.$refs.deleteModal.show();
    this.$refs.deleteModal.$once('ok', () => this.authStore.logout());
  }

  get initials() {
    return ((this.user!.firstName || ' ')[0] + (this.user!.lastName || ' ')[0]).toUpperCase();
  }

  get user() {
    return this.authStore.loggedInIdentity;
  }
}
</script>

<style lang="scss" scoped>
.user-menu {
  width: 100%;
  height: 100%;
  ul {
    list-style: none;
    padding: 0;
    .menu-link {
      cursor: pointer;
      padding-top: 0.6rem;
      padding-left: 3.2rem;
      min-height: $side-nav-width * 0.8;

      .menu-link-text {
        font-size: 0.8rem;
        line-height: 1.2em;
        font-weight: 400;
      }

      &:hover:not(.router-link-active) {
        @include themedTextColor;
      }

      &.router-link-active {
        @include themedBackgroundColor($color-primary);
        color: white;
        font-weight: bold;
      }
    }
  }
}

.menu-toggle {
  margin-right: 1.35rem;
  transition: transform 0.6s;
  width: 24px;
  height: 24px;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
