<script setup lang="ts">
import { computed, ref, useSlots, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
// importing directly to avoid non-extracted css issues from built code
import { VueTagsInput, createTags } from '@johmun/vue-tags-input/vue-tags-input/publish';
import uniq from 'lodash/uniq';

type Tag = {
  text: string;
  tiClasses: string[];
};

const props = defineProps(makeUseFormElementProps<FieldModel, string[]>());

const emit = defineEmits(makeUseFormElementEmits<string[]>());

const slots = useSlots();

const currTags = ref<Tag[]>([]);

const currTagsText = ref<string[]>([]);

const inputVal = ref('');

const formElement = useFormElement({
  emit,
  props,
});

const {
  fieldErrorsShown,
  readonly,
  inputErrorClass,
  inputName,
  inputClass,
  placeholder,
  autocomplete: baseAutocomplete,
  dirtyOnInput,
} = useBaseFieldValues(formElement);

const { modelStateRef, model, field, setValue: baseSetValue, setModelState, emitFormEvent, getSlotName } = formElement;

const allowEditTags = modelStateRef('allowEditTags', true);

const tagValidation = modelStateRef('tagValidation', []);

const includeInvalidTags = modelStateRef('includeInvalidTags', false);

const addOnlyFromAutocomplete = modelStateRef('addOnlyFromAutocomplete', false);

const autocompleteAlwaysOpen = modelStateRef('autocompleteAlwaysOpen', false);

const autocompleteMinLength = modelStateRef('autocompleteMinLength', 0);

const autocompleteItems = modelStateRef('autocompleteItems', undefined);

const tags = computed(() => createTags(currTagsText.value || []) as Tag[]);

const autocomplete = computed(() => baseAutocomplete.value || model.value.$name);

function checkTagsValidity() {
  const allValid = !currTags.value.find((i) => !i.tiClasses.includes('ti-valid'));
  setModelState('validTags', allValid);
}

function setValue(newTags: Tag[]) {
  const filtered = newTags
    .filter((t) => includeInvalidTags.value || t.tiClasses.includes('ti-valid'))
    .map((t) => t.text);

  currTags.value = newTags;
  checkTagsValidity();
  currTagsText.value = newTags.map((t) => t.text);

  baseSetValue(filtered, !field.value.$dirty && !dirtyOnInput.value);
}

function onBlur() {
  setModelState('validTags', null);
  setTimeout(() => {
    field.value.$touch();
    emitFormEvent({
      event: 'form-field-blur',
    });

    // Setting a timeout inside another to skip 2 JS event loops, ensuring the setValue function has had an opportunity to be called
    setTimeout(() => checkTagsValidity, 500);
  });
}

function onFocus() {
  emitFormEvent({
    event: 'form-field-focus',
  });
}

function getTagSlotNames() {
  return Object.keys(slots).filter((k) => {
    const slots = [
      'tag-left',
      'tag-right',
      'tag-center',
      'tag-actions',
      'autocomplete-item',
      'autocomplete-header',
      'autocomplete-footer',
      'between-elements',
    ];

    const innerSlotName = k.split(':')[1];
    if (!slots.includes(innerSlotName)) {
      return false;
    }
    return !!getSlotName(innerSlotName);
  });
}

watch(
  () => field.value.$model,
  () => (currTagsText.value = uniq(currTagsText.value.concat(field.value.$model || []))),
  { immediate: true }
);

watch(inputVal, () => setModelState('tagsInputVal', inputVal.value), { immediate: true });

checkTagsValidity();
</script>

<template>
  <div class="field-group-input-container">
    <VueTagsInput
      v-model="inputVal"
      :tags="tags"
      :name="inputName"
      :readonly="readonly"
      :tabindex="readonly ? -1 : undefined"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :validation="tagValidation"
      :allow-edit-tags="allowEditTags"
      :add-only-from-autocomplete="addOnlyFromAutocomplete"
      :add-on-key="[' ', 13]"
      :autocomplete-always-open="autocompleteAlwaysOpen"
      :autocomplete-min-length="autocompleteMinLength"
      :autocomplete-items="autocompleteItems"
      :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
      @blur="onBlur"
      @focus="onFocus"
      @tags-changed="setValue"
    >
      <template v-for="slot in getTagSlotNames()" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </VueTagsInput>
  </div>
</template>
