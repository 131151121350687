<template>
  <VRow>
    <VCol>
      <FileUploader
        title="Proof of ID"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Clear, colour copy of driving license or passport (in date, with all information legible)"
        :uploadRequest="uploadID"
        :uploaded="photoId"
        :deleteRequest="deleteFile"
        :downloadRequest="downloadFile"
        :loading="requestManager.anyPending"
        @update:selected="$emit('file-selected', { file: $event, category: ClientFileCategories.PHOTO_ID })"
        v-bind="$attrs"
      />
    </VCol>
    <VCol>
      <FileUploader
        title="Proof of Address"
        :maxSize="10485760"
        description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :uploadRequest="uploadPOA"
        :uploaded="proofOfAddress"
        :deleteRequest="deleteFile"
        :downloadRequest="downloadFile"
        :loading="requestManager.anyPending"
        @update:selected="$emit('file-selected', { file: $event, category: ClientFileCategories.PROOF_OF_ADDRESS })"
        v-bind="$attrs"
      />
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { BaseIndividual, ClientFileCategories, UploadedFile } from 'ah-api-gateways';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { useAuthStore } from '@/app/store/authStore';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { tap } from 'rxjs/operators';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { cloneDeep } from 'lodash';

/**
 * UBO Documents editor
 * Can work in "auto-upload" mode or in select-only mode ("auto-upload" prop will be sent over to the FileUploader child components)
 *
 * Emits:
 * - update:files - .sync-able event to relay updates to the currently uploaded files.
 * - file-selected (payload: {file: File | null, category: ClientFileCategories}) - emitted when file is selected and ready to be uploaded or null if cancelled.
 */
@Component({
  components: {
    FileUploader,
  },
})
export default class UboDocumentsEditor extends Mixins(WithRequestManager) {
  @Prop({ required: true }) ubo!: BaseIndividual;

  @Prop({ required: false }) files?: UploadedFile[];

  private defaultAcceptanceFileTypes = defaultAcceptanceFileTypes;

  private innerFiles: UploadedFile[] = [];

  ClientFileCategories = ClientFileCategories;

  created() {
    if (!this.files) {
      this.requestManager
        .sameOrCancelAndNew(
          'getUboDocuments',
          this.$services.compliance.getUboDocuments(this.clientId, this.ubo.id!, {
            errors: { silent: true },
          })
        )
        .subscribe((response) => {
          this.innerFiles = response;
          this.$emit('update:files', response);
        });
    }
  }

  @Watch('files', { immediate: true })
  onFilesChange() {
    if (this.files) {
      this.innerFiles = cloneDeep(this.files);
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get clientId() {
    return this.authStore.loggedInIdentity!.client!.id;
  }

  get photoId() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.PHOTO_ID) || null;
  }

  get proofOfAddress() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.PROOF_OF_ADDRESS) || null;
  }

  uploadFile(file: File, category: ClientFileCategories) {
    return this.$services.compliance.submitUboDocument(this.clientId, this.ubo.id!, category, file).pipe(
      tap((update) => {
        if (update.finished) {
          this.$services.compliance.getUboDocuments(this.clientId, this.ubo.id!).subscribe((docs) => {
            const document = docs.find((d) => d.id === update.file.id);

            if (document) {
              const currFileIndex = this.innerFiles.findIndex((i) => i.category === document.category);
              currFileIndex > -1 ? this.innerFiles.splice(currFileIndex, 1, document) : this.innerFiles.push(document);
            }

            // update ubo file status in store
            this.individualSettingsStore.loadClientUbos(true);
          });
        }
      })
    );
  }

  deleteFile(file: UploadedFile) {
    return this.$services.compliance.removeUboDocument(this.clientId, this.ubo.id!, file.id).pipe(
      tap(() => {
        const currFileIndex = this.innerFiles.findIndex((i) => i.category === file.category);
        if (currFileIndex > -1) {
          this.innerFiles.splice(currFileIndex, 1);
        }

        // update ubo file status in store
        this.individualSettingsStore.loadClientUbos(true);
      })
    );
  }

  downloadFile(file: UploadedFile) {
    return this.$services.compliance.downloadUboSyncDocument(this.clientId, this.ubo.id!, file);
  }

  uploadID(file: File) {
    return this.uploadFile(file, ClientFileCategories.PHOTO_ID);
  }

  uploadPOA(file: File) {
    return this.uploadFile(file, ClientFileCategories.PROOF_OF_ADDRESS);
  }
}
</script>
