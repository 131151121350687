<script setup lang="ts">
import { RegistrationStepKeys } from '@/app/helpers/registration/flow';
import { ref } from 'vue';
import OnboardingReview from './OnboardingReview.vue';
import SignatoryTermsReview from './forms/SignatoryTermsReview.vue';
import { Client, FeatureFlag } from 'ah-api-gateways';
import BankingProviderSelectionForm from '@/app/views/public/registration/BankingProviderSelectionForm.vue';
import { useFeatureFlag } from 'ah-common-stores';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';

defineProps<{ client: Client }>();

const step = ref<'review' | 'terms' | 'banking'>('review');

const emit = defineEmits({
  'update:client': (_value: Client) => true,
  'flow-finished': (_value: void) => true,
});

const { isActive: isNatwestProviderActive, loadFlag: loadNatwestProviderFlag } = useFeatureFlag({
  featureFlag: FeatureFlag.PREFERRED_BANKING_PROVIDER_NATWEST_ENABLED,
  skipPreload: true,
});

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const services = getServices();

function submitRegistrationRequest(clientId: string) {
  return services.compliance.submitClientQuestionnaire(clientId);
}

function finish() {
  requestManager
    .currentOrNew('submitRegistration', submitRegistrationRequest(authStore.loggedInIdentity!.client!.id))
    .subscribe(() => {
      emit('flow-finished');
    });
}

async function onNextStep() {
  if (step.value === 'review') {
    step.value = 'terms';
  } else if (step.value === 'terms') {
    await requestManager.newPromise('loadNatwestFlag', loadNatwestProviderFlag());
    if (isNatwestProviderActive.value) {
      step.value = 'banking';
    } else {
      finish();
    }
  } else if (step.value === 'banking') {
    finish();
  }
}
</script>

<template>
  <div>
    <Transition name="fade" appear>
      <div v-if="step === 'review'">
        <h2>Application Approval</h2>

        <OnboardingReview
          :allowEdit="[
            RegistrationStepKeys.COMPANY_DETAILS,
            RegistrationStepKeys.TRADING_DETAILS,
            RegistrationStepKeys.DOCUMENTATION,
            RegistrationStepKeys.ADDITIONAL_USERS,
          ]"
          :client="client"
          success-btn-label="Continue"
          @proceed="onNextStep"
          @update:client="emit('update:client', $event)"
        />
      </div>
      <SignatoryTermsReview withOBOPermissions :client="client" @proceed="onNextStep" v-if="step === 'terms'" />
      <BankingProviderSelectionForm @proceed="onNextStep" v-if="step === 'banking'" />
    </Transition>
  </div>
</template>
