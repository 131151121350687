<script setup lang="ts">
import CollateralRawAmounts from 'ah-trades/src/components/collaterals/CollateralRawAmounts.vue';
import CollateralMargins from 'ah-trades/src/components/collaterals/CollateralMargins.vue';
import CollateralActions from 'ah-trades/src/components/collaterals/CollateralActions.vue';
import CollateralActionsHelpText from 'ah-trades/src/components/collaterals/CollateralActionsHelpText.vue';
import WarningChangesBanner from '@/app/components/trades/WarningChangesBanner.vue';
import { Client } from 'ah-api-gateways';
import debounce from 'lodash/debounce';
import { DebouncedFunc } from 'lodash';
import OnBehalfOf from 'ah-common-lib/src/onBehalfOf/OnBehalfOf';
import { CollateralActionEvent } from 'ah-trades/src/components/collaterals/CollateralActionEvent';
import { DEFAULT_INACTIVITY_TIMEOUT } from 'ah-common-lib/src/constants/time';
import { useAuthStore } from '@/app/store/authStore';
import { useWalletsStore } from '@/app/store/walletsModule';
import { computed, onBeforeMount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { getServices } from '@/app/services';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';

/**
 * On behalf of client collateral management
 *
 * If set, partner can access the client collateral management
 * on behalf of client with ID 'clientId'
 *
 * FIXME: debouncedLoad is a patch to allow wallet refresh to bypass race condition
 */

const walletStore = useWalletsStore();

const router = useRouter();

const route = useRoute();

const services = getServices();

const requestManager = useRequestManager().manager;

const toast = useToast();

const authStore = useAuthStore();

const props = defineProps({ clientId: { type: String, required: false } });

const onBehalfOfClient = ref<Client>();

const debouncedLoad = ref<DebouncedFunc<() => void>>();

const refreshComponentTimeout = ref<number>();

onMounted(() => refreshComponent());

onBeforeMount(() => {
  loadCollaterals();
  debouncedLoad.value = debounce(() => loadCollaterals(), 3000);
});

function refreshComponent() {
  if (refreshComponentTimeout.value) {
    refreshComponentTimeout.value = window.setInterval(() => {
      loadCollaterals();
    }, DEFAULT_INACTIVITY_TIMEOUT);
  }
}

onUnmounted(() => {
  debouncedLoad.value!.cancel();
  if (refreshComponentTimeout.value !== null) clearInterval(refreshComponentTimeout.value);
});

function goBack() {
  router.push('/admin/activity/clients/obo/' + props.clientId);
}

function reloadData() {
  getOBOClient();
  loadCollaterals();
}

function getOBOClient() {
  if (props.clientId) {
    requestManager.currentOrNew('loadClient', services.client.getClient(props.clientId)).subscribe((response) => {
      onBehalfOfClient.value = response;
    });
  }
}

watch(() => props.clientId, getOBOClient, { immediate: true });

function onCollateralAction(event: CollateralActionEvent) {
  toast.success(`${event.mode === 'post' ? 'Posted to' : 'Withdrew from'} collaterals successfully.`);
  debouncedLoad.value!();
}

function loadCollaterals() {
  return requestManager.newPromise(
    'loadCollaterals',
    walletStore.loadCollaterals({ force: true, clientId: props.clientId })
  );
}

const isAgent = computed(() => authStore.isAgent);
const readonly = computed(() => route.meta?.readonly || false);
const isOBOAction = computed(() => !!(!readonly.value && isAgent && onBehalfOfClient.value));
const isClientUser = computed(() => authStore.isClientUser);
</script>

<template>
  <OnBehalfOf :client="onBehalfOfClient">
    <div class="padded-space" id="collateral-management-view">
      <div class="inner">
        <VRow class="mx-0" align-v="center">
          <h2>Collateral Management</h2>
        </VRow>
        <WarningChangesBanner v-if="isClientUser" />
        <LoadingOverlay
          :state="requestManager.currentStates"
          class="page-overlay"
          show-retry
          @retry="reloadData"
          removeOnLoading
        >
          <BackButton v-if="isOBOAction" label="go back" @click="goBack()" small />
          <BoxGrid align-h="start">
            <BoxGridItem cols="12" lg="6">
              <BoxGrid align-h="start">
                <BoxGridBlock cols="12">
                  <CollateralRawAmounts />
                </BoxGridBlock>
                <BoxGridBlock cols="12">
                  <CollateralMargins />
                </BoxGridBlock>
              </BoxGrid>
            </BoxGridItem>
            <BoxGridItem cols="12" lg="6" v-if="!readonly">
              <BoxGrid align-h="start">
                <BoxGridBlock cols="12" useLoadingOverlay>
                  <CollateralActionsHelpText :clientId="clientId" />
                  <div v-if="$mediaQuery.is('mdDown')">
                    <hr class="separator my-4" />
                    <CollateralActions @transaction-executed="onCollateralAction" />
                  </div>
                </BoxGridBlock>
                <BoxGridBlock
                  cols="12"
                  v-if="$mediaQuery.is('lgUp')"
                  useLoadingOverlay
                  :loadingOverlayProps="{ showRetry: true }"
                >
                  <CollateralActions @transaction-executed="onCollateralAction" />
                </BoxGridBlock>
              </BoxGrid>
            </BoxGridItem>
          </BoxGrid>
        </LoadingOverlay>
      </div>
    </div>
  </OnBehalfOf>
</template>

<style lang="scss" scoped>
.separator {
  border-width: 1px;
  border-bottom-width: 0px;

  @include themedBorderColor($color-input-border);
}
.client-name,
.back-button {
  @include themedTextColor($color-dark-primary, $color-primary);
  ::v-deep * {
    font-size: 14px;
  }
}
</style>
