<template>
  <ResponsiveNav>
    <ul class="main">
      <RouterLink tag="li" class="menu-link" to="/dashboard" exact>
        <IconDashboard /> <span class="menu-link-text"> Dashboard </span>
      </RouterLink>
      <SideNavTradeLink v-if="canViewTrades" />
      <RouterLink tag="li" class="menu-link" to="/dashboard/payments">
        <IconWallet /> <span class="menu-link-text"> Payments</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/wallets">
        <IconWallet /> <span class="menu-link-text"> Wallets</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/beneficiaries">
        <IconAgenda /> <span class="menu-link-text"> Beneficiaries </span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/dashboard/subscriptions" v-if="canViewSubscriptions">
        <IconReport /> <span class="menu-link-text"> Subscriptions </span>
      </RouterLink>
    </ul>
    <template #bottom>
      <ul class="bottom">
        <SideNavSettingsLink />
      </ul>
    </template>
  </ResponsiveNav>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import { AuthorityType, FeatureFlag } from 'ah-api-gateways';
import TradesSidebarView from '@/app/views/dashboard/trades/TradesSidebarView.vue';
import SideNavTradeLink from './SideNavTradeLink.vue';
import SideNavSettingsLink from './SideNavSettingsLink.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { useFeatureFlag } from 'ah-common-stores';

@Component({
  components: {
    InfoTooltip,
    TradesSidebarView,
    SideNavTradeLink,
    SideNavSettingsLink,
  },
})
export default class ClientResponsiveNav extends Vue {
  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get canViewTrades() {
    return this.authStore.hasAuthorities(AuthorityType.VIEW_TRADES);
  }

  get isXhedgeProClient() {
    return this.individualSettingsStore.client?.enableXHedgePro;
  }

  // FIXME - MM: Add feature flag here
  created() {
    this.individualSettingsStore.loadClient();
  }

  get canViewSubscriptions() {
    return (
      useFeatureFlag({
        featureFlag: FeatureFlag.MVP_OPTIONS_PAYMENTS_SCHEDULE,
      }).isActive.value && this.isXhedgeProClient
    );
  }
}
</script>
