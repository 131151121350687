<template>
  <div>
    <LoadingOverlay :loading="loading">
      <div class="authorisations-table" :class="{ blur: loading }">
        <VRow class="authorisations-header align-items-end" v-if="!hideHeaders">
          <VCol class="authority-col"> Authority </VCol>
          <VCol cols="6" class="switch-col"> Active </VCol>
          <VCol class="date-col"> Expiry </VCol>
        </VRow>
        <div class="authorisations-body">
          <slot v-bind="{ isStackedView }" />
        </div>
      </div>
    </LoadingOverlay>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AuthorisationsSettingsTable extends Vue {
  /**
   * Loading state of table.
   *
   * If any request is in progress table will not be presented
   */
  @Prop({ default: false }) loading!: boolean;

  /**
   * Hide table headers.
   *
   * If true, headers won't be showned
   */
  @Prop({ default: false }) hideHeaders!: boolean;

  get isStackedView() {
    return this.$mediaQuery.is('mdDown');
  }
}
</script>

<style lang="scss" scoped>
.authorisations-table {
  .col {
    flex-grow: 0;
  }
  .authority-col {
    flex-basis: 50%;
  }

  .switch-col {
    flex-basis: 16.5%;
  }

  .date-col {
    white-space: nowrap;
    flex-basis: 30%;
  }

  .authorisations-header {
    font-weight: 600;
    padding-bottom: math.div($padded-space, 2);
    margin-bottom: $padded-space;
    @include themedPropColor('border-bottom', $color-box-border, $color-dark-box-border, '1px solid ');
  }
}
</style>
