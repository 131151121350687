<template>
  <div>
    <h1 class="mb-5">Email not verified</h1>
    <p>Check your {{ email }} inbox to confirm your email and finish registration.</p>
    <div class="text-center mt-5">
      <VButton
        :label="resentCode ? 'Email sent' : 'Resend verification email'"
        :disabled="resentCode"
        @click="resendVerificationCode"
        class="btn-primary"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component
export default class EmailVerificationWarning extends Mixins(WithRequestManager) {
  @Prop({ required: true }) email!: string;

  private resentCode = false;

  requestManagerConfig = {
    exposeToParent: false,
  };

  resendVerificationCode() {
    if (this.resentCode) {
      return;
    }
    this.requestManager
      .currentOrNew('resendVerification', this.$services.registration.resendRegistrationConfirmation(this.email))
      .subscribe(
        () => {
          this.resentCode = true;
        },
        () => {
          this.$router.push('/login');
        }
      );
  }
}
</script>
