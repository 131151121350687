<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PaginatedParams } from 'ah-api-gateways';
import { keysIn, isEqual, cloneDeep } from 'lodash';
import { PropGetterSetter } from '../../../listing/useManagedListFilterInterfaces';

// FIXME delete file after moving all files to composition api
/**
 * Base List Filters component
 *
 * Meant for usage alongside a <Listing> component, controlling filters and/ord pagination parameters
 *
 * Emits:
 * - update:filters: emmited when `filters` changes. Can be used with .sync
 * - update:sortAndPageParams: emmited when `sortAndPageParams` changes. Can be used with .sync
 */
@Component({})
export default class ListFilters extends Vue {
  /**
   * Filters object. Sync-able via `update:filters` event
   */
  @Prop({ required: false }) filters?: any;

  /**
   * Sorting and pagination parameters object. Sync-able via `update:sortAndPageParams` event
   */
  @Prop({ required: false }) sortAndPageParams?: Partial<PaginatedParams>;

  /**
   * Whether to run setters when values are undefined. If changes are expected to happen during the filter lifecycle
   * (i.e. changing filter collections)
   * This value should be true
   */
  @Prop({ default: false }) runSettersOnUndefined?: boolean | string;

  filtersInner: any = {};

  /**
   * Copy of last emmited value to determine whether data was changed relative to last event
   * Checking agaisnt last emmited value prevents issues with debouncing
   * Resets when filters prop is updated
   */
  filtersEmmited: any = {};

  sortAndPageParamsInner: Partial<PaginatedParams> = {};

  beforeMount() {
    setTimeout(() => {
      // We check for filter changes on setTimeout, as to ensure any sync has already happened (i.e. from query string parameters)
      this.onFiltersInnerChange();
      this.onPaginationInnerChange();
    });
  }

  protected get knownFilters(): PropGetterSetter[] {
    return [];
  }

  protected get shouldRunSettersOnUndefined(): boolean {
    return this.runSettersOnUndefined !== false;
  }

  protected get knownSortAndPageParams(): PropGetterSetter[] {
    return [];
  }

  protected get filtersComputed() {
    const out: any = {};
    keysIn(this.filtersInner).forEach((k) => {
      if (this.knownFilters.findIndex((i) => i.key === k) === -1) {
        out[k] = this.filtersInner[k];
      }
    });

    this.knownFilters.forEach((f) => {
      const value = f.getter(this.filtersInner);
      if (value !== undefined) {
        out[f.key] = value;
      }
    });

    return out;
  }

  protected get sortAndPageParamsComputed() {
    const out: any = {};
    keysIn(this.sortAndPageParamsInner).forEach((k) => {
      if (this.knownSortAndPageParams.findIndex((i) => i.key === k) === -1) {
        out[k] = (this.sortAndPageParamsInner as any)[k];
      }
    });

    this.knownSortAndPageParams.forEach((f) => {
      const value = f.getter(this.sortAndPageParamsInner as any);
      if (value !== undefined) {
        out[f.key] = value;
      }
    });

    return out;
  }

  @Watch('filters', { deep: true, immediate: true })
  onFiltersChange() {
    this.knownFilters.forEach((f) => {
      if (this.shouldRunSettersOnUndefined || (this.filters && this.filters[f.key] !== undefined)) {
        f.setter((this.filters || {})[f.key], this.filters);
      }
    });
    this.filtersInner = cloneDeep(this.filters);
    this.filtersEmmited = cloneDeep(this.filters);
  }

  @Watch('sortAndPageParams', { immediate: true })
  onSortingChange() {
    this.knownSortAndPageParams.forEach((f) => {
      if (
        this.sortAndPageParams &&
        (this.shouldRunSettersOnUndefined || (this.sortAndPageParams as any)[f.key] !== undefined)
      ) {
        f.setter(((this.sortAndPageParams as any) || {})[f.key], this.sortAndPageParams as any);
      }
    });

    this.sortAndPageParamsInner = { ...this.sortAndPageParams };
  }

  @Watch('filtersComputed', { deep: true, immediate: true })
  onFiltersInnerChange() {
    if (!isEqual(this.filtersComputed, this.filters) || !isEqual(this.filtersEmmited, this.filtersComputed)) {
      this.filtersEmmited = cloneDeep(this.filtersComputed);
      this.$emit('update:filters', this.filtersEmmited);
    }
  }

  @Watch('sortAndPageParamsComputed', { deep: true, immediate: true })
  onPaginationInnerChange() {
    if (!isEqual(this.sortAndPageParamsComputed, this.sortAndPageParams)) {
      this.$emit('update:sortAndPageParams', this.sortAndPageParamsComputed);
    }
  }
}
</script>
