import { render, staticRenderFns } from "./TradeCountReportDashboardWidget.vue?vue&type=template&id=4b2f3f69&scoped=true"
import script from "./TradeCountReportDashboardWidget.vue?vue&type=script&lang=ts"
export * from "./TradeCountReportDashboardWidget.vue?vue&type=script&lang=ts"
import style0 from "./TradeCountReportDashboardWidget.vue?vue&type=style&index=0&id=4b2f3f69&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2f3f69",
  null
  
)

export default component.exports