<script setup lang="ts">
import { DebouncedFunc, debounce, isEqual } from 'lodash';
import { PaginatedParams } from 'ah-api-gateways';
import { PropType, ref, onMounted, onBeforeUnmount, watch } from 'vue';

/**
 * Base List block component
 *
 * Meant for usage alongside a <Listing> and a <fooFilter> components, controlling filter flow between both
 *
 * update-filter:  used for one-way syncing to an external value
 *
 * FIXME this component should use useManagedListBlock
 * - converting requires changing all props from `commonFilters` to `commonFilter` and removing `defaultFilters` in favor of setting an initial `filter` prop value
 */

const props = defineProps({
  /**
   * Possible set common filters of the list
   *
   * This filters will remain true despite the filters
   * set by the user
   */
  commonFilters: {
    type: Object as PropType<any>,
    required: false,
  },
  /**
   * Possible set initial filters of the list
   *
   * This filters can be overwritten by the user
   */
  defaultFilters: {
    type: Object as PropType<any>,
    required: false,
  },
  /**
   * Possible set common sort and page params of the list
   *
   * This params will remain true despite the sort and page
   * inputed by the user
   */
  commonSortAndPageParams: {
    type: Object as PropType<Partial<PaginatedParams>>,
    required: false,
  },
  /**
   * Possible set common sort and page params of the list
   *
   * This sort and page params can be overwritten by the user
   */
  defaultSortAndPageParams: {
    type: Object as PropType<Partial<PaginatedParams>>,
    required: false,
  },
});

const emit = defineEmits({
  'update:filter': (_filter: any) => true,
});

const filter = ref<any>({});

const sortAndPageParams = ref<Partial<PaginatedParams>>({});

const debouncedFilter = debounce((filter: any) => setFilter(filter), 800);

const debouncedSortAndPageParams = debounce((sortAndPageParams: any) => setSortAndPageParams(sortAndPageParams), 800);

const isBeforeMounted = ref(true);

onMounted(() => {
  setTimeout(() => (isBeforeMounted.value = false));
});

function setFilter(_filter: any) {
  const newFilter = { ..._filter, ...props.commonFilters };
  if (!isEqual(newFilter, filter.value)) {
    filter.value = newFilter;
    emit('update:filter', filter.value);
  }
}

function setSortAndPageParams(_sortAndPageParams: any) {
  if (!isEqual(_sortAndPageParams, sortAndPageParams.value)) {
    sortAndPageParams.value = { ..._sortAndPageParams, ...props.commonSortAndPageParams };
  }
}

function onFilterChange(filter: any) {
  if (isBeforeMounted.value) {
    setFilter(filter);
  } else {
    debouncedFilter(filter);
  }
}

function onSortAndPageParamsChange(sortAndPageParams: any) {
  if (isBeforeMounted.value) {
    setSortAndPageParams(sortAndPageParams);
  } else {
    debouncedSortAndPageParams(sortAndPageParams);
  }
}

onBeforeUnmount(() => {
  debouncedFilter.cancel();
  debouncedSortAndPageParams.cancel();
});

function onDefaultSortChange() {
  if (props.defaultSortAndPageParams) {
    sortAndPageParams.value = props.defaultSortAndPageParams;
  }
}

watch(() => props.defaultSortAndPageParams, onDefaultSortChange, { immediate: true });

function onDefaultFiltersChange() {
  if (props.defaultFilters) {
    setFilter(props.defaultFilters);
  }
}

watch(() => props.defaultFilters, onDefaultFiltersChange, { immediate: true });

function onCommonFiltersChange() {
  setFilter({ ...filter.value, ...props.commonFilters });
}

watch(() => props.commonFilters, onCommonFiltersChange, { immediate: true, deep: true });
</script>

<template>
  <div>
    <slot
      name="filters"
      v-bind="{ filter, setFilter, onFilterChange, sortAndPageParams, setSortAndPageParams, onSortAndPageParamsChange }"
    />
    <div class="mt-3">
      <slot name="list" v-bind="{ filter, setFilter, setSortAndPageParams, sortAndPageParams }" />
    </div>
  </div>
</template>
