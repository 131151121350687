import { MaybeRef, toRef } from '@vueuse/core';
import { noop } from 'lodash';
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { Vue } from 'vue-property-decorator';
import { RawLocation, Route } from 'vue-router';
import { useRoute, useRouter } from 'vue-router/composables';

export interface IRouteProtectorOptions {
  allowSubpaths?: MaybeRef<boolean>;
  allowQueryChange?: MaybeRef<boolean>;
  exitAllowed: MaybeRef<boolean>;
  onNavigationPrevented?: (to: Route) => void;
}

const defaultOptions: Required<IRouteProtectorOptions> = {
  allowSubpaths: true,
  allowQueryChange: true,
  exitAllowed: true,
  onNavigationPrevented: noop,
};

/**
 * Class to extend when a component needs to protect against route changes
 */

export function useRouteProtector(options?: IRouteProtectorOptions) {
  const route = useRoute();

  const router = useRouter();

  const opts = {
    ...defaultOptions,
    ...options,
  };

  const allowSubpaths = toRef(opts.allowSubpaths);

  const allowQueryChange = toRef(opts.allowQueryChange);

  const exitAllowed = toRef(opts.exitAllowed);

  const forceAllowChange = ref(false);

  const currentPath = ref('');

  const beforeEachUnsubscriber = ref<any>();

  function beforeUnloadListener(event: BeforeUnloadEvent) {
    if (!exitAllowed.value) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  function onAllowSubpathsChange() {
    if (allowSubpaths.value) {
      /**
       * Retrieves the closest match that rendered this or a direct parent (via <RouterView>)
       * This is necessary to allow RouteProtector behavior to be consistent
       * for both View and non-View components alike
       */
      const routeAux = [...route.matched].reverse().find((r) =>
        Object.keys(r.instances).find((k) => {
          let currComponent: Vue | null = new Vue({});
          while (currComponent) {
            if (r.instances[k] === currComponent) {
              return true;
            }
            currComponent = currComponent.$parent;
          }
          return false;
        })
      );
      currentPath.value = routeAux?.path || '';
    } else {
      currentPath.value = '';
    }
  }

  watch(allowSubpaths, onAllowSubpathsChange, { immediate: true });

  onBeforeMount(() => {
    beforeEachUnsubscriber.value = router.beforeResolve((to, from, next) => {
      if (forceAllowChange.value || exitAllowed.value) {
        next();
      } else if (allowSubpaths.value && to.matched.some((i) => i.path === currentPath.value)) {
        next();
      } else if (allowQueryChange.value && to.path === from.path) {
        next();
      } else {
        opts.onNavigationPrevented(to);
        next(false);
      }
    });
    window.addEventListener('beforeunload', beforeUnloadListener);
  });

  onBeforeUnmount(() => {
    beforeEachUnsubscriber.value();
    window.removeEventListener('beforeunload', beforeUnloadListener);
  });

  function forcePush(location: RawLocation) {
    forceAllowChange.value = true;
    router.push(location);
    setTimeout(() => {
      forceAllowChange.value = false;
    });
  }

  function forceReplace(location: RawLocation) {
    forceAllowChange.value = true;
    router.replace(location);
    setTimeout(() => {
      forceAllowChange.value = false;
    });
  }

  return {
    forcePush,
    forceReplace,
  };
}
