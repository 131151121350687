<template>
  <div>
    <h3>Trade Count</h3>
    <TradeCountReportChart :fromDate="fromDate" :toDate="toDate" />
    <div class="buttons text-right">
      <RouterLink class="button view-all transparent" :to="'/admin/activity/reports'">
        <span> View all trades </span>
        <IconArrowRight />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { TradeCountReportChart } from 'ah-reports';

@Component({
  components: { TradeCountReportChart },
})
export default class TradeCountReportDashboardWidget extends Vue {
  @Prop({ required: true }) fromDate!: Date;

  @Prop({ required: true }) toDate!: Date;
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
}

.trade-count {
  @include phablet {
    text-align: left !important;
    font-size: 0.5rem;
  }
  @include tablet {
    text-align: center !important;
    font-size: 0.5rem;
  }
  @include desktop {
    font-size: 0.9rem;
  }
  @include hd {
    font-size: 1rem;
  }
  .count {
    font-size: 4em;
    @include themedTextColor($color-primary);
  }

  .count-label {
    font-size: 1.5em;
  }
}

.buttons {
  @include phablet {
    margin-top: -3rem;
  }
  @include tablet {
    margin-top: -2rem;
  }
  @include desktop {
    margin-top: 0;
  }
}
</style>
