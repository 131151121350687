<template>
  <BoxGrid alignH="start" alignV="stretch" class="mt-3" v-if="client">
    <BoxGridItem class="info-block mb-2 pl-2" align-self="stretch" cols="12" lg="4" md="4">
      <ClientAssigneeInfo :client="client" />
    </BoxGridItem>
    <BoxGridItem class="info-block mb-md-2 mb-sm-3 pl-md-3 pl-sm-2" align-self="stretch" cols="12" lg="4" md="4">
      <ClientMarkupInfo :client="client" />
    </BoxGridItem>
    <BoxGridItem class="info-block mb-md-2 mb-sm-3 pl-md-3 pl-sm-2" align-self="stretch" cols="12" lg="4" md="4">
      <ClientFeeInfo :client="client" />
    </BoxGridItem>
  </BoxGrid>
</template>

<script lang="ts">
import { Client } from 'ah-api-gateways';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ClientAssigneeInfo from './ClientAssigneeInfo.vue';
import ClientFeeInfo from './ClientFeeInfo.vue';
import ClientMarkupInfo from './ClientMarkupInfo.vue';

@Component({
  components: {
    ClientAssigneeInfo,
    ClientMarkupInfo,
    ClientFeeInfo,
  },
})
export default class ClientRowInfo extends Vue {
  @Prop({ required: true }) client!: Client;
}
</script>

<style lang="scss" scoped>
::v-deep .btn {
  @include upToResolution($tabletResolution) {
    width: 12rem;
  }
}

.info-block:not(:last-child) {
  @include tablet {
    @include themedPropColor('border-right', $color-primary, $color-dark-primary, '', ' 1px solid');
  }
  * {
    min-height: 1.5rem;
  }
  ::v-deep {
    .rows-block {
      @include tablet {
        min-height: 13rem;
      }
    }
    .info-block {
      .row {
        @include upToResolution($tabletResolution) {
          * {
            font-size: 13px;
          }
          font-weight: $font-weight-semibold;
        }
      }
      .percentage-text {
        margin-left: 3rem;
        @include upToResolution($tabletResolution) {
          font-size: 13px;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
</style>
