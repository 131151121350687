import { email, helpers, maxLength, required } from '@vuelidate/validators';
import { personTitleField } from 'ah-common-lib/src/form/formModels';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { ValidationRuleSet } from 'ah-common-lib/src/form/interfaces';
import {
  checkboxField,
  emailField,
  nullField,
  phoneField,
  selectField,
  textField,
  radioField,
  dateField,
  financialAmountField,
} from 'ah-common-lib/src/form/models';
import {
  date,
  ifTest,
  maxDate,
  optional,
  requiredIfStateValue,
  url,
  validName,
} from 'ah-common-lib/src/form/validators';
import {
  tradeFrequencyOptions,
  sourceOfFundOptions,
  expectedVolumeOptions,
  reasonForAccountUsageOptions,
} from 'ah-common-lib/src/helpers/questionnaire';
import { EmirClassification, emirClassificationOptionsFullLabel } from 'ah-api-gateways';
import { subYears } from 'date-fns';

const defaultState = { required: true, fieldWrapperClass: 'col col-4 px-4', showRequiredMarkers: true };

export const makeMemberForm = (
  hidePermissions = false,
  emailValidations?: Partial<ValidationRuleSet>,
  isOwner = false
) =>
  makeFormModel({
    name: 'memberForm',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2 px-4', showRequiredMarkers: true }),
      nullField('sep1', { fieldWrapperClass: 'col col-12 m-0' }),
      textField(
        'firstName',
        'First Name',
        { ...defaultState, fieldWrapperClass: 'col col-4  px-4' },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField('lastName', 'Last Name', defaultState, { required: requiredIfStateValue('firstName'), validName }),
      emailField(
        'email',
        `Email Address`,
        { ...defaultState, maxLength: 100, readonly: isOwner },
        {
          required,
          email,
          ...emailValidations,
        }
      ),
      phoneField('phoneNumber', 'Mobile Number', { ...defaultState, readonly: isOwner }),
      dateField(
        'birthDate',
        'Date of Birth',
        {
          useDateTime: false,
          required: true,
          errorMessages: {
            maxDate: 'Must be 18 years or older.',
          },
          fieldWrapperClass: 'col col-4 px-4',
        },
        {
          date: ifTest(date, (val) => val instanceof Date),
          required: ifTest(requiredIfStateValue('birthDate'), (a) => !(a instanceof Date)),
          maxDate: maxDate(subYears(new Date(), 18)),
        }
      ),
      financialAmountField(
        'ownershipPercentage',
        'Percentage of Ownership',
        {
          fieldWrapperClass: 'col col-4 px-4',
          label: 'You may include up to 2 decimal places.',
          errorMessages: {
            ownershipLimits: 'Must be a value between 25% and 100%',
          },
        },
        {
          required,
          ownershipLimits: optional((value) => value >= 25 && value <= 100),
        }
      ),
      textField(
        'permissions',
        'Requested Permissions',
        { ...defaultState, readonly: true, hidden: hidePermissions },
        {}
      ),
    ],
  });

export const makeCompanyDetailsFM = () =>
  makeFormModel({
    name: 'companyDetailsForm',
    fieldType: 'form',
    fields: [
      selectField('countryCode', 'Country of Incorporation', [], { ...defaultState, readonly: true }),
      selectField('entityType', 'Company Type', [], { ...defaultState, placeholder: 'Select One' }),
      textField('name', 'Registered Company Name', defaultState),
      textField('registrationNumber', 'Company number', defaultState),
      textField('sectorDescription', 'Sector', defaultState),
      textField(
        'website',
        'Company website',
        {
          fieldWrapperClass: 'col col-4 px-4',
          maxLength: 100,
          errorMessages: { url: 'Incorrect URL ' },
        },
        // Website marked as not required for review purposes
        { url }
      ),
      phoneField('phoneNumber', 'Company Telephone', defaultState),
    ],
  });

export const makeCompanyAddressFM = (isEditable = false) =>
  makeFormModel({
    name: 'tradingFM',
    fieldType: 'form',
    fields: [
      textField(
        'addressLine',
        'Address Line 1',
        {
          ...defaultState,
          fieldWrapperClass: 'col col-4 px-4',
          errorMessages: { maxLength: 'Address Line 1 cannot have more than 250 characters.' },
        },
        { maxLength: maxLength(250), required: requiredIfStateValue('addressLine') }
      ),
      textField(
        'city',
        'City',
        {
          ...defaultState,
          fieldWrapperClass: 'col col-4 px-4',
          errorMessages: { maxLength: 'City cannot have more than 86 characters.' },
        },
        { maxLength: maxLength(86), required: requiredIfStateValue('city') }
      ),
      textField(
        'stateOrProvince',
        'State/Province/County',
        {
          ...defaultState,
          fieldWrapperClass: 'col col-4 px-4',
          errorMessages: { maxLength: 'State/Province cannot have more than 128 characters.' },
        },
        { maxLength: maxLength(128), required: requiredIfStateValue('stateOrProvince') }
      ),
      textField(
        'postalCode',
        'Postcode / ZIP code',
        {
          ...defaultState,
          fieldWrapperClass: 'col col-4 px-4',
          errorMessages: { maxLength: 'Postcode cannot have more than 10 digits.' },
        },
        { maxLength: maxLength(10), required: requiredIfStateValue('postalCode') }
      ),
      selectField('countryCode', 'Country', [], {
        ...defaultState,
        fieldWrapperClass: 'col col-4 px-4',
        readonly: isEditable,
      }),
    ],
  });

export const makeTradingDetailsFM = () =>
  makeFormModel({
    name: 'tradingFM',
    fieldType: 'form',
    fields: [
      checkboxField(
        'fxSpotInterest',
        'FX Spot',
        false,
        {
          fieldWrapperClass: 'col col-4  px-4',
          errorMessages: { required: 'At least one product interest is required.' },
        },
        {
          required: helpers.withParams({ type: 'anyFxSet' }, (val: any, ctx: any) => {
            return !!val || !!ctx.fxForwardInterest;
          }),
        }
      ),
      checkboxField(
        'fxForwardInterest',
        'FX Forwards',
        false,
        {
          fieldWrapperClass: 'col col-4 px-4',
          errorMessages: { required: 'At least one product interest is required.' },
        },
        {
          required: helpers.withParams({ type: 'anyFxSet' }, (val: any, ctx: any) => {
            return !!val || !!ctx.fxSpotInterest;
          }),
        }
      ),
      nullField('sep1', { fieldWrapperClass: 'col col-12 m-0' }),
      selectField('sourceOfFundsSelection', 'Source of Funds', sourceOfFundOptions, {
        ...defaultState,
        fieldWrapperClass: 'col col-4 px-4',
        placeholder: 'Select One',
      }),

      nullField('sep1', { fieldWrapperClass: 'col col-12 m-0' }),
      textField('sourceOfFunds', '', {
        ...defaultState,
        hidden: true,
        placeholder: 'Please specify...',
        maxLength: 100,
      }),
      nullField('sep1', { fieldWrapperClass: 'col col-12 m-0' }),
      selectField('reason', 'Reason for use of account', reasonForAccountUsageOptions, {
        ...defaultState,
        placeholder: 'Select One',
        showRequiredMarkers: true,
      }),

      selectField('buyCurrencies', 'Which currencies will you be buying?', [], {
        ...defaultState,
        placeholder: 'Select all that apply',
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'currencies',
      }),
      selectField('sellCurrencies', 'Which currencies will you be selling?', [], {
        ...defaultState,
        placeholder: 'Select all that apply',
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'currencies',
      }),
      selectField('tradeFrequency', 'Frequency of activity', tradeFrequencyOptions, {
        ...defaultState,
        placeholder: 'Select One',
        fieldWrapperClass: 'col col-4 px-4 mt-4',
      }),
      selectField('maxExpectedVolume', 'Volumes', expectedVolumeOptions, {
        ...defaultState,
        placeholder: 'Select One',
        fieldWrapperClass: 'col col-4 px-4 mt-4',
      }),
      selectField('beneficiaryCountries', 'Destination of Payments', [], {
        ...defaultState,
        placeholder: 'Select all that apply',
        multiple: true,
        showBadges: true,
        maxFulltextLabels: 0,
        itemsCountLabel: 'countries',
        appendToBody: true,
        maxItems: 5,
      }),
      selectField('reportingCurrency', 'What is your tax reporting currency', [], {
        ...defaultState,
        placeholder: 'Select One',
      }),
      radioField('emirClassification', 'EMIR Classification', emirClassificationOptionsFullLabel, {
        required: true,
        defaultValue: EmirClassification.NFC,
        inline: true,
        fieldWrapperClass: 'col col-12 px-4 mt-2',
      }),
    ],
  });
