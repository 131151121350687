<template>
  <div>
    <h3>Commission</h3>
    <CommissionCountReportChart :fromDate="fromDate" :toDate="toDate" />
    <div class="buttons text-right">
      <RouterLink class="button view-all transparent" :to="'/admin/activity/reports'">
        <span> View report </span>
        <IconArrowRight />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CommissionCountReportChart } from 'ah-reports';

@Component({
  components: { CommissionCountReportChart },
})
export default class CommissionCountReportDashboardWidget extends Vue {
  @Prop({ required: true }) fromDate!: Date;

  @Prop({ required: true }) toDate!: Date;
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  padding-bottom: 33%;
}

.trade-count {
  @include phablet {
    text-align: left !important;
    font-size: 0.5rem;
  }
  @include tablet {
    text-align: center !important;
    font-size: 0.5rem;
  }
  @include desktop {
    font-size: 0.9rem;
  }
  @include hd {
    font-size: 1rem;
  }
  .count {
    font-size: 4em;
    @include themedTextColor($color-primary);
  }

  .count-label {
    font-size: 1.5em;
  }
  .count-subtitle {
    @include themedTextColor($color-text-secondary);
    @include upToResolution($desktopResolution) {
      font-size: 1.3em;
    }
    @include desktop {
      font-size: 1em;
    }
  }
}

.buttons {
  @include phablet {
    margin-top: -3rem;
  }
  @include tablet {
    margin-top: -2rem;
  }
  @include desktop {
    margin-top: 0;
  }
}
</style>
