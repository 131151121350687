var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.collaterals)?_c('div',{staticClass:"collateral-actions-help-text"},[(_setup.isMarginCallTriggered)?_c('h3',[_c('IconAlertCircle',{staticClass:"info-circle actions-required"}),_vm._v(" You have a Margin Call. ")],1):_c('h3',[_vm._v("Actions")]),_c('div',{staticClass:"messages"},[(
        _setup.collaterals.state === _setup.ClientCollateralState.BELOW_RECOMMENDED_RECAP_THRESHOLD ||
        _setup.collaterals.state === _setup.ClientCollateralState.ABOVE_RECOMMENDED_RECAP_THRESHOLD
      )?[_c('p',[_vm._v(" You can withdraw collateral up to the equivalent of "+_vm._s(_setup.collaterals.currency)+" "+_vm._s(_setup.formatCurrencyValue(_setup.collaterals.unutilizedCollateral))+". ")]),_c('p',[_vm._v(" However, it is advisable that you maintain your current collateral balance to avoid margin calls and the risk of positions being closed in volatile markets. We generally recommend maintaining an Effective Collateral Utilisation below "+_vm._s(_setup.recommendedRecapThresholdPercentage)+"% ")])]:(
        _setup.collaterals.state === _setup.ClientCollateralState.ABOVE_UC_THRESHOLD ||
        _setup.collaterals.state === _setup.ClientCollateralState.ABOVE_RECAPITALIZATION_THRESHOLD
      )?[_c('p',[_vm._v("Your effective collateral utilisation is currently at "+_vm._s(_setup.effectiveCollateralUtilizationPercentage)+"%.")]),_c('p',[_vm._v(" We recommend that you post "+_vm._s(_setup.collaterals.currency)+" "+_vm._s(_setup.formatCurrencyValue(_setup.collaterals.recommendedRecapitalizationAmount))+" to keep collateral utilization below "+_vm._s(_setup.recommendedRecapThresholdPercentage)+"% to avoid your positions being closed in volatile markets. ")])]:(_setup.isMarginCallTriggered)?[_c('p',[_vm._v("Your effective collateral utilisation is currently at "+_vm._s(_setup.effectiveCollateralUtilizationPercentage)+"%.")]),_c('p',[_vm._v("You MUST post collateral immediately to avoid your positions being closed.")])]:_c('div',{staticClass:"no-actions"},[_c('PieChartIcon',{staticClass:"icon"}),_c('h3',{staticClass:"message"},[_vm._v("No Action required")])],1)],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }