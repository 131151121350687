<template>
  <ResponsiveNav>
    <ul class="main">
      <RouterLink tag="li" class="menu-link" to="/admin" exact>
        <IconDashboard />
        <span class="menu-link-text"> Dashboard </span>
      </RouterLink>
      <SideNavActivityLink />
      <RouterLink tag="li" class="menu-link" to="/admin/payments">
        <IconWallet /> <span class="menu-link-text"> Payments</span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/admin/wallets">
        <IconWallet /> <span class="menu-link-text"> Wallets </span>
      </RouterLink>
      <RouterLink tag="li" class="menu-link" to="/admin/beneficiaries">
        <IconAgenda /> <span class="menu-link-text"> Beneficiaries </span>
      </RouterLink>
      <!-- <RouterLink tag="li" class="menu-link" to="/admin/training">
        <IconTraining />
        <span class="menu-link-text"> Training </span>
      </RouterLink> -->
    </ul>
    <template #bottom>
      <ul class="bottom">
        <SideNavSettingsLink basePath="/admin/settings" />
      </ul>
    </template>
  </ResponsiveNav>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import InfoTooltip from '@/app/components/common/InfoTooltip.vue';
import SideNavSettingsLink from './SideNavSettingsLink.vue';
import SideNavActivityLink from './SideNavActivityLink.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    InfoTooltip,
    SideNavActivityLink,
    SideNavSettingsLink,
  },
})
export default class AdminResponsiveNav extends Vue {
  private settingsOpen: boolean = false;

  get authStore() {
    return useAuthStore();
  }

  @Watch('this.$route.fullPath', { immediate: true })
  onRouteChange() {
    this.settingsOpen = this.$route.fullPath.includes('/admin/settings');
  }

  toggleSettings() {
    this.settingsOpen = !this.settingsOpen;
  }
}
</script>
