<script setup lang="ts">
import CurrencyFundingFee from 'ah-trades/src/components/fees/CurrencyFundingFee.vue';
import CurrencySelectInput from 'ah-trades/src/components/currency/CurrencySelectInput.vue';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import VButton from 'ah-common-lib/src/common/components/VButton.vue';
import WalletFundingDetailsViewer from './WalletFundingDetailsViewer.vue';
import { BModal } from 'bootstrap-vue';
import { FieldOptionObj, FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { RouterLink } from 'vue-router';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import { WalletFundingDetails, Wallet, BankingScheme, Client, FeePaymentType, PaymentRail } from 'ah-api-gateways';
import { computed, onBeforeMount, provide, reactive, ref, watch, set } from 'vue';
import { makeFormModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import { useAhWalletsState } from '..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

const props = withDefaults(
  defineProps<{
    buttonTitle?: string;
    wallet?: Wallet | null;
    noCurrencySelect?: string | boolean;
    currency?: string;
    allowedCurrencies?: string[];
    /**
     * Potentially set client to allow on behalf of actions
     */
    clientId?: string;
  }>(),
  {
    buttonTitle: 'Fund Account',
    wallet: null,
    noCurrencySelect: false,
    currency: '',
  }
);

const state = reactive<{
  fundingDetails: { [key: string]: WalletFundingDetails[] };
  selectedCurrency: string;
  onBehalfOfClient: Client | null;
}>({
  fundingDetails: {},
  selectedCurrency: '',
  onBehalfOfClient: null,
});

provide('onBehalfOfClient', state.onBehalfOfClient);

const fundingOptions: FieldOptionObj[] = [
  { label: 'Local funding', value: BankingScheme.LOCAL },
  { label: 'SWIFT funding', value: BankingScheme.SWIFT },
];

const fundingFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'fundingForm',
    state: {
      title: '',
      class: 'form-narrow margin-auto',
    },
    fieldType: 'form',
    fields: [
      radioField('funding', '', fundingOptions, {
        defaultValue: BankingScheme.LOCAL,
        inline: true,
        titleTooltip:
          'Regular funding is a regular local payment and intermediary bank charges will not apply. Priority payments are sent via the SWIFT network. When a payment is sent via SWIFT, intermediary bank charges may apply.',
      }),
    ],
  }),
  validation: null,
});

const modal = ref<InstanceType<typeof BModal> | null>(null);

const walletState = useAhWalletsState();

const onBehalfOfClient = useOnBehalfOf();

onBeforeMount(() => walletState.store.useSettingsStore().loadPaymentRails());

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k.startsWith('getClient-')) {
      onClientChange();
    } else if (k === 'loadFundingDetails') {
      loadFundingDetails(props.currency);
    }
  },
});

const modalTitle = computed(() => `Add money to ${props.wallet ? `${props.wallet.currency} ` : ''}wallet`);

const priorityFundingDetails = computed(() =>
  selectedCurrencyDetails.value?.find((d) => d.paymentRail === PaymentRail.SWIFT)
);

const bankingScheme = computed(() =>
  fundingFormDef.form.funding === BankingScheme.SWIFT ? BankingScheme.SWIFT : BankingScheme.LOCAL
);

const selectedCurrencyDetails = computed<WalletFundingDetails[] | undefined>(() =>
  state.selectedCurrency ? state.fundingDetails[state.selectedCurrency] : undefined
);

const fundingDetail = computed(() =>
  selectedCurrencyDetails.value?.find((details) => details.paymentRail === fundingFormDef.form.funding)
);

const rail = computed(() => {
  return walletState.store.useSettingsStore().getPaymentRail({
    currency: state.selectedCurrency,
    bankingScheme: BankingScheme.LOCAL,
    type: FeePaymentType.RECEIPT,
  });
});

const regularFundingDetails = computed(() => {
  const fundingDetailsArray: WalletFundingDetails[] = [];
  selectedCurrencyDetails.value?.forEach((d) => {
    if (d.paymentRail !== PaymentRail.SWIFT) {
      fundingDetailsArray.push(d);
    }
  });
  return fundingDetailsArray;
});

const currentClientId = computed(
  () => walletState.store.useAuthStore().loggedInIdentity!.client?.id ?? onBehalfOfClient.value?.id
);

function onClientChange() {
  if (props.clientId) {
    requestManager.manager
      .sameOrCancelAndNew(`getClient-${props.clientId}`, walletState.services.client.getClient(props.clientId))
      .subscribe((response) => (state.onBehalfOfClient = response));
  }
}

watch(
  () => [props.wallet, props.currency],
  () => {
    if (props.wallet) {
      state.selectedCurrency = props.wallet.currency;
    } else {
      state.selectedCurrency = props.currency;
    }
  },
  { immediate: true }
);

watch(() => props.clientId, onClientChange, { immediate: true });

watch(
  () => [state.fundingDetails, rail.value],
  () => {
    const model = getChildModel(fundingFormDef.form, 'funding')!;
    let options = [...fundingOptions];
    if (rail.value.length > 0) {
      options = rail.value.map((i) => ({ label: i.label + ' funding', value: i.paymentRail }));
      options[rail.value.length] = {
        label: 'SWIFT funding',
        value: BankingScheme.SWIFT,
      };
    }
    options = options.map((o: any) => {
      const out = { ...o };
      if (out.value === BankingScheme.SWIFT) out.disabled = !priorityFundingDetails.value;
      else out.disabled = regularFundingDetails.value.length === 0;
      return out;
    });

    setState(model, 'options', options);

    fundingFormDef.form.funding = BankingScheme.SWIFT;
  },
  { immediate: true }
);

function show() {
  modal.value?.show();
  if (state.selectedCurrency) {
    loadFundingDetails(state.selectedCurrency);
  }
}

function onCurrencyChanged(currency: string) {
  state.selectedCurrency = currency;
  loadFundingDetails(currency);
}

function loadFundingDetails(currency: string) {
  requestManager.manager
    .sameOrNew(
      `loadFundingDetails-currency`,
      walletState.services.wallet.getWalletFundingDetails(
        currency,
        props.clientId ?? currentClientId.value,
        onBehalfOfClient.value?.id
      )
    )
    .subscribe((details) => {
      set(state.fundingDetails, currency, details);
    });
}
</script>

<template>
  <span>
    <BModal :title="modalTitle" modal-class="side-modal" ref="modal" hide-footer>
      <div class="card-block mb-4">
        <h3>Bank Transfer</h3>
        <p>Follow the instructions on your bank to make a payment transfer using the following details:</p>
      </div>
      <CurrencySelectInput
        v-if="!wallet && noCurrencySelect === false"
        label="Currency"
        :allowedCurrencies="allowedCurrencies"
        :allowEmpty="false"
        :currency="state.selectedCurrency"
        @update:currency="onCurrencyChanged"
      />
      <LoadingOverlay
        variant="box"
        :opacity="0.85"
        noWrap
        :state="requestManager.manager.requestStates.loadFundingDetails"
        loadingText="Loading Info"
      />
      <div v-if="!requestManager.manager.anyPending && selectedCurrencyDetails" class="mt-4">
        <ValidatedForm :fm="fundingFormDef.form" :validation.sync="fundingFormDef.validation" />
        <div>
          <p class="light-text">
            For priority funding, intermediary bank fees may apply. This will be automatically deducted from your
            funding amount so please ensure you send enough to cover this fee.
          </p>
          <div class="card-block my-4 funding-details">
            <WalletFundingDetailsViewer v-if="fundingDetail" :fundingDetails="fundingDetail" />
          </div>
          <p class="light-text" v-if="!state.onBehalfOfClient">
            We will notify you when the funds are in your account<br />
            <RouterLink class="plain-color-link" to="/settings/notifications">Notification Settings</RouterLink>
          </p>
          <div class="card-block">
            <CurrencyFundingFee
              small="true"
              :bankingScheme="bankingScheme"
              :paymentCurrency="state.selectedCurrency"
              :clientId="clientId"
              :feePaymentType="FeePaymentType.RECEIPT"
            />
          </div>
        </div>
      </div>
    </BModal>
    <slot v-bind="{ show }">
      <VButton blurOnClick @click="show">{{ buttonTitle }}</VButton>
    </slot>
  </span>
</template>

<style lang="scss" scoped>
.funding-details {
  min-height: 6rem;
}
</style>
