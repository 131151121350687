<script setup lang="ts">
import ClientCompanyDetailsEditor from '@/app/components/settings/client/ClientCompanyDetailsEditor.vue';
import { Client, UploadedFile, ClientFileCategories } from 'ah-api-gateways';
import FileUploadCounter from 'ah-common-lib/src/common/components/upload/FileUploadCounter.vue';
import DocumentsUploader from '@/app/components/settings/client/DocumentsUploader.vue';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, ref } from 'vue';
import { useToast } from 'ah-common-lib/src/toast';
import { useTheme } from 'ah-theme';

const theme = useTheme();

const toast = useToast();

const files = ref<UploadedFile[]>([]);

const individualSettingsStore = useIndividualSettingsStore();

const supportEmail = computed(() => theme.val.helpEmail || 'info@alt21.com');

const client = computed(() => individualSettingsStore.client);

const companyFileCategories = [
  ClientFileCategories.SAMPLE_INVOICE,
  ClientFileCategories.INCORPORATED_DOCUMENT,
  ClientFileCategories.FINANCIAL_STATEMENT,
];

const numberOfFiles = computed(
  () => files.value.filter((file) => companyFileCategories.includes(file.category as ClientFileCategories)).length
);

function onClientSaved(client: Client) {
  individualSettingsStore.setClient(client);
  toast.success('Client updated successfully');
}

individualSettingsStore.loadClient(true);
</script>

<template>
  <div class="padded-space" id="company-client-information-settings-view">
    <div class="centered-space">
      <h2>Company Details</h2>
      <BoxGrid alignH="start">
        <BoxGridItem cols="12" md="12" lg="6" xl="6">
          <ClientCompanyDetailsEditor :client="client" @update:client="onClientSaved" />
        </BoxGridItem>
        <BoxGridItem cols="12" lg="6" xl="5">
          <BoxGrid>
            <BoxGridBlock cols="12">
              <FileUploadCounter :numberOfFiles="numberOfFiles" v-if="client">
                <DocumentsUploader
                  :hideUpdatedBy="false"
                  :show-personal="false"
                  :show-titles="false"
                  :files.sync="files"
                  :client="client"
                />
              </FileUploadCounter>
            </BoxGridBlock>
            <BoxGridBlock cols="12">
              <p class="mb-0">
                Unable to reset your password?
                <a :href="`mailto:${supportEmail}?subject=Account access support`">Contact us.</a>
              </p>
            </BoxGridBlock>
          </BoxGrid>
        </BoxGridItem>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
