<template>
  <AppBanner
    v-if="!requestManager.anyPending && withOutstandingFees && feesInfo && debtCurrencyWalletID"
    class="text-center"
    :class="[feesInfo.accountState === 'SUSPENDED' ? 'danger' : 'warning']"
  >
    <p class="mb-0">
      You have outstanding fees requiring attention in your GBP wallet. Click
      <RouterLink :to="feeLink" class="plain-color-link">here</RouterLink> to pay them.
    </p>
  </AppBanner>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import AppBanner from '@/app/components/common/AppBanner.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useWalletsStore } from '@/app/store/walletsModule';

@Component({
  components: {
    AppBanner,
  },
})
export default class FeeWarningBanner extends Mixins(WithRequestManager) {
  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };
  onRetryFromParentManager(k: string) {
    if (k === 'loadFeesInfo') {
      this.onIsLoggedInChange();
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get walletStore() {
    return useWalletsStore();
  }

  @Watch('isLoggedIn', { immediate: true })
  onIsLoggedInChange() {
    if (!this.isLoggedIn) {
      return;
    }
    if (!this.authStore.isClientUser) {
      this.requestManager.newPromise('loadFeesInfo', this.walletStore.loadPartnerFeesInfo({ force: false }));
    } else {
      this.requestManager.newPromise(
        'loadFeesInfo',
        this.walletStore.loadClientFeesInfo({
          force: false,
          clientId: this.authStore.loggedInIdentity!.client!.id,
        })
      );
    }
  }

  get feesInfo() {
    if (!this.authStore.isClientUser) {
      return this.walletStore.getPartnerFeesInfo();
    } else {
      return this.walletStore.getClientFeesInfo(this.authStore.loggedInIdentity!.client!.id!);
    }
  }

  get feeLink() {
    if (this.authStore.isClientUser) {
      return `/dashboard/wallets/${this.debtCurrencyWalletID}?tab=fees`;
    } else {
      return `/admin/wallets/${this.debtCurrencyWalletID}?tab=fees`;
    }
  }

  get isLoggedIn() {
    return this.authStore.isLoggedIn;
  }

  get withOutstandingFees() {
    return this.feesInfo && this.feesInfo.debtCumulative > 0;
  }

  get debtCurrencyWalletID() {
    return this.wallet?.id;
  }

  get wallet() {
    if (this.feesInfo) {
      return this.walletStore.getWallet(this.feesInfo.debtCurrency);
    }
  }
}
</script>
