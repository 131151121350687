<script setup lang="ts">
import { ref, computed, watch, Ref } from 'vue';
import { makeFormModel, setApiErrorMessages, setState, toDataModel, updateModel } from 'ah-common-lib/src/form/helpers';
import { radioField, textField, emailField, phoneField, financialAmountField } from 'ah-common-lib/src/form/models';
import {
  BaseIndividual,
  ClientFileCategories,
  ClientFileCategoriesToHuman,
  ClientFileCategoryDescriptions,
  EntityAddressHistory,
  FeatureFlag,
  UnsubmittedUbo,
  UploadedFile,
  VersionedObject,
} from 'ah-api-gateways';
import { generateTempUUID } from 'ah-common-lib/src/helpers/uuid';
import { FormModel, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { cloneDeep, debounce, isEqual } from 'lodash';
import { requiredIfStateValue, optional, checkParam, validName } from 'ah-common-lib/src/form/validators';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { personTitleField } from 'ah-common-lib/src/form/formModels';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { map, tap } from 'rxjs/operators';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { waitForEntityChange } from 'ah-requests';
import { registrationDateField } from '@/app/helpers/registration/forms';
import { useFeatureFlag, useFEFeatureFlag } from 'ah-common-stores';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import OnboardingPreviousAddressesForms from '../common/OnboardingPreviousAddressesForms.vue';
import RemoveButton from '../common/RemoveButton.vue';
import { required } from '@vuelidate/validators';
import { getServices } from '@/app/services';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

/**
 * Ultimate beneficiary owner(s) form
 *
 * The current component will display a form to add (if any) `maxUBO` number
 * of UBO users.
 *
 * Emits:
 * - update:ubos (payload: UnsubmittedUbo[])
 * - update:files (payload: UploadedFile[])
 */

interface UboFormDef {
  ubo: UnsubmittedUbo;
  form: FormModel;
  validation: FormValidation | null;
  currentAddressValidation: FormValidation | null;
  previousAddressesValidation: FormValidation | null;
}

const props = defineProps({
  ubos: { type: Array as () => UnsubmittedUbo[], default: () => [] },
  files: { type: Array as () => UploadedFile[], default: () => [] },
  showUboAddressHistoryErrors: { type: Boolean, default: false },
});

const emit = defineEmits({
  'update:ubos': (_value: UnsubmittedUbo[]) => true,
  'update:files': (_value: UploadedFile[]) => true,
  'update:validation': (_value: FormValidation) => true,
});

const uboUserFM = (useDOB = false) =>
  makeFormModel({
    name: 'uboUserForm',
    fieldType: 'form',
    fields: [
      personTitleField({ fieldWrapperClass: 'col col-2', showRequiredMarkers: true }),
      textField(
        'firstName',
        'First Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      financialAmountField(
        'ownershipPercentage',
        'Percentage of Ownership',
        {
          fieldWrapperClass: 'col col-5',
          label: 'You may include up to 2 decimal places.',
          errorMessages: {
            ownershipLimits: 'Must be a value between 25% and 100%',
          },
        },
        {
          required,
          ownershipLimits: optional((value) => value >= 25 && value <= 100),
        }
      ),
      emailField('email', 'Email Address', { showRequiredMarkers: true }),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { fieldWrapperClass: 'col col-6' },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
      ...(useDOB ? [registrationDateField({ fieldWrapperClass: 'col col-6', showRequiredMarkers: true })] : []),
    ],
  });

const uboChoiceFM = () =>
  makeFormModel({
    name: 'uboChoiceForm',
    fieldType: 'form',
    fields: [
      radioField(
        'choice',
        'Are there any UBOs?',
        [
          { label: 'Yes', value: 'yes' },
          { label: 'No', value: 'no' },
        ],
        {
          defaultValue: 'no',
          inline: true,
          fieldWrapperClass: 'mb-0 col col-12',
          showRequiredMarkers: true,
        }
      ),
    ],
  });

const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');

const { isActive: isOnboardingDocumentsActive } = useFeatureFlag({
  featureFlag: FeatureFlag.ONBOARDING_DOCUMENTS_ENABLED,
});

const authStore = useAuthStore();
const individualSettingsStore = useIndividualSettingsStore();
const services = getServices();

const uboChoiceForm = ref(uboChoiceFM());
const uboChoiceValidation = ref<FormValidation | null>(null);
const uboUserFormDefs: Ref<UboFormDef[]> = ref<UboFormDef[]>([]);
const innerFiles = ref<UploadedFile[]>([]);

const MAX_ALLOWED_UBOS = 4;

const resolutionLetterTitle = computed(() => ClientFileCategoriesToHuman[ClientFileCategories.RESOLUTION_LETTER]);
const resolutionLetterDescription = computed(
  () => ClientFileCategoryDescriptions[ClientFileCategories.RESOLUTION_LETTER]
);

const isUboAUKResident = (ubo?: UnsubmittedUbo) => {
  return ubo?.currentAddress && ubo.currentAddress.countryCode === 'GB';
};

const resolutionLetter = computed(() => {
  return innerFiles.value.find((document) => document.category === ClientFileCategories.RESOLUTION_LETTER) || null;
});

const shouldDisplayUbos = computed(() => {
  return uboChoiceForm.value.choice === 'yes';
});

const uboAddressApiErrors = computed(() =>
  props.ubos.map((ubo) =>
    ubo.addressHistoryApiError ? constructPayloadErrors<EntityAddressHistory>(ubo.addressHistoryApiError) : undefined
  )
);

const validUploadedFiles = computed(() => {
  return true;
  // FIXME: documents are no longer required in UBOs (temporary fix)

  // if (!this.shouldDisplayUbos) {
  //   return true;
  // } else {
  //   const anyIncompleteUboFiles = this.uboUsers.find((user) =>
  //     [ClientFileCategories.PROOF_OF_ADDRESS, ClientFileCategories.PHOTO_ID].find(
  //       (cat) =>
  //         !user.documents.find((d) => d.category === cat) &&
  //         !user.readyToUpload?.find((d) => d.category === cat && d.file)
  //     )
  //   );

  //   return !anyIncompleteUboFiles;
  // }
});

const validation = computed<FormValidation>(() => {
  let areAnyUbosInvalid = false;
  let areAnyUbosDirty = false;

  if (uboChoiceForm.value.choice === 'yes') {
    areAnyUbosInvalid = uboUserFormDefs.value?.some(
      (formDef) =>
        formDef.currentAddressValidation?.$invalid ||
        (isUboAUKResident(formDef.ubo) && formDef.previousAddressesValidation?.$invalid) ||
        formDef.validation?.$invalid
    );
    areAnyUbosDirty = uboUserFormDefs.value?.some(
      (formDef) =>
        formDef.currentAddressValidation?.$dirty ||
        (isUboAUKResident(formDef.ubo) && formDef.previousAddressesValidation?.$dirty) ||
        formDef.validation?.$dirty
    );
  }

  return {
    $invalid: uboChoiceValidation.value?.$invalid || !validUploadedFiles.value || areAnyUbosInvalid,
    $dirty: !!uboChoiceValidation.value?.$dirty || areAnyUbosDirty,
    $errors: [],
    $touch: () => {},
    $reset: () => {},
    $validate: async () => {},
  } as unknown as FormValidation;
});

const maxAllowedUsersReached = computed(() => {
  return uboUserFormDefs.value.length >= MAX_ALLOWED_UBOS;
});

function makeEmptyUbo(): UnsubmittedUbo {
  return {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    birthDate: '',
    ownershipPercentage: 25,
    email: '',
    id: generateTempUUID(),
    documents: [],
    currentAddress: {
      addressLine: '',
      city: '',
      postalCode: '',
      stateOrProvince: '',
      countryCode: '',
      residingFrom: '',
    },
    previousAddresses: [],
    readyToUpload: [
      { category: ClientFileCategories.PROOF_OF_ADDRESS, file: null },
      { category: ClientFileCategories.PHOTO_ID, file: null },
    ],
  };
}

function onUpdateUbo(uboFormDef: UboFormDef, update: Partial<UnsubmittedUbo>) {
  Object.assign(uboFormDef.ubo, update);
  emitUbosUpdate();
}

// Update to ubos models is done on a debounced cycle to avoid expensive template recalculations
const debouncedUbosUpdate = debounce((ubos: UnsubmittedUbo[]) => {
  emit('update:ubos', ubos);
}, 250);

function emitUbosUpdate() {
  if (shouldDisplayUbos.value) {
    debouncedUbosUpdate(uboUserFormDefs.value.map((item) => item.ubo));
  } else {
    debouncedUbosUpdate([]);
  }
}

function uploadResolutionLetter(file: File) {
  return services.client
    .uploadDocument(authStore.loggedInIdentity!.client!.id, ClientFileCategories.RESOLUTION_LETTER, file)
    .pipe(
      tap((update) => {
        if (update.finished) {
          waitForDocument(update.file).subscribe((document) => {
            if (document) {
              individualSettingsStore.setClientDocument({ document });
              const currFileIndex = innerFiles.value.findIndex((i) => i.category === document.category);

              currFileIndex > -1
                ? innerFiles.value.splice(currFileIndex, 1, document)
                : innerFiles.value.push(document);
            }
          });
        }
      })
    );
}

function waitForDocument(file: VersionedObject) {
  return waitForEntityChange(
    () => services.client.getDocuments(authStore.loggedInIdentity!.client!.id),
    (docs) => {
      return !!docs.find((d) => d.id === file.id);
    }
  ).pipe(map((docs) => docs.find((d) => d.id === file.id)!));
}

function deleteFile(file: UploadedFile) {
  return services.client.deleteDocument(authStore.loggedInIdentity!.client!.id, file.id).pipe(
    tap(() => {
      const currFileIndex = innerFiles.value.findIndex((i) => i.category === file.category);
      if (currFileIndex > -1) {
        innerFiles.value.splice(currFileIndex, 1);
      }

      individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
    })
  );
}

function downloadFile(file: UploadedFile) {
  return services.client.downloadSyncDocument(authStore.loggedInIdentity!.client!.id, file);
}

function getFile(user: UnsubmittedUbo, category: ClientFileCategories) {
  return (
    user.readyToUpload?.find((file) => file.category === category)?.file ||
    user.documents?.find((file) => file.category === category)
  );
}

function onFileChange(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
  if (file === null) {
    onFileRemoved(file, user, category);
  }
  user.readyToUpload = user.readyToUpload || [];
  let userFile = user.readyToUpload!.find((file) => file.category === category)!;
  if (!userFile) {
    userFile = { category, file: file };
    user.readyToUpload!.push(userFile);
  } else {
    userFile.file = file;
  }
  emitUbosUpdate();
}

function onFileRemoved(file: File, user: UnsubmittedUbo, category: ClientFileCategories) {
  user.readyToUpload = user.readyToUpload || [];

  let uploaded = user.documents!.find((file) => file.category === category)!;
  let userFile = user.readyToUpload!.find((file) => file.category === category)!;
  if (uploaded) {
    user.documents.splice(user.documents.indexOf(uploaded), 1);
  }

  if (!userFile) {
    userFile = { category, file: null };
    user.readyToUpload!.push(userFile);
  } else {
    userFile.file = null;
  }

  emitUbosUpdate();
}

function onFormEvent(event: FormEvent<any>) {
  if (event.event === 'form-field-set-value') {
    uboUserFormDefs.value.forEach((uboFormDef) => {
      uboFormDef.ubo = {
        ...uboFormDef.ubo,
        ...toDataModel(uboFormDef.form),
        documents: uboFormDef.ubo.documents,
        readyToUpload: uboFormDef.ubo.readyToUpload,
      };
    });
    setState(event.model, 'errors', []);
    emitUbosUpdate();
  }
}

function onChoiceChange(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    if (shouldDisplayUbos.value && uboUserFormDefs.value.length === 0) {
      addUser();
    }
    emitUbosUpdate();
  }
}

function remove(index: number) {
  if (uboUserFormDefs.value.length > 1) {
    uboUserFormDefs.value.splice(index, 1);
    emitUbosUpdate();
  } else {
    uboUserFormDefs.value = [];
    emit('update:ubos', []);
    uboChoiceForm.value.choice = 'no';
  }
}

function addUser() {
  uboUserFormDefs.value.push(generateUboForm());
  emitUbosUpdate();
}

function generateUboForm(ubo?: UnsubmittedUbo): UboFormDef {
  return {
    form: uboUserFM(dobInOnboardingFeatureActive.value),
    currentAddressValidation: null,
    previousAddressesValidation: null,
    validation: null,
    ubo: {
      id: generateTempUUID(),
      ...(ubo ? cloneDeep(ubo) : makeEmptyUbo()),
    },
  };
}

watch(
  () => props.files,
  () => {
    if (props.files !== null) {
      innerFiles.value = props.files;
    }
  },
  { immediate: true }
);

watch(
  [
    () => props.ubos,
    dobInOnboardingFeatureActive,
    () => props.ubos.map((ubo) => ubo.userCreationApiError?.occurrenceId),
  ],
  (_newVals, oldVals) => {
    if (props.ubos && props.ubos.length > 0) {
      uboChoiceForm.value.choice = 'yes';
      uboUserFormDefs.value = props.ubos.map((ubo) => {
        const uboForm =
          uboUserFormDefs.value.find((formDef) => formDef.ubo.id && formDef.ubo.id === ubo.id) || generateUboForm(ubo);
        updateModel(uboForm.form, ubo, undefined, true);

        const isErrorUnchanged = !!(oldVals[2] || []).find(
          (oldUboError) => oldUboError === ubo.userCreationApiError?.occurrenceId
        );
        if (!isErrorUnchanged) {
          setState(uboForm.form, 'errors', [], true);
          if (ubo.userCreationApiError) {
            const payloadErrorData = constructPayloadErrors<BaseIndividual>(ubo.userCreationApiError);
            setApiErrorMessages(payloadErrorData, uboForm.form);
          }
        }

        return uboForm;
      });
    } else {
      uboChoiceForm.value.choice = 'no';
      uboUserFormDefs.value = [];
    }
  },
  { immediate: true }
);

watch(innerFiles, () => {
  if (!isEqual(innerFiles.value, props.files)) {
    emit('update:files', innerFiles.value);
  }
});

watch(
  validation,
  () => {
    if (validation.value) {
      emit('update:validation', validation.value as FormValidation);
    }
  },
  { immediate: true }
);
</script>

<template>
  <div class="card-block">
    <h3>Ultimate Beneficial Owner Information</h3>
    <div>
      <ValidatedForm :fm="uboChoiceForm" :validation.sync="uboChoiceValidation" @form-event="onChoiceChange">
        <template #uboChoiceForm.choice:label>
          <label class="field-group-field-label mb-0">
            <span>Are there any UBOs? *</span>
            <p class="text-small text-muted">
              An Ultimate Beneficial Owner (UBO) is somebody who owns 25% or more of the organisation.
            </p>
          </label>
        </template>
        <template #uboChoiceForm.choice:after>
          <p class="text-small text-muted">
            If there are no identified UBO's for the business, please provide us with a Proof of Authorised Signatory
            Document, which will verify any Authorised users for the account.
          </p>
        </template>
      </ValidatedForm>
      <p class="text-secondary"></p>
      <ExpandTransition appear>
        <div v-if="shouldDisplayUbos">
          <p class="text-small text-muted">
            We need an authorised signatory of the company to sponsor the application. this link will be sent to the
            signatory upon completion.
          </p>
          <div v-for="(uboFormDef, index) in uboUserFormDefs" :key="uboFormDef.ubo.id">
            <hr class="mb-5 mt-4" v-if="index !== 0" />
            <div class="user-holder my-3">
              <div class="d-flex justify-content-between mb-4">
                <h3 class="current-account-heading my-0">UBO</h3>
                <RemoveButton :removeButtonText="'Remove UBO'" @remove="remove(index)" />
              </div>
              <ValidatedForm
                :fm="uboUserFormDefs[index].form"
                :validation.sync="uboUserFormDefs[index].validation"
                @form-event="onFormEvent"
              >
                <template #uboUserForm.email:before>
                  <h3 class="current-account-heading">UBO's Current Address</h3>
                  <RegistrationAddressForm
                    :address="uboFormDef.ubo.currentAddress"
                    @update:address="
                      onUpdateUbo(uboFormDef, { currentAddress: { ...uboFormDef.ubo.currentAddress, ...$event } })
                    "
                    :validation.sync="uboUserFormDefs[index].currentAddressValidation"
                    :isCountryEditable="true"
                    :apiErrors="uboAddressApiErrors[index]?.fields?.currentAddress"
                  />
                  <OnboardingPreviousAddressesForms
                    class="mb-4"
                    v-if="isUboAUKResident(uboFormDef.ubo)"
                    :previousAddresses="uboFormDef.ubo.previousAddresses"
                    @update:previousAddresses="onUpdateUbo(uboFormDef, { previousAddresses: $event })"
                    :validation.sync="uboUserFormDefs[index].previousAddressesValidation"
                    :currentAddressResidenceDate="uboFormDef.ubo.currentAddress.residingFrom"
                    @update:currentAddressResidenceDate="
                      onUpdateUbo(uboFormDef, {
                        currentAddress: { ...uboFormDef.ubo.currentAddress, residingFrom: $event },
                      })
                    "
                    :showErrorMessage="showUboAddressHistoryErrors"
                    :isUboLabel="true"
                    :apiErrors="uboAddressApiErrors[index]"
                  />
                </template>
                <template #uboUserForm.phoneNumber:after>
                  <span class="text-muted">
                    <ul class="text-small pl-4 my-1">
                      <li>Must include country code</li>
                    </ul>
                  </span>
                </template>
                <template #uboUserForm.ownershipPercentage:append>
                  <BInputGroupText class="plus-minus">
                    <div class="percent">%</div>
                  </BInputGroupText>
                </template>
                <template #uboUserForm.ownershipPercentage:after>
                  <span class="text-muted">
                    <ul class="text-small pl-0 my-1">
                      You may include up to 2 decimal places
                    </ul>
                  </span>
                </template>
              </ValidatedForm>
              <template v-if="isOnboardingDocumentsActive">
                <FileUploader
                  title="Proof of Address"
                  :maxSize="10485760"
                  description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
                  :accept="defaultAcceptanceFileTypes"
                  acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                  :selected="getFile(uboFormDef.ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                  @update:selected="onFileChange($event, uboFormDef.ubo, ClientFileCategories.PROOF_OF_ADDRESS)"
                  uploadRequest="Don't allow"
                  :auto-upload="false"
                  hide-warning
                />
                <FileUploader
                  title="Proof of ID"
                  :maxSize="10485760"
                  :accept="defaultAcceptanceFileTypes"
                  acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
                  description="Clear, colour copy of driving license or passport (in date, with all information legible)"
                  :selected="getFile(uboFormDef.ubo, ClientFileCategories.PHOTO_ID)"
                  @update:selected="onFileChange($event, uboFormDef.ubo, ClientFileCategories.PHOTO_ID)"
                  uploadRequest="Don't allow"
                  :auto-upload="false"
                  hide-warning
                />
              </template>
            </div>
          </div>
          <div class="text-center">
            <VButton :disabled="maxAllowedUsersReached" class="btn-stroked" @click="addUser"> Add another UBO </VButton>
          </div>
        </div>
        <div v-else-if="!shouldDisplayUbos">
          <FileUploader
            :title="resolutionLetterTitle"
            :description="resolutionLetterDescription"
            tooltip
            :maxSize="10485760"
            :accept="defaultAcceptanceFileTypes"
            acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
            :uploadRequest="uploadResolutionLetter"
            :downloadRequest="downloadFile"
            :deleteRequest="deleteFile"
            :uploaded="resolutionLetter"
            hide-warning
          >
            <template #description-after>
              If you cannot provide this at this stage, please login to your platform to upload this at a later stage.
              It will be required before account approval.
            </template>
          </FileUploader>
        </div>
      </ExpandTransition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-holder {
  position: relative;
}

.close-button {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  right: 1.3rem;
  top: -0.5rem;
  ::v-deep svg {
    path {
      @include themedPropColor('fill', $color-primary);
    }
  }
}
.current-account-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
