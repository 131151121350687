<script setup lang="ts">
import { reactive, computed, watch, ref } from 'vue';
import { FieldOptionObj, FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import { useAhTradesState } from '../../../';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { QuotePriceResponse, FundingType, TradeFundingDetails } from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { ValidatedForm } from 'ah-common-lib/src/form/components';
import CollateralTransactionExecutor from '../../collaterals/CollateralTransactionExecutor.vue';
import TradeCollateralCreditFundForm from '../TradeCollateralCreditFundForm.vue';

/**
 * FIXME - This component serves the purpose to render the old flow of forward trades (US's PT-1828 and PT-1829)
 * This will exist until we remove the VARIATION_MARGIN_AND_LIMITS_FEATURE feature flag
 * After that, and since this component is only used here, we should remove it
 * */

const props = defineProps<{
  tradePriceResponse: QuotePriceResponse;
  tradeFundingDetails?: Partial<TradeFundingDetails>;
}>();

const emit = defineEmits<{
  (e: 'update:tradeFundingDetails', value: Partial<TradeFundingDetails>): void;
}>();

const fundingOptions: FieldOptionObj[] = [
  { label: 'Fund with Collateral', value: FundingType.COLLATERAL },
  { label: 'Fund with Credit', value: FundingType.CREDIT },
  { label: 'Fund with Both', value: FundingType.COLLATERAL_AND_CREDIT },
];

const hasPostedCollateral = ref(false);

const tradeState = useAhTradesState();

const onBehalfOf = useOnBehalfOf();

function loadCollaterals() {
  tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOf.value?.id });
}

loadCollaterals();

const collaterals = computed(() => {
  const clientId = onBehalfOf.value
    ? onBehalfOf.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const currency = computed(() => props.tradePriceResponse.homeCurrency);

const areCollateralsLoading = computed(() =>
  tradeState.store.useWalletsStore().areCollateralsLoading(onBehalfOf.value?.id)
);

const sufficientCollateral = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return (
    props.tradePriceResponse.homeCurrencyInitialMarginAmount <= 0 ||
    collaterals.value.marginCollateralAvailable >= props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const fundWithCollateralPossible = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return (
    sufficientCollateral.value &&
    collaterals.value.initialMarginCollateralAvailable >= props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const fundWithCreditPossible = computed(() => {
  if (!collaterals.value || areCollateralsLoading.value) {
    return false;
  }

  return (
    sufficientCollateral.value &&
    collaterals.value.initialMarginCreditAvailable >= props.tradePriceResponse.homeCurrencyInitialMarginAmount
  );
});

const fundingFM = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'fundingForm',
    fieldType: 'form',
    fields: [
      radioField('initialMarginFundingType', '', fundingOptions, {
        inline: true,
        required: false,
      }),
    ],
  }),
  validation: null,
});

const fundWithCollateral = computed(() => fundingFM.form.initialMarginFundingType === FundingType.COLLATERAL);

const fundWithCredit = computed(() => fundingFM.form.initialMarginFundingType === FundingType.CREDIT);

const isFormValid = computed(
  () =>
    (fundWithCollateral.value && fundWithCollateralPossible.value) ||
    (fundWithCredit.value && fundWithCreditPossible.value) ||
    false
);

function reCheckCollaterals() {
  hasPostedCollateral.value = true;
  loadCollaterals();
}

function emitValues(creditContribution?: number, collateralContribution?: number) {
  emit('update:tradeFundingDetails', {
    initialMarginFundingType: fundingFM.form.initialMarginFundingType,
    initialMarginFundingCurrency: currency.value,
    initialMarginCollateralFundingAmount: collateralContribution,
    initialMarginCreditFundingAmount: creditContribution,
    valid: isFormValid.value,
  });
}

/**
 * Initial fund type set
 */
if (!fundWithCreditPossible.value) {
  fundingFM.form.initialMarginFundingType = FundingType.COLLATERAL;
  emitValues(0, props.tradePriceResponse.homeCurrencyInitialMarginAmount);
} else {
  fundingFM.form.initialMarginFundingType = FundingType.CREDIT;
  emitValues(props.tradePriceResponse.homeCurrencyInitialMarginAmount, 0);
}

function onFormEvent(event: FormEvent) {
  if (event.event === 'form-field-set-value') {
    if (fundWithCollateral.value) {
      emitValues(0, props.tradePriceResponse.homeCurrencyInitialMarginAmount);
    } else if (fundWithCredit.value) {
      emitValues(props.tradePriceResponse.homeCurrencyInitialMarginAmount, 0);
    } else {
      emitValues();
    }
  }
}

watch(
  () => props.tradeFundingDetails,
  () => {
    if (props.tradeFundingDetails) {
      fundingFM.form.initialMarginFundingType = props.tradeFundingDetails.initialMarginFundingType;
    }
  },
  { immediate: true }
);

watch(isFormValid, () => {
  if (props.tradeFundingDetails) {
    emit('update:tradeFundingDetails', { ...props.tradeFundingDetails, valid: isFormValid.value });
  }
});
</script>

<template>
  <div>
    <ValidatedForm
      class="funding-type-form"
      :fm="fundingFM.form"
      :validation="fundingFM.validation"
      @form-event="onFormEvent"
    />
    <template v-if="fundWithCollateral">
      <div class="d-inline-flex">
        <h3 class="mr-3">Available Posted Collateral:</h3>
        {{ currency }} {{ formatCurrencyValue(collaterals?.initialMarginCollateralAvailable) }}
      </div>
      <p class="text-secondary" v-if="fundWithCollateralPossible && !hasPostedCollateral">
        You have sufficient margin collateral available to execute this trade.
      </p>
      <p class="text-secondary" v-else-if="fundWithCollateralPossible && hasPostedCollateral">
        You have posted sufficient collateral for this trade.
      </p>
      <div v-else>
        <div class="form-error-wrapper">
          <div>
            <IconAlertCircle class="icon text-danger" />
          </div>
          <div>You need to post collateral to execute this trade</div>
        </div>
        <div class="bordered-box">
          <h2 class="mb-2">Post collateral</h2>
          <CollateralTransactionExecutor
            btnTitle="Post Collateral"
            mode="post"
            @transaction-executed="reCheckCollaterals"
          />
        </div>
      </div>
    </template>

    <template v-else-if="fundWithCredit">
      <div class="d-inline-flex">
        <h3 class="mr-3">Available Credit:</h3>
        {{ currency }} {{ formatCurrencyValue(collaterals?.initialMarginCreditAvailable) }}
      </div>
      <p class="text-secondary" v-if="fundWithCreditPossible">
        You have sufficient credit available to execute this trade.
      </p>
      <div class="form-error-wrapper" v-else>
        <div>
          <IconAlertCircle class="icon text-danger" />
        </div>
        <div class="text-secondary">
          Your available credit is
          <b>{{ currency }} {{ formatCurrencyValue(collaterals?.initialMarginCreditAvailable) }}</b>
          , meaning you have insufficient credit to execute this trade. Please select Fund with Collateral, or Fund with
          Both.
        </div>
      </div>
    </template>
    <TradeCollateralCreditFundForm
      :tradeFundingDetails="tradeFundingDetails"
      :tradePriceResponse="tradePriceResponse"
      v-on="$listeners"
      v-else
    />
  </div>
</template>

<style lang="scss" scoped>
.form-error-wrapper {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  margin-bottom: 1rem;
  .icon {
    width: 2rem;
    height: auto;
    margin-right: 1rem;
  }
}

.bordered-box {
  border: 1px solid;
  padding: $padded-space;
  @include themedBorderColor($color-widgets-green);
}

.funding-type-form {
  margin-top: 2rem;
  ::v-deep {
    .field-group-input-container {
      display: flex;
    }
  }
}
</style>
