<template>
  <BoxGridBlock :loadingOverlayProps="{ state: requestManager.currentStates }" useLoadingOverlay overlayType="simple">
    <template v-if="group">
      <div class="corner-buttons">
        <GroupEditModal
          class="mr-2"
          :group.sync="group"
          v-slot="{ show }"
          @update:group="$emit('update:group', $event)"
        >
          <VButton class="manage-group-button" blurOnClick @click="show">Manage Group</VButton>
        </GroupEditModal>
        <ConfirmModal modalText="Permanently delete this group?" @ok="deleteGroup" v-slot="{ showModal }">
          <VButton blurOnClick class="delete-button btn-danger-secondary" @click="showModal">Delete</VButton>
        </ConfirmModal>
      </div>
      <div class="d-flex justify-content-between">
        <h3>{{ group.name }}</h3>
      </div>
      <PermissionsEditor targetType="group" :targetId="groupId" :role="groupRole" />
    </template>
  </BoxGridBlock>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { IndividualGroup, UserRole } from 'ah-api-gateways';
import GroupEditModal from '@/app/components/groups/GroupEditModal.vue';
import PermissionsEditor from '../settings/PermissionsEditor.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    GroupEditModal,
    PermissionsEditor,
  },
})
export default class GroupInfo extends Mixins(WithRequestManager) {
  @Prop({ required: true }) groupId!: string;

  private group: IndividualGroup | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadGroup') {
      this.onGroupChange();
    }
    if (k === `deleteGroup-${this.groupId}`) {
      this.deleteGroup();
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get groupService() {
    return this.authStore.isClientUser ? this.$services.clientGroup : this.$services.partnerGroup;
  }

  get groupParentId() {
    // Partner group endpoints do not use id in path, when called from a partner user
    return this.authStore.isClientUser ? this.authStore.loggedInIdentity!.client!.id : undefined;
  }

  get groupRole() {
    return this.authStore.isClientUser ? UserRole.CLIENT_INDIVIDUAL : UserRole.PARTNER_AGENT;
  }

  @Watch('groupId', { immediate: true })
  onGroupChange() {
    this.requestManager
      .sameOrCancelAndNew('loadGroup', this.groupService.getGroup(this.groupId, this.groupParentId), this.groupId)
      .subscribe((group) => {
        this.group = group;
      });
  }

  deleteGroup() {
    this.requestManager
      .currentOrNew(`deleteGroup-${this.groupId}`, this.groupService.deleteGroup(this.groupId, this.groupParentId))
      .subscribe(() => {
        this.$emit('group-deleted', this.groupId);
      });
  }
}
</script>

<style lang="scss" scoped>
.corner-buttons {
  position: absolute;
  right: math.div($padded-space, 2);
  top: math.div($padded-space, 2);
}
</style>
