<template>
  <div>
    <h3 class="mb-0">Top Accounts</h3>
    <TopAccountsCountReportChart :fromDate="fromDate" :toDate="toDate" />
    <div class="label text-center">Amounts in GBP</div>
    <div class="buttons text-right">
      <RouterLink class="button view-all transparent" :to="'/admin/activity/report'">
        <span> All Accounts </span>
        <IconArrowRight />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { BListGroup, BListGroupItem } from 'bootstrap-vue';
import take from 'lodash/take';
import { VChart } from 'ah-reports';
import { makeBarChartOptions, makeBarChartDataset } from 'ah-reports';
import { TopAccountsCountReportChart } from 'ah-reports';
import { linesColorPrimary } from 'ah-reports';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';

const CURRENCY = 'GBP';
const TOP_DISPLAY_AMOUNT = 5;

@Component({
  components: {
    VChart,
    BListGroup,
    BListGroupItem,
    TopAccountsCountReportChart,
  },
})
export default class TopAccountsDashboardWidget extends Mixins(WithRequestManager) {
  @Prop({ required: true }) fromDate!: Date;

  @Prop({ required: true }) toDate!: Date;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadData') {
      this.loadDates();
    }
  }

  private options = makeBarChartOptions(
    { horizontal: true, gridColor: linesColorPrimary },
    {
      scales: {
        y: {
          ticks: {
            autoSkip: false,
          },
        },
      },
    }
  );

  private data = makeBarChartDataset();

  private accounts: Array<{ clientId: string; clientName: string; tradedAmount: number }> = [];

  private currency = CURRENCY;

  get dates() {
    return {
      from: this.fromDate,
      to: this.toDate,
    };
  }

  @Watch('dates', { immediate: true })
  loadDates() {
    this.requestManager
      .cancelAndNew('loadData', this.$services.trade.getClientTopAccountsReport(this.fromDate, this.toDate))
      .subscribe((response) => {
        this.accounts = take(response.list, TOP_DISPLAY_AMOUNT);
        this.data.datasets![0].data! = this.accounts.map((i) => i.tradedAmount);
        this.data.labels = this.accounts.map((i) => i.clientName);

        // Force update by replacing prop reference entirely
        this.options = { ...this.options };
        this.data = { ...this.data };
      });
  }

  get authStore() {
    return useAuthStore();
  }

  get loggedInUser() {
    return this.authStore.loggedInIdentity;
  }
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 250px;
  padding-bottom: 33%;
}
.top-accounts-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  ol {
    width: 60%;
    list-style: none;
    counter-reset: li;
    li {
      counter-increment: li;
    }
    li::before {
      @include themedTextColor($color-primary);
      content: '.' counter(li);
      display: inline-block;
      width: 1em;
      margin-left: -1.5em;
      margin-right: 0.5em;
      text-align: right;
      direction: rtl;
    }
  }
  .top-accounts-item {
    display: grid;
    grid-template-columns: 10px 50% 50px auto;
    justify-items: start;
    align-items: center;
    .currency {
      justify-self: end;
    }
  }
}
.label {
  @include phablet {
    font-size: 10px;
    @include themedTextColor($color-text-secondary, $color-dark-text-secondary);
    margin-left: 4rem;
  }
  @include tablet {
    margin-left: -2rem;
  }
  @include desktop {
    text-align: left !important;
    margin-left: 10rem;
  }
}
</style>
