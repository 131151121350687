<template>
  <div>
    <LoadingIcon v-if="requestManager.requestStates.loadfutureCommissionData === 'pending'" class="svg" />
    <div v-else-if="data">
      <div v-if="data.totalCommissionsByCurrency">
        <!-- Table headers construction -->
        <!-- We use a swipeable box for mobile screens -->
        <VRow class="row">
          <VCol class="col-md-2 col-sm-4"> <label> Settlement date </label> </VCol>
          <template v-if="!isStackedView && headerKeys.length <= step">
            <VCol class="col-2 text-right" v-for="head in headerKeys" :key="head">
              <label> {{ head }} </label>
            </VCol>
          </template>
          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :step="step" showArrows>
              <template #content>
                <VRow alighn-h="start">
                  <VCol v-for="i in step" :key="i" class="text-right">
                    <label>
                      {{ headerKeys[index + i - 1] }}
                    </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max" showArrows>
              <template #content>
                <label class="m-0">
                  {{ headerKeys[index] }}
                </label>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-md-2 col-sm-4 column-total text-right mr-3">
            <label> Total (GBP) </label>
          </VCol>
        </VRow>
        <hr />

        <!-- Table list content construction -->
        <div class="table-content">
          <VRow class="row" v-for="row in data.commissionsBySettlementDate" :key="row.settlementDate">
            <VCol class="col-md-2 col-sm-4">
              <label> {{ row.settlementDate }} </label>
            </VCol>
            <template v-if="!isStackedView && headerKeys.length <= step">
              <VCol class="col-2 text-right" v-for="head in headerKeys" :key="head">
                <label v-if="row['commissionsByCurrency'][head]">
                  {{ formatCurrencyValue(row.commissionsByCurrency[head].totalPartnerProfit) }}
                </label>
                <label v-else> - </label>
              </VCol>
            </template>
            <VCol class="col-md-8" v-else-if="!isStackedView">
              <SwipeableBox :index.sync="index" :max="max" :step="step">
                <template #content>
                  <VRow>
                    <VCol v-for="i in step" :key="i" class="text-right">
                      <label v-if="row['commissionsByCurrency'][headerKeys[index + i - 1]]">
                        {{
                          formatCurrencyValue(row.commissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit)
                        }}
                      </label>
                      <label v-else-if="index + i <= headerKeys.length"> - </label>
                    </VCol>
                  </VRow>
                </template>
              </SwipeableBox>
            </VCol>
            <VCol class="col-4" v-else>
              <SwipeableBox :index.sync="index" :max="max">
                <template #content>
                  <label v-if="row['commissionsByCurrency'][headerKeys[index]]">
                    {{ formatCurrencyValue(row.commissionsByCurrency[headerKeys[index]].totalPartnerProfit) }}
                  </label>
                  <label v-else> - </label>
                </template>
              </SwipeableBox>
            </VCol>
            <VCol class="col-md-2 col-sm-4 column-total text-right">
              <label v-if="row.equivalentTotalValues">
                {{ formatCurrencyValue(row.equivalentTotalValues.totalPartnerProfit) }}
              </label>
            </VCol>
          </VRow>
        </div>
        <hr />

        <!-- Table totals construction -->
        <VRow>
          <VCol class="col-md-2 col-sm-4">
            <label> Total (in currency) </label>
          </VCol>
          <template v-if="!isStackedView && headerKeys.length <= step">
            <VCol class="col-2 text-right" v-for="(row, rowKey) in data.totalCommissionsByCurrency" :key="rowKey">
              <label v-if="row.totalPartnerProfit">
                {{ formatCurrencyValue(row.totalPartnerProfit) }}
              </label>
              <label v-else> - </label>
            </VCol>
          </template>

          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :step="step">
              <template #content>
                <VRow>
                  <VCol v-for="i in step" :key="i" class="text-right">
                    <label
                      v-if="
                        data &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]] &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit
                      "
                    >
                      {{
                        formatCurrencyValue(
                          data.totalCommissionsByCurrency[headerKeys[index + i - 1]].totalPartnerProfit
                        )
                      }}
                    </label>
                    <label v-else-if="index + i <= headerKeys.length"> - </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>

          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max">
              <template #content>
                <label
                  v-if="
                    data.totalCommissionsByCurrency[headerKeys[index]] &&
                    data.totalCommissionsByCurrency[headerKeys[index]].totalPartnerProfit
                  "
                >
                  {{ formatCurrencyValue(data.totalCommissionsByCurrency[headerKeys[index]].totalPartnerProfit) }}
                </label>
                <label v-else> - </label>
              </template>
            </SwipeableBox>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="col-md-2 col-sm-4">
            <label> Total (GBP equiv) </label>
          </VCol>
          <template v-if="!isStackedView && headerKeys.length <= step">
            <VCol class="col-2 text-right" v-for="(row, rowKey) in data.totalCommissionsByCurrency" :key="rowKey">
              <label v-if="row.equivalentValues.totalPartnerProfit">
                {{ formatCurrencyValue(row.equivalentValues.totalPartnerProfit) }}
              </label>
              <label v-else> - </label>
            </VCol>
          </template>

          <VCol class="col-md-8" v-else-if="!isStackedView">
            <SwipeableBox :index.sync="index" :max="max" :step="step">
              <template #content>
                <VRow>
                  <VCol v-for="i in step" :key="i" class="text-right">
                    <label
                      v-if="
                        data &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]] &&
                        data.totalCommissionsByCurrency[headerKeys[index + i - 1]].equivalentValues.totalPartnerProfit
                      "
                    >
                      {{
                        formatCurrencyValue(
                          data.totalCommissionsByCurrency[headerKeys[index + i - 1]].equivalentValues.totalPartnerProfit
                        )
                      }}
                    </label>
                    <label v-else-if="index + i <= headerKeys.length"> - </label>
                  </VCol>
                </VRow>
              </template>
            </SwipeableBox>
          </VCol>

          <VCol class="col-4" v-else>
            <SwipeableBox :index.sync="index" :max="max">
              <template #content>
                <label
                  v-if="
                    data.totalCommissionsByCurrency[headerKeys[index]] &&
                    data.totalCommissionsByCurrency[headerKeys[index]].equivalentValues.totalPartnerProfit
                  "
                >
                  {{
                    formatCurrencyValue(
                      data.totalCommissionsByCurrency[headerKeys[index]].equivalentValues.totalPartnerProfit
                    )
                  }}
                </label>
                <label v-else> - </label>
              </template>
            </SwipeableBox>
          </VCol>
          <VCol class="col-md-2 col-sm-4 column-total text-right mr-4">
            <label v-if="data.equivalentTotalValues.totalPartnerProfit">
              {{ formatCurrencyValue(data.equivalentTotalValues.totalPartnerProfit) }}
            </label>
            <label v-else> - </label>
          </VCol>
        </VRow>
        <span class="chart-label">GBP equivalents are based on the current exchange rate and subject to change.</span>
      </div>

      <!-- No content found message -->
      <div v-else>No data found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { FutureCommissionSettlementSchedule } from 'ah-api-gateways';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import SwipeableBox from '@/app/components/common/SwipeableBox.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';

@Component({
  components: {
    SwipeableBox,
  },
})
export default class FutureCommissionSchedule extends Mixins(WithRequestManager) {
  private data: FutureCommissionSettlementSchedule | null = null;

  private headerKeys: string[] = [];

  private index: number = 0;

  private step: number = 4;

  formatCurrencyValue = formatCurrencyValue;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadfutureCommissionData') {
      this.loadData();
    }
  }

  get isStackedView() {
    return this.$mediaQuery.is('smDown');
  }

  @Watch('isStackedView', { immediate: true })
  onViewSizeChange() {
    this.index = 0;
  }

  get max() {
    return this.headerKeys.length - 1;
  }

  created() {
    this.loadData();
  }

  loadData() {
    this.requestManager
      .sameOrCancelAndNew('loadfutureCommissionData', this.$services.trade.getFutureCommissionReport())
      .subscribe((response) => {
        this.headerKeys = [];
        this.data = response;
        this.headerKeys = Object.keys(this.data.totalCommissionsByCurrency);
      });
  }
}
</script>

<style lang="scss" scoped>
.table-content {
  position: relative;
  @include upToResolution($tabletResolution) {
    max-height: calc(30px * 10);
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.column-total {
  position: absolute;
  right: 0;
}

.chart-label {
  font-size: 13px;
  opacity: 0.5;
}
</style>
