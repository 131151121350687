<template>
  <div class="notifications-list">
    <div v-for="(item, index) in updates" :key="item.id">
      <span v-if="index === 0 || !isSameDay(updates[index - 1], item)" class="date-label text-secondary d-block">
        <hr v-if="index !== 0" class="mb-4" />
        <h3>{{ formatDate(item.date) }}</h3>
      </span>
      <UpdateEntryCard :update="item" />
    </div>
    <div
      v-if="!noUpdates && hasMoreUpdates"
      v-on-intersect="($event) => onInfiniteLoad"
      class="loader d-flex justify-content-center"
    >
      <LoadingIcon v-if="requestManager.anyPending" class="loading-icon" />
    </div>
    <LoadingIcon v-if="noUpdates && requestManager.anyPending" class="loading-icon" />
    <div v-else-if="noUpdates" class="notification-card card-block text-center">No updates found.</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { UpdateEntry, UpdateType } from 'ah-api-gateways';
import UpdateEntryCard from './UpdateEntryCard.vue';
import { format, isSameDay, isToday, isYesterday } from 'date-fns';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useNotificationsStore } from 'ah-notifications/src/store';

@Component({
  components: {
    UpdateEntryCard,
  },
})
export default class UpdatesListing extends Mixins(WithRequestManager) {
  @Prop({ default: null }) updateTypeSelected!: UpdateType | null;

  updates: UpdateEntry[] = [];

  total = -1;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadUpdates') {
      this.loadMoreItems();
    }
  }

  get notificationsStore() {
    return useNotificationsStore();
  }

  mounted() {
    if (this.notificationsStore.updateUnreadCount > 0) {
      this.notificationsStore.markAllAsRead('updates');
    }
  }

  get hasMoreUpdates() {
    return this.total === -1 || this.updates.length >= this.total;
  }

  onInfiniteLoad(intersection: IntersectionObserverEntry) {
    if (intersection.isIntersecting) {
      this.loadMoreItems();
    }
  }

  isSameDay(a: UpdateEntry, b: UpdateEntry) {
    return isSameDay(new Date(a.date), new Date(b.date));
  }

  loadMoreItems() {
    this.requestManager
      .currentOrNew(
        'loadUpdates',
        this.$services.updates.listUpdateEntries({
          sort: 'date,desc',
          type: this.updateTypeSelected || undefined,
        })
      )
      .subscribe((list) => {
        this.total = list.total;
        this.updates.push(...list.list);
      });
  }

  formatDate(date: string) {
    if (isToday(new Date(date))) {
      return 'TODAY';
    } else if (isYesterday(new Date(date))) {
      return 'YESTERDAY';
    }

    return format(new Date(date), 'EEEE, LLLL d yyyy');
  }

  get noUpdates() {
    return !this.updates || this.updates.length === 0;
  }

  @Watch('updateTypeSelected', { immediate: true })
  onUpdateTypeChanges() {
    this.updates = [];
    this.total = -1;
    this.loadMoreItems();
  }
}
</script>

<style lang="scss" scoped>
.loading-icon {
  width: 1.5em;
  height: 1.5em;
}

.date-label {
  font-size: 0.7em;
  font-weight: 700;
}

hr {
  @include themedBorderColor($color-box-border, $color-box-border);
}
</style>
