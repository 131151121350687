import { HttpBasedService, HttpService, HttpOptions, HttpRequestOptions, uploadFileWithUpdates } from 'ah-requests';
import {
  ComplianceStatusObject,
  PublicComplianceCase,
  FileCategory,
  FileUploadRequest,
  VersionedObject,
  UploadedFile,
  BaseIndividual,
  PaginatedResponse,
  PaginatedQuery,
  EntityAddressHistory,
  AddressHistoryItem,
  AddressHistoryListFilterParams,
  standardiseQuerySort,
  EntityAddressHistoryResponse,
  PreferredBankingProvider,
} from '../models';
import { map } from 'rxjs/operators';
import { downloadFile } from 'ah-requests/helpers/download';

export class ComplianceService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'complianceService' } },
      },
    });
  }

  public uploadLater(clientId: string, options?: Partial<HttpOptions<void>>) {
    return this.put<void>(`${this.baseUrl}clients/${clientId}/documents/skip`, undefined, {
      options,
    });
  }

  public getClientComplianceStatus(clientId: string, options?: Partial<HttpOptions<ComplianceStatusObject>>) {
    return this.get<ComplianceStatusObject>(`${this.baseUrl}clients/${clientId}/status`, {
      options,
    });
  }

  public getClientComplianceCase(clientId: string, options?: Partial<HttpRequestOptions<PublicComplianceCase>>) {
    return this.get<PublicComplianceCase>(`${this.baseUrl}clients/cases/${clientId}`, options);
  }

  public updateClientComplianceCase(
    clientId: string,
    update: Partial<PublicComplianceCase>,
    options?: Partial<HttpOptions<VersionedObject>>
  ) {
    return this.put<VersionedObject>(`${this.baseUrl}clients/cases/${clientId}`, update, { options });
  }

  public submitClientQuestionnaire(clientId: string, options?: Partial<HttpOptions<void>>) {
    return this.post<void>(`${this.baseUrl}clients/cases/${clientId}/submit`, undefined, { options });
  }

  public listUbos(
    clientId: string,
    params?: PaginatedQuery,
    options?: Partial<HttpOptions<PaginatedResponse<BaseIndividual>>>
  ) {
    return this.get<PaginatedResponse<BaseIndividual>>(`${this.baseUrl}clients/cases/${clientId}/ubos`, {
      options: {
        ...options,
      },
      axiosConfig: { params },
    });
  }

  public createUboUser(clientId: string, ubo: BaseIndividual, options?: Partial<HttpOptions<BaseIndividual>>) {
    return this.post<BaseIndividual>(`${this.baseUrl}clients/cases/${clientId}/ubos`, ubo, { options });
  }

  public createAddressHistory(
    payload: EntityAddressHistory,
    options?: Partial<HttpOptions<EntityAddressHistoryResponse>>
  ) {
    return this.post<EntityAddressHistoryResponse>(`${this.baseUrl}address-history`, payload, { options });
  }

  public validateAddressHistory(
    payload: Partial<EntityAddressHistory>,
    options?: Partial<HttpOptions<EntityAddressHistoryResponse>>
  ) {
    return this.post<EntityAddressHistoryResponse>(`${this.baseUrl}address-history/validate`, payload, { options });
  }

  public listAddressHistoryItems(
    filters: PaginatedQuery<AddressHistoryListFilterParams>,
    options?: Partial<HttpRequestOptions<PaginatedResponse<AddressHistoryItem>>>
  ) {
    filters = standardiseQuerySort(filters);
    return this.get<PaginatedResponse<AddressHistoryItem>>(`${this.baseUrl}address-history`, {
      ...options,
      axiosConfig: {
        ...options?.axiosConfig,
        params: filters,
      },
    });
  }

  public updateUboUser(
    clientId: string,
    uboId: string,
    ubo: BaseIndividual,
    options?: Partial<HttpOptions<BaseIndividual>>
  ) {
    return this.put<BaseIndividual>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}`, ubo, { options });
  }

  public removeUboUser(clientId: string, uboId: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}`, { options });
  }

  public getUboDocuments(clientId: string, uboId: string, options?: Partial<HttpOptions<UploadedFile[]>>) {
    return this.get<UploadedFile[]>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents`, { options });
  }

  public removeUboDocument(clientId: string, uboId: string, fileId: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents/${fileId}`, { options });
  }

  public downloadUboSyncDocument(clientId: string, uboId: string, file: UploadedFile) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: file.link ?? `${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents/${file.id}`,
      },
    }).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  public submitUboDocument(clientId: string, uboId: string, category: FileCategory, file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        category,
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<VersionedObject>(
      {
        axiosConfig: {
          url: `${this.baseUrl}clients/cases/${clientId}/ubos/${uboId}/documents`,
          method: 'POST',
          data: formData,
        },
      },
      (c) => this.request(c)
    );
  }

  public submitBankingProvider(clientId: string, payload: PreferredBankingProvider, options?: HttpOptions<void>) {
    return this.post<void>(`${this.baseUrl}clients/cases/${clientId}/banking-provider`, payload, { options });
  }

  public moveToTermsAndConditions(clientId: string, options?: HttpOptions<void>) {
    return this.post<void>(`${this.baseUrl}clients/cases/${clientId}/terms-and-conditions`, undefined, { options });
  }
}
