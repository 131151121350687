<template>
  <div class="user-badge">
    {{ initials }}
    <AsyncImage class="user-badge-photo" v-if="imageSrc" :src="imageSrc" alt="Profile Picture" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import { IndividualFileCategories, IndividualReference } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';

@Component({
  components: {
    AsyncImage,
  },
})
export default class UserIcon extends Mixins(WithRequestManager) {
  imageSrc = '';

  @Prop({ required: true }) user!: IndividualReference;

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }
  get initials() {
    return ((this.user!.firstName || ' ')[0] + (this.user!.lastName || ' ')[0]).toUpperCase();
  }
  @Watch('photo.id', { immediate: true })
  onPhotoIdChange() {
    if (this.photo) {
      this.$services.individual
        .getDocumentAsBlob(this.authStore.loggedInIdentity!.id, this.photo!)
        .subscribe((blob) => {
          if (this.imageSrc) {
            URL.revokeObjectURL(this.imageSrc);
          }
          this.imageSrc = URL.createObjectURL(blob!);
        });
    }
  }
  beforeDestroy() {
    if (this.imageSrc) {
      URL.revokeObjectURL(this.imageSrc);
    }
  }

  created() {
    this.individualSettingsStore.loadIndividualDocuments(true);
  }

  get photo() {
    return this.individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE);
  }
}
</script>
