<template>
  <div id="app-holder" v-if="themeLoaded">
    <Transition name="fade" :appear="pageLoaded">
      <MaintenanceWarningScreen v-if="isInMaintenance || $theme.error" class="maintenance-screen-holder" />
      <RouterView v-else />
    </Transition>
    <VersionAlert v-if="showDevTools" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import VersionAlert from 'ah-common-lib/src/common/components/VersionAlert.vue';
import config from '@/config';
import MaintenanceWarningScreen from './app/components/common/MaintenanceWarningScreen.vue';
import { useSettingsStore } from './app/store/settingsModule';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { FeatureFlag, FeatureFlagTreatment, NotificationEventType, NotificationType } from 'ah-api-gateways';
import { useTabSync } from 'ah-common-lib/src/tabSync';
import { useFeatureFlagStore } from 'ah-common-stores';

const CHECK_THEME_LOAD_TIMEOUT = 3000;

/**
 * Main App component
 *
 * This component serves 2 functions:
 * - Displays the maintenance screen if either the app is in maintenance OR the app theme has failed to load
 * - Re-checks for theme until it loads properly
 */
@Component({
  components: {
    VersionAlert,
    MaintenanceWarningScreen,
  },
})
export default class Home extends Vue {
  // FIXME - this needs to be here to prevent a non breaking issue
  // where missing Injection errors are reported in InjectReactive, even if `default` key is provided
  @ProvideReactive('ahEmptyKey') private emptyKey = null;

  pageLoaded = false;

  private checkLoadThemeTimeout: number | null = null;

  private soundsEnabledFeatureFlag = false;

  get authStore() {
    return useAuthStore();
  }

  get settingsStore() {
    return useSettingsStore();
  }

  get tabSync() {
    return useTabSync();
  }

  sound = new Audio('https://files.alt21.com/cashregister.mp3');

  mounted() {
    window.setTimeout(() => {
      this.pageLoaded = true;
    });

    useNotificationsStore().notificationEvents.subscribe((event) => {
      if (
        event.eventType === NotificationEventType.NOTIFICATION_CREATED &&
        event.payload.type === NotificationType.TRADE_CONFIRMED &&
        this.authStore.isAgent &&
        this.soundsEnabledFeatureFlag
      ) {
        this.tabSync.isLeaderPromise.finally(() => {
          this.sound.volume = 0.1;
          this.sound.play();
        });
      }
    });
  }

  @Watch('authStore.isLoggedIn', { immediate: true })
  onLogin() {
    if (this.authStore.isLoggedIn && this.authStore.isAgent) {
      useFeatureFlagStore()
        .loadFeatureFlag({
          featureFlag: FeatureFlag.FRONTEND_NOTIFICATION_SOUNDS_ENABLED,
          force: false,
        })
        .then(
          (featureFlagResponse) =>
            (this.soundsEnabledFeatureFlag = featureFlagResponse?.treatment === FeatureFlagTreatment.ON)
        );
    }
  }

  @Watch('isInMaintenance', { immediate: true })
  checkLoadTheme() {
    if (this.checkLoadThemeTimeout) {
      clearTimeout(this.checkLoadThemeTimeout);
    }
    if (!this.$theme.loaded && !this.isInMaintenance) {
      this.$theme.load().subscribe({
        error: () => {
          this.checkLoadThemeTimeout = window.setTimeout(() => this.checkLoadTheme(), CHECK_THEME_LOAD_TIMEOUT);
        },
      });
    }
  }

  get showDevTools() {
    return !!config.showDevTools;
  }

  @Watch('settingsStore.darkMode', { immediate: true })
  onDarkModeChange() {
    if (this.settingsStore.darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }

  get themeLoaded() {
    return this.$theme.loaded || this.$theme.error;
  }

  get isInMaintenance() {
    return this.authStore.isInMaintenance;
  }
}
</script>

<style lang="scss">
#app-holder {
  height: 100%;
  @include themedBackgroundColor($color-main-bg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}

.maintenance-screen-holder {
  z-index: 1101; // Above toaster z-index
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
</style>
