<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inDashboard }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconTrade /> <span class="menu-link-text"> Hedging </span>
      <span>
        <span @click="toggleDashboard()"><IconChevronDown class="toggle-icon" :class="{ open: dashboardOpen }" /></span>
        <ExpandTransition appear>
          <TradesSidebarView v-if="dashboardOpen" path="/dashboard/trades" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" to="/dashboard/trades" v-else>
      <!-- Visible in tablet or higher resolutions -->
      <IconTrade /> <span class="menu-link-text"> Hedging </span>
    </RouterLink>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import TradesSidebarView from '@/app/views/dashboard/trades/TradesSidebarView.vue';

@Component({
  components: {
    TradesSidebarView,
  },
})
export default class SideNavTradeLink extends Vue {
  private dashboardOpen: boolean = false;

  get inDashboard() {
    return this.$route.fullPath.includes('/dashboard/trades');
  }

  @Watch('$route.fullPath', { immediate: true })
  onRouteChange() {
    this.dashboardOpen = this.inDashboard;
  }

  toggleDashboard() {
    this.dashboardOpen = !this.dashboardOpen;
  }
}
</script>

<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
