<template>
  <span>
    Notify
    <ValidatedForm :fm="notificationTimeFormModel" @form-event="onFormEvent">
      <template #notificationTime.timeValue:append>
        <BInputGroupText class="plus-minus">
          <div class="plus" @click="offsetTimeValue(1)">+</div>
          <div class="minus" @click="offsetTimeValue(-1)">-</div>
        </BInputGroupText>
      </template>
    </ValidatedForm>
    {{ config.multipleDescription }}.
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { NotificationSetting, NotificationTimeOffsetUnit, NotificationSettingConfig } from 'ah-api-gateways';
import { makeFormModel, updateModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { textField, selectField } from 'ah-common-lib/src/form/models';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';

const HOUR_MAX_VALUE = 72;
const DAYS_MAX_VALUE = 100;
const MONTHS_MAX_VALUE = 2;

const notificationTimeFormModel = () =>
  makeFormModel({
    name: 'notificationTime',
    fieldType: 'form',
    state: {
      formClass: 'notification-time-form',
      hideErrorMessages: true,
    },
    fields: [
      textField('timeValue', '', {
        fieldType: 'number',
        fieldWrapperClass: 'amount-field',
        min: 1,
        max: 100,
        step: 1,
      }),
      selectField(
        'timeUnit',
        '',
        [
          {
            label: 'hours',
            value: NotificationTimeOffsetUnit.HOURS,
          },
          {
            label: 'days',
            value: NotificationTimeOffsetUnit.DAYS,
          },
          {
            label: 'months',
            value: NotificationTimeOffsetUnit.MONTHS,
          },
        ],
        {
          fieldWrapperClass: 'amount-type-field',
        }
      ),
    ],
  });

@Component
export default class SingleNotificationTimeEditForm extends Vue {
  @Prop({ required: true }) config!: NotificationSettingConfig;

  @Prop({ required: true }) notification!: NotificationSetting;

  @Prop({ default: false }) readonly!: string | boolean;

  notificationTimeFormModel = notificationTimeFormModel();

  beforeMount() {
    this.onTimeUnitChange();
  }

  get maxTimeValue() {
    if (this.notificationTimeFormModel.timeUnit === NotificationTimeOffsetUnit.HOURS) {
      return HOUR_MAX_VALUE;
    } else if (this.notificationTimeFormModel.timeUnit === NotificationTimeOffsetUnit.DAYS) {
      return DAYS_MAX_VALUE;
    } else return MONTHS_MAX_VALUE;
  }

  @Watch('readonly', { immediate: true })
  onReadonlyChange() {
    setState(this.notificationTimeFormModel, 'readonly', this.readonly !== false);
  }

  @Watch('notification', { immediate: true })
  onNotificationChange() {
    updateModel(this.notificationTimeFormModel, this.notification);
  }

  onTimeUnitChange() {
    setState(getChildModel(this.notificationTimeFormModel, 'timeValue')!, 'max', this.maxTimeValue);
    this.notificationTimeFormModel.timeValue = Math.min(this.notificationTimeFormModel.timeValue, this.maxTimeValue);
  }

  offsetTimeValue(amount: number) {
    this.notificationTimeFormModel.timeValue = Math.max(
      1,
      Math.min(this.maxTimeValue, this.notificationTimeFormModel.timeValue + amount)
    );
    this.updateNotification();
  }

  onFormEvent(event: FormEvent) {
    if (event.event === 'form-field-set-value') {
      if (event.model.$name === 'timeUnit') {
        this.onTimeUnitChange();
      }
      this.updateNotification();
    }
  }

  updateNotification() {
    this.$emit('update:notification', {
      ...this.notification,
      timeValue: this.notificationTimeFormModel.timeValue,
      timeUnit: this.notificationTimeFormModel.timeUnit,
    });
  }
}
</script>

<style lang="scss">
// Unscoped styles to allow @themedProp to work correctly

.notification-time-form {
  display: inline-flex;

  .field-group-wrapper {
    margin-bottom: 0;
  }

  .amount-field,
  .amount-type-field {
    display: inline-block;
    margin-bottom: 0.5em;
  }

  .amount-field {
    width: 5em;
    margin-right: 0.5em;

    input.form-control {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }

    :focus-within {
      .plus {
        top: -1px;
        right: -1px;
      }

      .minus {
        bottom: -1px;
        right: -1px;
      }
    }
  }

  .plus-minus {
    position: relative;
    width: 1.3em;

    .plus,
    .minus {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      text-align: center;
      border-left-style: solid;
      border-left-width: 1px;
      @include themedBorderColor($color-input-border);

      &:hover {
        cursor: pointer;
      }
    }

    .plus {
      bottom: 50%;
      border-bottom-style: solid;
      border-bottom-width: 1px;
      @include themedBorderColor($color-input-border);
    }

    .minus {
      top: 50%;
    }
  }
  .amount-type-field {
    width: 6em;
  }
}
</style>
