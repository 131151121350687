<script setup lang="ts">
import { forkJoin, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { FieldOptionObj } from 'ah-common-lib/src/form/interfaces';
import TagMultiSelect from './TagMultiSelect.vue';
import { PaginatedParams, PaginatedQuery, PaginatedResponse } from 'ah-api-gateways';
import { debounce, isEqual, uniqBy } from 'lodash';
import Vue, { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';

/**
 * Searchable Multi Select Component
 *
 * Allows the user to search for a specific entity in a selector
 */

const props = defineProps({
  /**
   * Paginated fetch request
   *
   * Used to fetch the data to populate the select options
   */
  fetchRequest: {
    type: Function as PropType<(query: any) => Observable<PaginatedResponse<any>>>,
    default: () => () => null,
  },
  /**
   * Single entity fetch request
   *
   * Used to fetch the initial unkown data
   */
  singleFetchRequest: {
    type: Function as PropType<(query: any) => Observable<any>>,
    default: () => () => null,
  },
  /**
   * Selected values.
   *
   * Syncable with v-model
   */
  value: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  /**
   * List request pagesize
   *
   * Defaults to 20
   */
  pageSize: {
    type: Number,
    default: 20,
  },
  /**
   * Search by key in pagination request
   *
   * Defaults to 'query'
   */
  searchBy: {
    type: String,
    default: 'query',
  },
  /**
   * Entity value key
   *
   * Defaults to 'id'
   */
  valueKey: {
    type: String,
    default: 'id',
  },

  /**
   * Entity label key
   *
   * Defaults to 'name'
   */
  valueLabel: {
    type: [String, Function] as PropType<string | ((item: AnalyserNode) => string)>,
    default: 'name',
  },

  /**
   * Entity sort key
   */
  sortKey: {
    type: [String, Array] as PropType<string | string[]>,
  },
});

const emit = defineEmits({
  'update:selectedOptions': (_savedLists: FieldOptionObj<any>[]) => true,
});

const requestManager = useRequestManager().manager;

const options = ref<FieldOptionObj<any>[]>([]);

const debouncedSearch = debounce(() => fetchDataRequest().subscribe(), 600);

const searchTerm = ref('');

const lastResult = ref<PaginatedResponse<any> | null>(null);

const hasMoreItems = computed(() => {
  if (
    typeof lastResult.value?.pageNumber !== 'number' ||
    typeof lastResult.value?.pageSize !== 'number' ||
    typeof lastResult.value?.total !== 'number'
  ) {
    return false;
  }
  return (lastResult.value.pageNumber + 1) * lastResult.value.pageSize < lastResult.value.total;
});

const loading = computed(() => requestManager.requestStates['fetchData'] === 'pending');

const unkownValues = computed(() => props.value.filter((v) => !options.value.find((opt) => isEqual(opt.value, v))));

const selectedOptions = computed(() =>
  options.value.filter((opt) => props.value.find((val) => isEqual(val, opt.value)))
);

function onTagSearch(search: string) {
  searchTerm.value = search;
  debouncedSearch();
}

function getLabelString(item: any) {
  if (typeof props.valueLabel === 'string') {
    return item[props.valueLabel];
  }
  return props.valueLabel(item);
}

function onFetchRequestChange() {
  fetchDataRequest().subscribe();
}

watch(() => props.fetchRequest, onFetchRequestChange, { immediate: true });

function onUnkownValuesChange() {
  if (unkownValues.value.length > 0) {
    fetchDataRequest().subscribe();
  }
}

watch(unkownValues, onUnkownValuesChange);

function onSelectedOptionsChange() {
  emit('update:selectedOptions', selectedOptions.value);
}

watch(selectedOptions, onSelectedOptionsChange, { immediate: true });

const sortParams = computed<Partial<PaginatedParams>>(() => {
  if (props.sortKey) {
    return {
      sort: props.sortKey,
      sortDirection: 'ASC',
    };
  }
  if (typeof props.valueLabel === 'string') {
    return {
      sort: props.valueLabel,
      sortDirection: 'ASC',
    };
  }
  return {};
});

function onInfiniteScroll(intersection: IntersectionObserverEntry) {
  if (intersection.isIntersecting) {
    const offsetParent = (intersection.target as HTMLElement).offsetParent;
    const scrollTop = offsetParent?.scrollTop;
    if (!requestManager.anyPending) {
      fetchDataRequest(true).subscribe(() => {
        if (offsetParent && scrollTop) {
          Vue.nextTick().then(() => {
            offsetParent.scrollTop = scrollTop;
          });
        }
      });
    }
  }
}

/**
 *
 * Fetch new data and missing data
 *
 */
function fetchDataRequest(infinite = false) {
  const query: PaginatedQuery<any> = {
    pageSize: props.pageSize,
    [props.searchBy]: searchTerm.value,
    ...sortParams.value,
  };

  const infiniteLoad = infinite && typeof lastResult.value?.pageNumber === 'number';

  if (infiniteLoad) {
    query.pageNumber = lastResult.value?.pageNumber! + 1;
  } else {
    query.pageNumber = 0;
    lastResult.value = null;
  }
  const requests: Observable<any[]>[] = [];

  // Add single request to any unkown values on first page query
  if (query.pageNumber === 0 && unkownValues.value.length > 0) {
    unkownValues.value.forEach((val) => requests.push(props.singleFetchRequest(val).pipe(map((r) => [r]))));
  }

  requests.push(
    props.fetchRequest(query).pipe(
      map((res) => {
        lastResult.value = res;
        return res.list;
      })
    )
  );

  return requestManager.sameOrCancelAndNew('fetchData', forkJoin(requests)).pipe(
    tap((res) => {
      let list: FieldOptionObj<any>[] = infiniteLoad ? [...options.value] : [];

      res.forEach((r) => {
        list.push(...r.map((el) => ({ label: getLabelString(el), value: (el as any)[props.valueKey] })));
      });

      options.value = uniqBy(list, 'value');
    })
  );
}
</script>

<template>
  <TagMultiSelect
    :loading="loading && !lastResult"
    :options="options"
    :filterable="false"
    @search="onTagSearch"
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #list-footer>
      <div v-on-intersect="onInfiniteScroll" v-if="hasMoreItems">
        <slot name="infinite-scroll">
          <li class="vs__dropdown-option loader text-center">
            <LoadingIcon class="loading-icon" />
          </li>
        </slot>
      </div>
    </template>
  </TagMultiSelect>
</template>
