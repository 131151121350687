<script setup lang="ts">
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = withDefaults(
  defineProps<{
    currency?: string;
  }>(),
  {
    currency: '',
  }
);

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const wallet = computed(() => {
  return tradeState.store
    .useWalletsStore()
    .getWallet(
      props.currency,
      onBehalfOfClient.value ? { isPartner: false, id: onBehalfOfClient.value.id } : undefined
    );
});

const collateralBalance = computed(() => wallet.value?.collateralBalance ?? 0);

watch(
  () => props.currency,
  () => {
    tradeState.store.useWalletsStore().loadCurrencyWallet({
      currency: props.currency,
      force: true,
      owner: onBehalfOfClient.value ? { isPartner: false, id: onBehalfOfClient.value.id } : undefined,
    });
  },
  { immediate: true }
);
</script>

<template>
  <span class="wallet-collateral"> {{ currency }} {{ formatCurrencyValue(collateralBalance) }} </span>
</template>
