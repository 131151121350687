<template>
  <VRow class="align-items-lg-start align-items-md-center">
    <VCol class="description-col">
      <h3 class="notifications-block-title mb-0">{{ group.name }}</h3>
    </VCol>
    <VCol class="notify-col text-md-right text-sm-center set-all-text"> Set all </VCol>
    <VCol class="switches-col mr-lg-0 mr-md-4 mr-sm-0">
      <VRow>
        <VCol class="switch-col email-col">
          <h4 v-if="titlesShown" class="col-inner-title">Email notification</h4>
          <IconLock v-if="allChildrenMandatory(NotificationChannelType.EMAIL)" class="lock" />
          <BFormCheckbox
            :checked="isTargettingEmail"
            :disabled="allChildrenMandatory(NotificationChannelType.EMAIL)"
            :class="calcStyles(NotificationChannelType.EMAIL)"
            name="check-button"
            switch
            @change="onTargetChange(NotificationChannelType.EMAIL, $event)"
          />
        </VCol>
        <VCol class="switch-col">
          <h4 v-if="titlesShown" class="col-inner-title">In-app notification</h4>
          <IconLock v-if="allChildrenMandatory(NotificationChannelType.IN_APP)" class="lock" />
          <BFormCheckbox
            :checked="isTargettingInApp"
            :disabled="allChildrenMandatory(NotificationChannelType.IN_APP)"
            :class="calcStyles(NotificationChannelType.IN_APP)"
            name="check-button"
            switch
            @change="onTargetChange(NotificationChannelType.IN_APP, $event)"
          />
        </VCol>
        <!-- <VCol class="switch-col sms-col">
      <h4 v-if="titlesShown" class="col-inner-title">SMS notification</h4>
          <IconLock v-if="allChildrenMandatory(NotificationChannelType.SMS)" class="lock" />
      <BFormCheckbox
        :checked="isTargettingSms"
        :disabled="allChildrenMandatory(NotificationChannelType.SMS)"
        :class="calcStyles(NotificationChannelType.SMS)"
        name="check-button"
        switch
        @change="onTargetChange(NotificationChannelType.SMS, $event)"
      />
    </VCol> -->
      </VRow>
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { NotificationChannel, NotificationChannelType, NotificationSettingConfigGroup } from 'ah-api-gateways';

@Component
export default class NotificationGroupHeaderEditForm extends Vue {
  @Prop({ required: true }) group!: NotificationSettingConfigGroup;

  @Prop({ default: false }) showTitles!: boolean | string;

  @Prop({ default: false }) readonly!: string | boolean;

  @Prop({ default: false }) channels!: NotificationChannel[];

  get isTargettingEmail() {
    const channel = this.channels.find((n) => n.type === NotificationChannelType.EMAIL);
    return channel?.enabled;
  }

  get isTargettingInApp() {
    const channel = this.channels.find((n) => n.type === NotificationChannelType.IN_APP);
    return channel?.enabled;
  }

  get isTargettingSms() {
    const channel = this.channels.find((n) => n.type === NotificationChannelType.SMS);
    return channel?.enabled;
  }

  get NotificationChannelType() {
    return NotificationChannelType;
  }

  get titlesShown() {
    return this.showTitles !== false;
  }

  get allChildrenMandatory() {
    return (type: NotificationChannelType) => {
      let out = true;
      this.group.notifications.forEach((setting) => {
        if (setting.channels.find((channel) => channel.channelType === type)!.configurable) out = false;
      });
      return out;
    };
  }

  get calcStyles() {
    return (type: NotificationChannelType) => {
      let out: string[] = [];
      if (this.allChildrenMandatory(type)) {
        out.push('disabled');
        const channel = this.channels.find((n) => n.type === type);
        if (channel?.enabled) {
          out.push('disabled-on');
        } else if (channel) {
          out.push('disabled-off');
        }
      }
      return out;
    };
  }

  onTargetChange(type: NotificationChannelType, enabled: boolean) {
    this.$emit('update:change', { type: type, enabled: enabled });
  }
}
</script>
