<script lang="ts" setup>
import { AuthorityType, ClientPositionUsageReport, GenericErrorCodes } from 'ah-api-gateways';
import { BModal } from 'bootstrap-vue';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpError } from 'ah-requests';
import { DEFAULT_INACTIVITY_TIMEOUT } from 'ah-common-lib/src/constants/time';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, onBeforeUnmount, onBeforeMount, reactive, ref, onMounted } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useAhTradesState } from '../..';

import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const props = withDefaults(
  defineProps<{
    forwardTitle?: string;
    inUseHeader?: string;
    instrumentHeader?: string;
    limitHeader?: string;
    remainingHeader?: string;
    spotTitle?: string;
  }>(),
  {
    forwardTitle: 'Forward & Options',
    inUseHeader: 'Current Usage',
    instrumentHeader: 'Instrument',
    limitHeader: 'Max. Open Positions Notional Value',
    remainingHeader: 'Remaining Trade Limit',
    spotTitle: 'Spot',
  }
);

const confirmNotifyUsModal = ref<InstanceType<typeof BModal> | null>(null);

const thankyouNotifyUsModal = ref<InstanceType<typeof BModal> | null>(null);

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const state = reactive<{
  report: ClientPositionUsageReport | null;
  refreshComponentTimeout: number | null;
}>({
  report: null,
  refreshComponentTimeout: null,
});

const requestManager = useRequestManager({
  exposeToParent: ['loadReport'],
  onRetryFromParentManager: (k: string) => {
    if (k === 'loadReport') {
      loadReport();
    }
  },
});

function loadReport() {
  requestManager.manager
    .currentOrNew(
      'loadReport',
      tradeState.services.risk
        .getClientPositionLimitsUsage(
          onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity!.client!.id,
          {
            errors: { silent: true },
          }
        )
        .pipe(
          catchError((e: HttpError) => {
            try {
              if (e.response?.data.code === GenericErrorCodes.RESOURCE_NOT_FOUND) {
                return of(null);
              }
            } catch (e) {}
            throw e;
          })
        )
    )
    .subscribe((report) => {
      state.report = report;
    });
}

function refreshComponent() {
  state.refreshComponentTimeout = window.setInterval(() => {
    loadReport();
  }, DEFAULT_INACTIVITY_TIMEOUT);
}

onMounted(() => {
  refreshComponent();
});

const canNotifyUs = computed(() =>
  tradeState.store.useAuthStore().hasAuthorities(AuthorityType.NOTIFY_LIMITS_TRADING_DESK)
);

const reportAmounts = computed(() => {
  if (!state.report) return [];
  return [
    {
      instrument: props.spotTitle,
      notionalLimit: state.report.notionalLimitSpots,
      notionalLimitUsage: state.report.notionalLimitSpotsInUse,
      notionalLimitRemaining: Math.max(0, state.report.notionalLimitSpots - state.report.notionalLimitSpotsInUse),
    },
    {
      instrument: props.forwardTitle,
      notionalLimit: state.report.notionalLimitForwardsOptions,
      notionalLimitUsage: state.report.notionalLimitForwardsOptionsInUse,
      notionalLimitRemaining: Math.max(
        0,
        state.report.notionalLimitForwardsOptions - state.report.notionalLimitForwardsOptionsInUse
      ),
    },
  ];
});

onBeforeMount(() => {
  loadReport();
});

function onConfirmNotify() {
  requestManager.manager.currentOrNew('requestNotify', tradeState.services.limits.notifyUsLimit()).subscribe(() => {
    thankyouNotifyUsModal.value?.show();
  });
}

function confirmNotifyUs() {
  confirmNotifyUsModal.value?.show();
}

onBeforeUnmount(() => {
  if (state.refreshComponentTimeout !== null) clearInterval(state.refreshComponentTimeout);
});
</script>

<template>
  <div class="position-limits">
    <template v-if="state.report">
      <VRow class="position-limits-headers row d-sm-none d-md-flex">
        <VCol cols="12" md="2"> {{ instrumentHeader }} </VCol>
        <VCol cols="12" md="4">
          <InfoTooltip :text="$ahTradesState.i18n.t('tooltips.maximumNotionalValue')" />
          {{ limitHeader }}
        </VCol>
        <VCol cols="12" md="3">{{ inUseHeader }}</VCol>
        <VCol cols="12" md="3">{{ remainingHeader }}</VCol>
      </VRow>
      <VRow
        :class="['position-limits-row row', `${amount.instrument.toLocaleLowerCase()}-row`]"
        v-for="amount in reportAmounts"
        :key="amount.instrument"
      >
        <VCol cols="12" md="2" class="instrument-title">
          <div class="row-header">{{ instrumentHeader }}</div>
          <div class="value">{{ amount.instrument }} <span class="d-sm-none">:</span></div>
        </VCol>
        <VCol cols="12" md="4" class="value-col">
          <div class="row-header">{{ limitHeader }}</div>
          <div class="value">{{ state.report.currency }} {{ formatCurrencyValue(amount.notionalLimit) }}</div>
        </VCol>
        <VCol cols="12" md="3" class="value-col">
          <div class="row-header">{{ inUseHeader }}</div>
          <div class="value">{{ state.report.currency }} {{ formatCurrencyValue(amount.notionalLimitUsage) }}</div>
        </VCol>
        <VCol cols="12" md="3" class="value-col">
          <div class="row-header">{{ remainingHeader }}</div>
          <div class="value">{{ state.report.currency }} {{ formatCurrencyValue(amount.notionalLimitRemaining) }}</div>
        </VCol>
      </VRow>
      <template v-if="!onBehalfOfClient">
        <VRow class="mt-4 ml-1">
          <p>If you would like to increase your limit, please notify us and we'll be in touch to discuss</p>
        </VRow>
        <VRow class="ml-1">
          <VButton
            v-if="canNotifyUs"
            @click="confirmNotifyUs()"
            :loading="requestManager.manager.requestStates.requestNotify === 'pending'"
          >
            Notify Us
          </VButton>
          <BModal ref="confirmNotifyUsModal" :centered="true" ok-title="Yes" cancel-title="No" @ok="onConfirmNotify">
            <div class="text-center">
              <p>Are you sure you wish to request an increase to your open position limits?</p>
            </div>
          </BModal>
          <BModal ref="thankyouNotifyUsModal" :centered="true" hide-footer>
            <div class="text-center">
              <p>
                Thank you. A member of our trading team will be in touch shortly to discuss your request and
                requirements.
              </p>
            </div>
          </BModal>
        </VRow>
      </template>
    </template>
    <div v-else>No limits found for your client.</div>
  </div>
</template>

<style lang="scss" scoped>
.position-limits {
  .position-limits-headers {
    margin-bottom: 1.5em;
  }

  .position-limits-row {
    margin-bottom: 1em;

    @include upToResolution($tabletResolution) {
      font-size: 15px;
      &:not(:last-child) {
        @include themedPropColor('border-bottom', $color-dark-primary, $color-dark-primary, '1px solid ');
      }
      .instrument-title,
      .value-col {
        font-weight: 400;
        display: inline-flex;
        margin-bottom: 10px;
        div {
          width: 50%;
        }
        .row-header {
          margin-right: 0.5em;
        }
        .value {
          text-align: end;
        }
      }
      .instrument-title {
        font-weight: 600;
      }
    }

    .row-header {
      display: none;
      @include upToResolution($tabletResolution) {
        display: block;
      }
    }
  }
}
</style>
