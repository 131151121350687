<template>
  <BoxGrid alignH="start">
    <BoxGridItem sm="12" lg="12" class="field-group-wrapper"><h2>Filter Payments</h2> </BoxGridItem>
    <BoxGridItem sm="12" lg="5" class="field-group-wrapper">
      <SearchInput
        class="search-input mb-4 mb-xl-0 mock-label-padding"
        placeholder="Search by Payment ID or Trade ID"
        :search.sync="search"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="2" xl="2" class="field-group-wrapper state">
      <label>
        Type
        <a v-if="type.length > 0" class="field-group-clear-link" @click="type = []"> clear </a>
      </label>
      <TagMultiSelect :maxFulltextLabels="4" :options="typeOptions" :value.sync="type" itemsCountLabel="types" />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="2" xl="2" class="field-group-wrapper state">
      <label>
        State
        <a v-if="states.length > 0" class="field-group-clear-link" @click="states = []"> clear </a>
      </label>
      <TagMultiSelect :maxFulltextLabels="4" :options="stateOptions" :value.sync="states" itemsCountLabel="states" />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="3" class="date-selector">
      <InputDateSelector
        title="Creation Date Range"
        :dateSelected.sync="creationDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="5" class="field-group-wrapper state">
      <div>
        <label>
          Beneficiary
          <a v-if="beneficiaries.length > 0" class="field-group-clear-link" @click="beneficiaries = []"> clear </a>
        </label>
        <SearchableSelector
          :fetchRequest="fetchRequestBeneficiary"
          :singleFetchRequest="singleFetchRequestBeneficiary"
          :value.sync="beneficiaries"
          itemsCountLabel="beneficiaries"
          appendLabelToPlaceholder="Beneficiaries"
          :maxFulltextLabels="3"
          :valueLabel="beneficiaryOptionLabel"
          :sortKey="['name', 'firstName', 'lastName']"
        />
      </div>
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="2" class="field-group-wrapper state">
      <label>
        Method
        <a v-if="method.length > 0" class="field-group-clear-link" @click="method = []"> clear </a>
      </label>
      <TagMultiSelect
        :maxFulltextLabels="4"
        :options="paymentRailOptions"
        :value.sync="method"
        itemsCountLabel="method"
      />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="2" class="field-group-wrapper state">
      <label>
        Currency
        <a v-if="currency.length > 0" class="field-group-clear-link" @click="currency = []"> clear </a>
      </label>
      <TagMultiSelect :maxFulltextLabels="4" :options="currencies" :value.sync="currency" itemsCountLabel="currency" />
    </BoxGridItem>
    <BoxGridItem sm="12" lg="3" xl="3" class="date-selector">
      <InputDateSelector
        title="Payment Date Range"
        :dateSelected.sync="executionDate"
        hide-choices
        clearable
        is-ranged
      />
    </BoxGridItem>
  </BoxGrid>
</template>

<script lang="ts">
import { Component, Mixins, Watch } from 'vue-property-decorator';
import {
  PaymentState,
  paymentStateLabels,
  PaymentType,
  paymentTypeLabels,
  PaymentRail,
  paymentRailLabels,
  Beneficiary,
  BeneficiaryCategory,
} from 'ah-api-gateways';
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import InputDateSelector, { DateRange } from 'ah-common-lib/src/common/components/InputDateSelector.vue';
import ListFilters from 'ah-common-lib/src/common/components/listing/ListFilters.vue';
import { addDays, startOfDay, endOfDay, format } from 'date-fns';
import { useSettingsStore } from '@/app/store/settingsModule';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import SearchableSelector from 'ah-common-lib/src/common/components/selects/SearchableSelector.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({ components: { InputDateSelector, SearchableSelector } })
export default class PaymentsListFilters extends Mixins(ListFilters, WithRequestManager) {
  selectedDate: DateRange | null = null;

  get settingsStore() {
    return useSettingsStore();
  }

  private get currencies() {
    return this.settingsStore.currencies.map((i) => ({ label: i.currency, value: i.currency }));
  }

  private get stateOptions() {
    return Object.keys(paymentStateLabels).map((k) => {
      return {
        value: k,
        label: paymentStateLabels[k as PaymentState],
      };
    });
  }
  private get typeOptions() {
    return Object.keys(paymentTypeLabels).map((k) => {
      return {
        value: k,
        label: paymentTypeLabels[k as PaymentType],
      };
    });
  }
  private get paymentRailOptions() {
    return this.paymentRails.map((k) => {
      return {
        value: k,
        label: paymentRailLabels[k as PaymentRail] ?? k,
      };
    });
  }

  private search = '';
  private currency: string[] = [];
  private states: PaymentState[] = [];
  private creationDate: DateRange | null = null;
  private executionDate: DateRange | null = null;
  private beneficiaries: string[] = [];
  private type: PaymentType[] = [];
  private method: PaymentRail[] = [];
  private paymentRails = Object.keys(paymentRailLabels);

  private fetchRequestBeneficiary(query: any) {
    return this.$services.beneficiary.listBeneficiaries({
      ...query,
      partnerId: this.partner?.id,
      clientId: this.client?.id,
      category: this.client ? BeneficiaryCategory.CLIENT_3RD_PARTY : BeneficiaryCategory.PARTNER_3RD_PARTY,
      withIndividuals: true,
    });
  }

  private singleFetchRequestBeneficiary(beneficiaryId: string) {
    return this.$services.beneficiary.getBeneficiary(beneficiaryId);
  }

  private beneficiaryOptionLabel(b: Beneficiary) {
    return b.name || `${b.firstName} ${b.lastName}`;
  }

  get authStore() {
    return useAuthStore();
  }

  get user() {
    return this.authStore.loggedInIdentity;
  }

  get partner() {
    return this.user?.partner;
  }

  get client() {
    return this.user?.client;
  }

  get knownFilters() {
    return [
      {
        key: 'excludeClients',
        getter: () => (this.partner?.id && this.client?.id ? false : true),
        setter: () => {},
      },
      {
        key: 'excludePartners',
        getter: () => (this.client?.id ? true : false),
        setter: () => {},
      },
      {
        key: 'partnerId',
        getter: () => this.partner?.id,
        setter: () => {},
      },
      {
        key: 'query',
        getter: () => (this.search.length && this.search) || undefined,
        setter: (value: any) => (this.search = value || ''),
      },
      {
        key: 'queryBy',
        getter: () => {
          if (this.search.length && this.search) {
            return ['composedReferenceNumber', 'tradeComposedReferenceNumber'];
          }
        },
        setter: () => {},
      },
      {
        key: 'state',
        getter: () => this.states || undefined,
        setter: (value: any) => (this.states = value),
      },
      {
        key: 'currency',
        getter: () => this.currency || undefined,
        setter: (value: any) => (this.currency = value),
      },
      {
        key: 'beneficiaryId',
        getter: () => this.beneficiaries || undefined,
        setter: (value: any) => (this.beneficiaries = value),
      },
      {
        key: 'type',
        getter: () => this.type || undefined,
        setter: (value: any) => (this.type = value),
      },
      {
        key: 'beneficiaryPaymentRail',
        getter: () => this.method || undefined,
        setter: (value: any) => (this.method = value),
      },
      {
        key: 'createdAtFrom',
        getter: () => {
          if (this.creationDate?.start && !isNaN(new Date(this.creationDate.start).valueOf())) {
            return startOfDay(new Date(this.creationDate.start)).toISOString();
          }
        },
        setter: (value: any) => {
          if (value) {
            if (this.creationDate) {
              this.creationDate.start = new Date(value);
            } else {
              this.creationDate = { start: startOfDay(new Date(value)), end: endOfDay(addDays(new Date(value), 1)) };
            }
          }
        },
      },
      {
        key: 'createdAtTo',
        getter: () => {
          if (this.creationDate?.end && !isNaN(new Date(this.creationDate.end).valueOf())) {
            return endOfDay(new Date(this.creationDate.end)).toISOString();
          }
        },
        setter: (value: any) => {
          if (value) {
            if (this.creationDate) {
              this.creationDate.end = new Date(value);
            } else {
              this.creationDate = { start: startOfDay(new Date(value)), end: endOfDay(addDays(new Date(value), 1)) };
            }
          }
        },
      },
      {
        key: 'executionDateFrom',
        getter: () => {
          if (this.executionDate?.start && !isNaN(new Date(this.executionDate.start).valueOf())) {
            return format(new Date(this.executionDate.start), 'yyyy-MM-dd');
          }
        },
        setter: (value: any) => {
          if (value) {
            if (this.executionDate) {
              this.executionDate.start = new Date(value);
            } else {
              this.executionDate = { start: startOfDay(new Date(value)), end: endOfDay(addDays(new Date(value), 1)) };
            }
          }
        },
      },
      {
        key: 'executionDateTo',
        getter: () => {
          if (this.executionDate?.end && !isNaN(new Date(this.executionDate.end).valueOf())) {
            return format(new Date(this.executionDate.end), 'yyyy-MM-dd');
          }
        },
        setter: (value: any) => {
          if (value) {
            if (this.executionDate) {
              this.executionDate.end = new Date(value);
            } else {
              this.executionDate = { start: startOfDay(new Date(value)), end: endOfDay(addDays(new Date(value), 1)) };
            }
          }
        },
      },
    ];
  }

  created() {
    this.settingsStore.loadTradeableCurrencies();
  }

  @Watch('client', { immediate: true })
  @Watch('partner')
  onClientChange() {
    const filter = {
      clientId: this.client?.id,
      partnerId: this.partner?.id,
      excludeClients: !this.client,
      excludePartners: !!this.client,
    };
    this.requestManager
      .sameOrCancelAndNew(
        'loadRails',
        this.$services.payments.loadPaymentsDistinctRails(filter, {
          errors: {
            silent: true,
          },
        }),
        filter
      )
      .subscribe((response) => {
        const rails = response.paymentRails;
        this.method.forEach((m) => {
          if (!rails.includes(m)) {
            rails.push(m);
          }
        });
        this.paymentRails = rails;
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  @media only screen and (max-width: 1600px) {
    .state {
      margin-left: 0 !important;
    }
    .date-selector {
      flex-grow: 1;
      max-width: 100%;
    }
  }
  .date-inputs-wrapper {
    .field-group-field-input {
      margin-right: 0.3rem !important;
    }
  }
}
</style>
