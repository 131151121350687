<script lang="ts" setup>
import CollateralTransactionCurrencyForm from './CollateralTransactionCurrencyForm.vue';
import { Observable, from, combineLatest, timer } from 'rxjs';
import { Wallet, SpotLiveRate, ClientCollaterals } from 'ah-api-gateways';
import { flatMap, take } from 'rxjs/operators';
import { resetForm } from 'ah-common-lib/src/form/helpers';
import { BModal } from 'bootstrap-vue';
import { CollateralActionEvent } from './CollateralActionEvent';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { computed, reactive, ref, watch } from 'vue';
import { RequestState } from 'ah-requests';
import { useAhTradesState } from '../..';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const completeActionModal = ref<InstanceType<typeof BModal> | null>(null);

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const emit = defineEmits<{
  /*
   * - transaction-executed (payload: CollateralActionEvent) - emitted when the transaction has been executed successfully
   */
  (e: 'transaction-executed', payload: CollateralActionEvent): void;
  /*
   * - update:txOperationState (payload: requestState) - emmmited when transaction request states update. Can be used with v-model (prop is ignored)
   */
  (e: 'update:txOperationState', payload: RequestState): void;
}>();

const props = defineProps<{
  mode: 'withdraw' | 'post';
  btnTitle?: string;
}>();

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (key: string) => {
    if (key.startsWith('loadWallet-')) {
      refreshWalletData();
    } else if (key === 'performTransaction') {
      performTransaction();
    } else if (key === 'loadLiveRates') {
      loadLiveRates();
    }
  },
});

const state = reactive<{
  currencies: {
    currency: string;
    model?: { amount: number };
    validation?: FormValidation;
  }[];
  marketRates: SpotLiveRate[] | null;
}>({
  currencies: [
    { currency: 'GBP', model: null as any, validation: null as any },
    { currency: 'EUR', model: null as any, validation: null as any },
    { currency: 'USD', model: null as any, validation: null as any },
  ],
  marketRates: null,
});

tradeState.store.useWalletsStore().loadCollaterals({ force: false, clientId: onBehalfOfClient.value?.id });

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const isPartnerUser = computed(() => !onBehalfOfClient.value && !tradeState.store.useAuthStore().isClientUser);

const ownerId = computed(() => {
  if (onBehalfOfClient.value) {
    return onBehalfOfClient.value.id;
  }
  return (
    tradeState.store.useAuthStore().loggedInIdentity?.client?.id ||
    tradeState.store.useAuthStore().loggedInIdentity?.partner?.id
  );
});

const isOverWithdrawing = computed(() => {
  if (props.mode === 'withdraw') {
    return totalEquivalent.value > (collaterals.value?.unutilizedCollateral ?? 0);
  }

  return false;
});

const isFormInvalid = computed(() => {
  if (totalEquivalent.value === 0) {
    return true;
  }

  for (let i = 0; i < state.currencies.length; i++) {
    const currency = state.currencies[i];
    if (currency.validation?.$invalid) {
      return true;
    }
    if (
      currency.model &&
      currency.model?.amount > 0 &&
      !tradeState.store.useWalletsStore().getWallet(currency.currency, {
        isPartner: isPartnerUser.value,
        id: ownerId.value,
      })
    ) {
      return true;
    }
  }

  return isOverWithdrawing.value;
});

const totalEquivalent = computed(() => {
  let total = 0;

  state.currencies.forEach((c) => {
    if (props.mode === 'post') {
      const rate = state.marketRates && state.marketRates.find((rate) => rate.currencyPair.startsWith(c.currency));
      total += (c.model?.amount ?? 0) * (rate?.spotRate ?? 1);
    } else {
      total +=
        (c.model?.amount ?? 0) *
        ((collaterals.value && (collaterals.value as any)[`${c.currency.toLowerCase()}ConversionRate`]) ?? 1);
    }
  });

  // Round to 2 decimal places
  if (total) {
    total = Math.round(total * 100) / 100;
  }
  return total;
});

function refreshWalletData() {
  state.currencies.forEach((currency) => {
    requestManager.manager.currentOrNewPromise(`loadWallet-${currency.currency}`, () =>
      tradeState.store.useWalletsStore().loadCurrencyWallet({
        currency: currency.currency,
        force: true,
        owner: { isPartner: isPartnerUser.value, id: ownerId.value },
      })
    );
  });
}

function openModal() {
  completeActionModal.value?.show();
}

function onTxStateChange() {
  emit('update:txOperationState', requestManager.manager.requestStates.performTransaction);
}

watch(
  () => requestManager.manager.requestStates.performTransaction,
  () => {
    onTxStateChange();
  },
  { immediate: true }
);

function loadLiveRates() {
  const currencyPairs = state.currencies
    .filter((curr) => curr.currency !== collaterals.value!.currency)
    .map((curr) => `${curr.currency}${collaterals.value!.currency}`);

  requestManager.manager
    .sameOrCancelAndNew(
      'loadLiveRates',
      combineLatest(currencyPairs.map((pair) => tradeState.services.rates.spotLiveRate(pair)))
    )
    .subscribe((rates) => {
      state.marketRates = rates;
    });
}

watch(
  collaterals,
  (newVal?: ClientCollaterals | null, oldVal?: ClientCollaterals | null) => {
    /**
     * Rates are loaded on page load (when oldVal is non-existent) and whenever collaterals are updated (comparing updatedAt values)
     */
    if (newVal && (!oldVal || newVal.updatedAt !== oldVal?.updatedAt)) {
      refreshWalletData();
      loadLiveRates();
    }
  },
  { immediate: true }
);

function performTransaction() {
  const requests: Observable<Wallet | null>[] = [];
  const referenceDate = new Date();

  const event: CollateralActionEvent = {
    mode: props.mode,
    gbpAmount: state.currencies.find((c) => c.currency === 'GBP')?.model?.amount || 0,
    eurAmount: state.currencies.find((c) => c.currency === 'EUR')?.model?.amount || 0,
    usdAmount: state.currencies.find((c) => c.currency === 'USD')?.model?.amount || 0,
  };

  state.currencies.forEach((c) => {
    if (c.model && c.model.amount > 0) {
      const wallet = tradeState.store.useWalletsStore().getWallet(c.currency, {
        isPartner: isPartnerUser.value,
        id: ownerId.value,
      });
      if (wallet) {
        const request =
          props.mode === 'withdraw'
            ? tradeState.services.wallet.withdrawCollateral(wallet.id, c.model.amount, onBehalfOfClient.value?.id)
            : tradeState.services.wallet.postCollateral(wallet.id, c.model.amount, onBehalfOfClient.value?.id);

        requests.push(
          request.pipe(
            flatMap(() => timer(250).pipe(take(1))),
            flatMap(() =>
              from(
                tradeState.store.useWalletsStore().waitForWalletChange({
                  id: wallet.id,
                })
              )
            )
          )
        );
      }
    }
  });

  return requestManager.manager
    .new(
      'performTransaction',
      combineLatest(requests).pipe(
        flatMap(() =>
          from(
            tradeState.store.useWalletsStore().waitForCollateralsChange({
              clientId: onBehalfOfClient.value?.id,
              referenceDate,
            })
          )
        )
      )
    )
    .subscribe(() => {
      emit('transaction-executed', event);
      state.currencies.forEach((c) => {
        c.model = { amount: 0 };
        if (c.validation) {
          resetForm(c.validation);
        }
      });
    });
}

function onClick() {
  if (onBehalfOfClient.value) {
    openModal();
  } else {
    performTransaction();
  }
}
</script>

<template>
  <div class="currency-form">
    <VRow class="mb-2" align-h="end">
      <VCol cols="8">
        <span class="form-label"> {{ mode === 'post' ? 'Available Balance' : 'Posted Collateral' }}</span>
      </VCol>
      <VCol cols="4">
        <span class="form-label text-right">Amount to {{ mode }}</span>
      </VCol>
    </VRow>
    <CollateralTransactionCurrencyForm
      class="mb-3"
      :type="mode"
      :currency="currency.currency"
      :validation.sync="currency.validation"
      :model.sync="currency.model"
      v-for="currency in state.currencies"
      :key="currency.currency"
      :collaterals="collaterals"
    />
    <div class="row mb-3" v-if="collaterals">
      <div class="col-6">Total {{ collaterals.currency }} equivalent:</div>
      <div class="col-6 text-right">{{ collaterals.currency }} {{ formatCurrencyValue(totalEquivalent) }}</div>
    </div>
    <div class="row mb-3" v-if="collaterals && isOverWithdrawing">
      <div class="col-12 text-right error-message">
        You cannot exceed the total limit of {{ collaterals.currency }}
        {{ formatCurrencyValue(collaterals.unutilizedCollateral) }}
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-sm-center text-md-left">
        <VButton :disabled="isFormInvalid" :loading="requestManager.manager.anyPending" @click="onClick">
          <template v-if="btnTitle"> {{ btnTitle }}</template>
          <template v-else> {{ mode === 'withdraw' ? 'Withdraw' : 'Post' }} </template>
        </VButton>
        <BModal
          v-if="onBehalfOfClient"
          ref="completeActionModal"
          :centered="true"
          footer-class="justify-content-center"
          ok-title="Confirm"
          ok-variant="success"
          @ok="performTransaction"
        >
          <div class="text-center">
            <p>You are about to {{ mode === 'withdraw' ? 'withdraw' : 'post' }} collateral from the</p>
            <p>{{ onBehalfOfClient.name }} wallet on their behalf</p>
          </div>
        </BModal>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5em;
}

.error-message {
  color: getColor($color-danger);
  font-size: 0.8em;
}
</style>
