<template>
  <span>
    <div tag="li" class="menu-link" :class="{ 'router-link-active': inActivity }" v-if="$mediaQuery.is('smOnly')">
      <!-- Visible only on phablets displays -->
      <IconReport /> <span class="menu-link-text"> Activity </span>
      <span>
        <span @click="toggleActivity()"><IconChevronDown class="toggle-icon" :class="{ open: activityOpen }" /></span>
        <ExpandTransition appear>
          <ActivitySidebarView v-if="activityOpen" path="/admin/activity" class="router-view" name="sidebar" />
        </ExpandTransition>
      </span>
    </div>

    <RouterLink tag="li" class="menu-link" to="/admin/activity" v-else>
      <!-- Visible in tablet or higher resolutions -->
      <IconReport /> <span class="menu-link-text"> Activity </span>
    </RouterLink>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import ActivitySidebarView from '@/app/views/admin/activity/ActivitySidebarView.vue';

@Component({
  components: {
    ActivitySidebarView,
  },
})
export default class SideNavActivityLink extends Vue {
  private activityOpen: boolean = false;

  get inActivity() {
    return this.$route.fullPath.includes('admin/activity');
  }

  @Watch('$route.fullPath', { immediate: true })
  onRouteChange() {
    this.activityOpen = this.inActivity;
  }

  toggleActivity() {
    this.activityOpen = !this.activityOpen;
  }
}
</script>

<style lang="scss" scoped>
.toggle-icon {
  float: right;
  transition: transform 0.5s;
  font-size: 1.5em;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
