<template>
  <PrivateTopNav v-if="authStore.isLoggedIn" class="top-nav" />
  <PublicTopNav v-else class="top-nav" />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PublicTopNav from '@/app/components/nav/PublicTopNav.vue';
import PrivateTopNav from '@/app/components/nav/PrivateTopNav.vue';
import { useAuthStore } from '@/app/store/authStore';

@Component({
  components: {
    PublicTopNav,
    PrivateTopNav,
  },
})
export default class ClientDashboardTopNav extends Vue {
  get authStore() {
    return useAuthStore();
  }
}
</script>
