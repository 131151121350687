<template>
  <div class="ubo-info">
    <div>
      <h3 class="mb-2">{{ ubo.title }}. {{ ubo.firstName }} {{ ubo.lastName }}</h3>
      <DataRow cols="3" v-if="ubo.email" label="Email Address" class="account-holder mb-2">
        {{ ubo.email }}
      </DataRow>
      <DataRow cols="3" v-if="ubo.phoneNumber" label="Mobile Number" class="account-holder">
        {{ getPhoneNumber(ubo.phoneNumber) }}
      </DataRow>

      <UboDocumentsEditor :ubo="ubo" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BaseIndividual } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import UboDocumentsEditor from './UboDocumentsEditor.vue';
import { getPhoneNumber } from 'ah-common-lib/src/helpers/calls';

@Component({
  components: {
    UboDocumentsEditor,
    FileUploader,
  },
})
export default class UboInfo extends Mixins(WithRequestManager) {
  @Prop({ required: true }) ubo!: BaseIndividual;

  getPhoneNumber = getPhoneNumber;
}
</script>
