<script setup lang="ts">
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { FormDefinition, FormEvent } from 'ah-common-lib/src/form/interfaces';
import { radioField, singleInputDateField, sliderField } from 'ah-common-lib/src/form/models';
import { reactive, watch, computed } from 'vue';
import { getChildModel } from 'ah-common-lib/src/form/helpers';
import { useAhTradesState } from '../..';
import { formatDate } from 'ah-common-lib/src/helpers/time';
import {
  AmountType,
  CreateVanillaOptionsPriceRequest,
  NonTradeableDays,
  VanillaPriceResponse,
  PricingEngineErrorCodes,
  PremiumPaymentType,
  FeatureFlag,
} from 'ah-api-gateways';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { setState } from 'ah-common-lib/src/form/helpers';
import { DebouncedFunc, debounce, isEqual } from 'lodash';
import { date, disallowStateDates, ifTest, minDate } from 'ah-common-lib/src/form/validators';
import { required } from '@vuelidate/validators';
import { startOfTomorrow, addDays } from 'date-fns';
import { ApiError } from 'ah-requests';
import { useFeatureFlagStore } from 'ah-common-stores';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

const emit = defineEmits<{
  (e: 'remove:solution'): void;
  (e: 'sort:solutions', delta: number): void;
  (e: 'update:price', value: CreateVanillaOptionsPriceRequest): void;
  (e: 'update:validation', value: boolean): void;
  (e: 'update:solution', value: VanillaPriceResponse): void;
}>();

const tradeState = useAhTradesState();

const walletStore = tradeState.store.useWalletsStore();

const props = defineProps<{
  solution: VanillaPriceResponse;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  apiError?: null | ApiError;
}>();

const state = reactive<{
  debouncedStrikeRate: DebouncedFunc<() => void> | null;
  holidays: string[];
  weekends: string[];
  apiError: ApiError | null;
}>({
  debouncedStrikeRate: null,
  holidays: [],
  weekends: [],
  apiError: null,
});

const onBehalfOfClient = useOnBehalfOf();

const clientId = computed(
  () => onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const clientRiskProfile = computed(() => walletStore.getClientRiskProfile(clientId.value));

const getCurrencyDetails = (amountType: AmountType) => {
  return props.solution.ccy1.amountType === amountType ? props.solution.ccy1 : props.solution.ccy2;
};

const sellCcy = computed(() => getCurrencyDetails(AmountType.SELL));
const buyCcy = computed(() => getCurrencyDetails(AmountType.BUY));

const sellCurrency = computed(() => sellCcy.value.currency);

const buyCurrency = computed(() => buyCcy.value.currency);

const showProtectionInSell = computed(() => props.solution.premiumCurrency === sellCcy.value.currency);

const paymentOptionsMonthly = computed(() => {
  const currencyDetails = showProtectionInSell.value ? sellCcy.value : buyCcy.value;
  return currencyDetails.optionsCurrencyData.paymentOptions.find(
    (paymentOption) => paymentOption.premiumPaymentType === PremiumPaymentType.MONTHLY
  );
});

const isPremiumAboveLimits = computed(() => {
  const totalPremium = paymentOptionsMonthly.value?.totalGbpPremium ?? 0;
  const premiumFinancingLimitRemaining = clientRiskProfile.value?.creditProfile?.premiumFinancingLimitRemaining ?? 0;
  return premiumFinancingLimitRemaining === 0 || totalPremium > premiumFinancingLimitRemaining;
});

const displayMonthlyPaymentOption = computed(
  () =>
    useFeatureFlagStore().isFeatureFlagOn(FeatureFlag.VANILLA_OPTIONS_HNPL_ENABLED, onBehalfOfClient.value?.id) &&
    !isPremiumAboveLimits?.value
);

const strikeRateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'strikeRateForm',
    title: '',
    fieldType: 'form',
    fields: [
      sliderField('strikeRate', 'Strike Rate', {
        class: 'zigzag-labels with-spot-rate',
        min: 0.1,
        max: 1,
        marks: [],
        tooltipFormatter: (val: number) => tradeState.store.useSettingsStore().displayRate(val),
        valueToInputFn: (val: number) => tradeState.store.useSettingsStore().displayRate(val),
        interval: 0.0001,
        withMathFloor: false,
      }),
    ],
  }),
  validation: null,
});

const settlementDateForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'settlementDateForm',
    title: '',
    fieldType: 'form',
    fields: [
      singleInputDateField(
        'settlementDate',
        'Settlement Date',
        {
          required: true,
          errorMessages: {
            minDate: 'Settlement Date must be after two working days (T+2), please try again',
            required: 'Required field',
            holidays: 'Settlement date cannot be on a holiday, please try again',
            weekend: 'Settlement date cannot be on a weekend, please try again',
          },
        },
        {
          required: ifTest(required, (val) => !(val instanceof Date)),
          minDate: minDate(() => addDays(startOfTomorrow(), 1)),
          date: ifTest(date, (val) => val instanceof Date),
          holidays: disallowStateDates('settlementDate', 'holidays'),
          weekend: disallowStateDates('settlementDate', 'weekends'),
        }
      ),
    ],
  }),
  validation: null,
});

const options = computed(() => {
  if (displayMonthlyPaymentOption.value) {
    return [
      {
        label: 'Pay Now',
        value: PremiumPaymentType.UPFRONT,
      },
      {
        label: 'Pay Your Premium Monthly',
        value: PremiumPaymentType.MONTHLY,
      },
    ];
  }
  return [
    {
      label: 'Pay Now',
      value: PremiumPaymentType.UPFRONT,
    },
  ];
});

const premiumPaymentForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'premiumPaymentForm',
    title: '',
    fieldType: 'form',
    fields: [
      radioField('paymentType', '', options.value, {
        defaultValue: props.solution.premiumType ? props.solution.premiumType : PremiumPaymentType.UPFRONT,
        inputClass: 'separator custom-control custom-radio col col-12 my-0',
        inline: '',
      }),
    ],
  }),
  validation: null,
});

function loadNonTradeableDays() {
  tradeState.services.pricingEngine
    .getNonTradeableDays(props.solution.hedgingProduct, props.solution.currencyPair)
    .subscribe((result: NonTradeableDays) => {
      state.holidays = result.holidays.filter((d) => !result.weekend.includes(d));
      state.weekends = result.weekend;

      if (state.holidays) {
        setState(getChildModel(settlementDateForm.form, 'settlementDate')!, 'holidays', state.holidays);
      }
      if (state.weekends) {
        setState(getChildModel(settlementDateForm.form, 'settlementDate')!, 'weekends', state.weekends);
      }
    });
}

watch(
  () => props.solution.currencyPair,
  () => {
    loadNonTradeableDays();
  },
  { immediate: true }
);
const sellAmount = computed(() => formatCurrencyValue(sellCcy.value.clientAmount));

const buyAmount = computed(() => formatCurrencyValue(buyCcy.value.clientAmount));

const strikeRate = computed(() => formatCurrencyValue(isPrimaryRate.value.optionsCurrencyData.selectedStrikeRate));

const isPrimaryRate = computed(() => {
  if (props.solution.ccy1.isPrimaryRate === true) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

const isFixedSide = computed(() => {
  if (props.solution.ccy1.isFixedSide === true) {
    return props.solution.ccy1;
  }
  return props.solution.ccy2;
});

state.debouncedStrikeRate = debounce(() => {
  if (strikeRateForm.form.strikeRate && !state.apiError) {
    emit('update:price', {
      clientId: props.solution.clientId,
      sellCurrency: sellCurrency.value,
      buyCurrency: buyCurrency.value,
      amount: isFixedSide.value.clientAmount,
      amountType: isFixedSide.value.amountType,
      settlementDate: formatDate(new Date(props.solution.settlementDate), 'yyyy-MM-dd'),
      strikeRate: strikeRateForm.form.strikeRate,
    });
  }
}, 800);

function setErrorMessage(subError: ApiError) {
  setState(getChildModel(settlementDateForm.form, subError.field!)!, 'errors', [
    {
      name: 'peError',
      error:
        subError.code === PricingEngineErrorCodes.EXPIRY_DATE_INVALID
          ? 'Settlement date is too far in the future, please try again'
          : subError.message,
    },
  ]);

  state.apiError = subError;
}

watch(
  () => props.apiError,
  () => {
    if (props.apiError && props.apiError.field) {
      setErrorMessage(props.apiError);
    }
  }
);

const isInvalid = computed(() => settlementDateForm.validation?.$invalid || strikeRateForm.validation?.$invalid);

function updateValidations() {
  if (isInvalid.value) {
    emit('update:validation', isInvalid.value);
  }
}
watch(
  isInvalid,
  () => {
    updateValidations();
  },
  { immediate: true }
);

function onSettlementDateFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    if (settlementDateForm.validation?.$invalid === false) {
      state.apiError = null;

      emit('update:price', {
        clientId: props.solution.clientId,
        sellCurrency: sellCcy.value.currency,
        buyCurrency: buyCcy.value.currency,
        amount: isFixedSide.value.clientAmount,
        amountType: isFixedSide.value.amountType,
        settlementDate: formatDate(new Date(settlementDateForm.form.settlementDate), 'yyyy-MM-dd'),
        strikeRate: strikeRateForm.form.strikeRate,
      });
    }
  }
}

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    state.debouncedStrikeRate!();
  }
}

function displayRate(value: number) {
  if (isPrimaryRate.value) {
    /*
        / if difference between both spot rate and forward rate is small and overlap,
        / hide the lowest (if CALL option type) or highest (if PUT option type) since the rate is displayed anyway on top/bottom.
      */
    let displayedRate;
    isPrimaryRate.value.optionsCurrencyData.optionType === 'PUT'
      ? (displayedRate = Math.max(
          isPrimaryRate.value.optionsCurrencyData.forwardRate,
          isPrimaryRate.value.optionsCurrencyData.spotRate
        ))
      : (displayedRate = Math.min(
          isPrimaryRate.value.optionsCurrencyData.forwardRate,
          isPrimaryRate.value.optionsCurrencyData.spotRate
        ));

    if (!equalToRate(value)) {
      return tradeState.store.useSettingsStore().displayRate(value);
    } else if (value === displayedRate) {
      return tradeState.store.useSettingsStore().displayRate(value);
    }
  }
}

function calcRatePosition(rate: string) {
  if (isPrimaryRate.value) {
    const rtl =
      isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates[0] <
      isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates[
        isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates.length - 1
      ];
    const max = Math.max(...isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates);
    const min = Math.min(...isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates);
    const value = ((isPrimaryRate.value.optionsCurrencyData as any)[rate] as number) || min;

    return {
      [rtl ? 'left' : 'right']: `${((value - min) / (max - min)) * 100}%`,
    };
  }

  return {};
}

function equalToRate(value: number) {
  return (
    value === isPrimaryRate.value.optionsCurrencyData!.spotRate ||
    value === isPrimaryRate.value.optionsCurrencyData!.forwardRate
  );
}

function updateStrikeRates() {
  if (strikeRateForm && isPrimaryRate.value && isPrimaryRate.value.optionsCurrencyData) {
    const strikeRateField = getChildModel(strikeRateForm.form, 'strikeRate')!;

    setState(strikeRateField, 'max', Math.max(...isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates));
    setState(strikeRateField, 'min', Math.min(...isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates));
    setState(
      strikeRateField,
      'maxValue',
      Math.max(
        isPrimaryRate.value.optionsCurrencyData.maxStrikeInStrikeRange,
        isPrimaryRate.value.optionsCurrencyData.minStrikeInStrikeRange
      )
    );
    setState(
      strikeRateField,
      'minValue',
      Math.min(
        isPrimaryRate.value.optionsCurrencyData.maxStrikeInStrikeRange,
        isPrimaryRate.value.optionsCurrencyData.minStrikeInStrikeRange
      )
    );
    setState(strikeRateField, 'marks', isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates);
    setState(strikeRateField, 'defaultValue', isPrimaryRate.value.optionsCurrencyData.selectedStrikeRate);

    const isLeftToRight =
      isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates[0] <
      isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates[
        isPrimaryRate.value.optionsCurrencyData.defaultStrikeRates.length - 1
      ];
    const isPut = isPrimaryRate.value.optionsCurrencyData.optionType === 'PUT';

    if (isPut === isLeftToRight) {
      setState(strikeRateField, 'process', (dotsPos: number[]) => [[dotsPos[0], 100]]);
    } else {
    }

    setState(strikeRateField, 'direction', isLeftToRight ? 'ltr' : 'rtl');

    strikeRateForm.form.strikeRate = isPrimaryRate.value.optionsCurrencyData.selectedStrikeRate;
  }
}

function updateSettlementDate() {
  if (props.solution.settlementDate) {
    settlementDateForm.form.settlementDate = new Date(props.solution.settlementDate);
  }
}

watch(
  () => props.solution.settlementDate,
  (newVal, oldVal) => {
    if (!isEqual(oldVal, newVal)) {
      updateSettlementDate();
    }
  },
  { immediate: true }
);

watch(
  () => isPrimaryRate.value.optionsCurrencyData,
  (newVal, oldVal) => {
    if (!isEqual(oldVal, newVal)) {
      updateStrikeRates();
    }
  },
  { immediate: true, deep: true }
);

function changePremiumCurrency(currency: string) {
  emit('update:solution', { ...props.solution, premiumCurrency: currency });
}

function changePremiumType() {
  emit('update:solution', { ...props.solution, premiumType: premiumType.value });
}

const premiumType = computed<PremiumPaymentType>(() =>
  displayMonthlyPaymentOption.value ? premiumPaymentForm.form.paymentType : PremiumPaymentType.UPFRONT
);

watch(premiumType, changePremiumType, { immediate: true });

const paymentOptionsUpfront = computed(() => {
  if (showProtectionInSell.value) {
    return sellCcy.value.optionsCurrencyData.paymentOptions.find(
      (paymentOption) => paymentOption.premiumPaymentType === PremiumPaymentType.UPFRONT
    );
  } else {
    return buyCcy.value.optionsCurrencyData.paymentOptions.find(
      (paymentOption) => paymentOption.premiumPaymentType === PremiumPaymentType.UPFRONT
    );
  }
});

const payNowAmount = computed(() => formatCurrencyValue(paymentOptionsUpfront.value?.totalPremium));

const payMonthlyAmount = computed(() =>
  formatCurrencyValue(paymentOptionsMonthly.value?.premiumPaymentSchedule[0].amount)
);

const payMonthlyTotalAmount = computed(() => formatCurrencyValue(paymentOptionsMonthly.value?.totalPremium));

const payPremiumCurrency = computed(() =>
  showProtectionInSell.value ? sellCurrency.value ?? '-' : buyCurrency.value ?? '-'
);

const numberOfMonthsText = computed(() => {
  const premiumPayments = paymentOptionsMonthly.value?.premiumPaymentSchedule;
  const numberOfPayments = premiumPayments?.length;

  return premiumPayments && numberOfPayments ? `${numberOfPayments} ${numberOfPayments > 1 ? 'months' : 'month'}` : '-';
});

function getDescription(paymentType: PremiumPaymentType) {
  return paymentType === PremiumPaymentType.UPFRONT
    ? `Pay ${payPremiumCurrency.value} ${payNowAmount.value} today`
    : `Pay ${payPremiumCurrency.value} ${payMonthlyTotalAmount.value} over the next ${numberOfMonthsText.value}`;
}
</script>

<template>
  <div>
    <div class="card-header d-flex justify-content-between align-items-start">
      <h3 class="no-bottom-margin">{{ index + 1 }}. Vanilla Option</h3>
      <div class="delete-action d-flex align-items-center">
        <div class="reorder">
          <span :class="['reorder-arrow', { disabled: isFirst }]" @click="emit('sort:solutions', -1)">
            <IconArrowRight class="sort-arrow left" />
          </span>
          <span :class="['reorder-arrow', { disabled: isLast }]" @click="emit('sort:solutions', 1)">
            <IconArrowRight class="sort-arrow" />
          </span>
        </div>
        <span @click="emit('remove:solution')"><IconTrash class="trash-icon" /></span>
      </div>
    </div>
    <div class="card-body solution-details">
      <span
        >Currency Pair: <label class="label">{{ props.solution.currencyPair ?? '-' }}</label></span
      >
      <VRow class="mt-3">
        <VCol cols="12" class="mb-3">
          <ValidatedForm
            :fm="settlementDateForm.form"
            :validation.sync="settlementDateForm.validation"
            @form-event="onSettlementDateFormEvent"
            class="settlement-date-input"
          />
        </VCol>
        <VCol class="mt-3">
          <ValidatedForm
            :fm="strikeRateForm.form"
            :validation.sync="strikeRateForm.validation"
            @form-event="onFormEvent"
          >
            <template #strikeRateForm.strikeRate:extra-content>
              <div
                v-if="isPrimaryRate.optionsCurrencyData.spotRate"
                :class="[
                  'spot-rate',
                  {
                    call: isPrimaryRate.optionsCurrencyData.optionType === 'CALL',
                    put: isPrimaryRate.optionsCurrencyData.optionType === 'PUT',
                  },
                ]"
                :style="calcRatePosition('spotRate')"
              >
                <div class="inner">Spot {{ formatCurrencyValue(isPrimaryRate.optionsCurrencyData.spotRate) }}</div>
              </div>
              <div
                v-if="isPrimaryRate.optionsCurrencyData.forwardRate"
                :class="[
                  'forward-rate',
                  {
                    call: isPrimaryRate.optionsCurrencyData.optionType === 'CALL',
                    put: isPrimaryRate.optionsCurrencyData.optionType === 'PUT',
                  },
                ]"
                :style="calcRatePosition('forwardRate')"
              >
                <div class="inner">
                  Forward {{ formatCurrencyValue(isPrimaryRate.optionsCurrencyData.forwardRate) }}
                </div>
                <div class="arrow"></div>
              </div>
            </template>
            <template #strikeRateForm.strikeRate:mark-label="{ active, label }">
              <div :class="['vue-slider-mark-label', 'custom-label', { 'is-rate': equalToRate(label) }, { active }]">
                {{ displayRate(label) }}
              </div>
            </template></ValidatedForm
          >
          <div class="rates-block mb-3">
            <VCol col="12"
              ><label
                >Strike Rate: <span class="label h3-font">{{ strikeRate }}</span></label
              >
            </VCol>
            <VCol col="12"
              ><label
                >Expiry date:
                <span class="label h3-font"
                  >{{ formatDate(solution.expiryDate, 'MMM dd, yyyy') }} 10AM (New York time)</span
                ></label
              ></VCol
            >
            <VCol col="12" class="mb-3"
              ><label
                >Settlement date:
                <span class="label h3-font">{{ formatDate(solution.settlementDate, 'MMM dd, yyyy') }} </span></label
              ></VCol
            >
            <VCol col="12"><label>Converting from:</label></VCol>
            <VCol>
              <div class="d-flex">
                <label
                  >{{ sellCurrency }} <span class="label h3-font">{{ sellAmount }} </span></label
                >

                <div class="mx-3">
                  <IconArrowRight class="arrow" />
                </div>

                <label
                  >{{ buyCurrency }} <span class="label h3-font">{{ buyAmount }}</span></label
                >
              </div>
            </VCol>
          </div>
          <div class="costs">
            <VCol col="12">
              <VRow alignH="between">
                <VCol>
                  <label class="protection-cost">Protection Cost </label>
                </VCol>
                <VCol>
                  <VRow alignH="end">
                    <div class="currency-switcher">
                      <span :class="{ selected: showProtectionInSell }" @click="changePremiumCurrency(sellCurrency)">
                        {{ sellCurrency }}
                      </span>
                      <span :class="{ selected: !showProtectionInSell }" @click="changePremiumCurrency(buyCurrency)">
                        {{ buyCurrency }}
                      </span>
                    </div>
                  </VRow>
                </VCol>
              </VRow>
              <VRow>
                <VCol v-if="displayMonthlyPaymentOption">
                  <ValidatedForm :fm="premiumPaymentForm.form" :validation.sync="premiumPaymentForm.validation">
                    <template #optionTitle="option"
                      ><label v-if="option.option.value === PremiumPaymentType.MONTHLY" class="bold"
                        >Hedge Now, Pay Later</label
                      >
                    </template>
                    <template #premiumPaymentForm.paymentType:option="option">
                      <VRow>
                        <VCol cols="12">
                          <label class="bold">{{ option.option.label }}</label></VCol
                        >
                        <VCol cols="12">
                          <label class="text-small bold">{{ getDescription(option.option.value) }}</label></VCol
                        >
                        <VCol cols="12" v-if="option.option.value === PremiumPaymentType.MONTHLY">
                          <div class="card-block mt-2 py-1 premium-box">
                            <p class="m-0">
                              Pay <b>{{ payPremiumCurrency + ' 0.00' }} now</b>
                            </p>
                            <p p class="m-0">
                              Pay
                              <b>
                                {{ payPremiumCurrency }} {{ payMonthlyAmount }} monthly for {{ numberOfMonthsText }}</b
                              >
                            </p>
                          </div></VCol
                        >
                      </VRow>
                    </template>
                  </ValidatedForm>
                </VCol>
                <VCol v-else>
                  <VRow>
                    <VCol cols="12"> <label class="bold">Pay Now</label></VCol>
                    <VCol cols="12">
                      <label class="text-small bold">{{ getDescription(PremiumPaymentType.UPFRONT) }}</label></VCol
                    >
                  </VRow>
                </VCol>
              </VRow>
            </VCol>
          </div>
        </VCol>
      </VRow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.h3-font {
  font-size: $h3-font-size;
}
.card-header {
  padding-bottom: 1.5rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  @include themedPropColor('border-bottom-color', $color-input-border);
}

.card-body {
  padding: 1.5rem 0rem;

  .label {
    font-weight: $font-weight-bold;
  }

  .bold {
    font-weight: $font-weight-bold;
  }

  .rates-block {
    background-color: getColorHSLA($color-primary-light, 85%);
    @include themedTextColor($color-text, $color-text);
    margin-top: 2rem;
    padding: 0.5rem 0.7rem;
    padding-bottom: 1rem;

    span {
      .arrow {
        @include themedTextColor($color-text);
      }
    }
  }

  .costs {
    .protection-cost {
      @include themedTextColor($color-primary, $color-dark-primary);
    }
  }

  ::v-deep .date-wrapper {
    width: 100%;
  }

  ::v-deep .with-spot-rate {
    padding-bottom: 2em;
    label {
      margin-bottom: 4rem;
    }
  }

  .forward-rate {
    position: absolute;
    top: -3em;
  }

  .spot-rate {
    position: absolute;
    top: 2em;
  }

  .spot-rate,
  .forward-rate {
    .inner {
      padding: 0.4em 0.5em;
      font-size: 0.75rem;
      position: absolute;
      color: white;
      @include themedProp('box-shadow', '0px 2px 7px rgba(0, 0, 0, 0.3)', '0px 2px 7px rgba(0, 0, 0, 0.7)');
      border-radius: 4px;
      left: 0;
      transform: translate(-50%, 0%);
      width: 9em;
      text-align: center;
    }

    &.spot-rate .inner {
      @include themedBackgroundColor($color-primary-light);
      margin-top: 1.2em;
    }

    &.forward-rate .inner {
      @include themedBackgroundColor($color-primary);
      margin-bottom: 1em;
    }
    &.forward-rate .inner::before {
      content: '';
      position: absolute;
      display: block;
      width: 0px;
      left: 50%;
      bottom: 0;
      border: 8px solid transparent;
      border-bottom: 0;
      @include themedPropColor('border-top', $color-primary, $color-dark-primary, '', ' 15px solid');
      transform: translate(-50%, calc(100%));
    }

    &.spot-rate .inner::before {
      content: '';
      position: absolute;
      display: block;
      width: 0px;
      left: 50%;
      top: 0;
      border: 8px solid transparent;
      border-top: 0;
      @include themedPropColor('border-bottom', $color-primary-light, $color-dark-primary-light, '', ' 15px solid');
      transform: translate(-50%, calc(-100%));
    }
  }

  ::v-deep {
    .premium-box {
      @include themedBorderColor($color-primary);
    }

    .separator:first-child {
      padding-bottom: 1rem;
    }

    .separator:last-child {
      padding-top: 1rem;
      border-top: 1px solid #d1d5db;
    }
  }
}

.reorder {
  margin-right: 1rem;

  .reorder-arrow {
    @include themedTextColor($color-text-secondary);
    cursor: pointer;

    &.disabled {
      cursor: inherit;
      opacity: 0.6;
    }

    &:first-child {
      margin-right: 0.25em;
    }
    &:last-child {
      margin-left: 0.25em;
    }
    .sort-arrow {
      width: 1em;
      height: 1em;

      &.left {
        transform: rotate(180deg);
      }
    }
  }
}

.trash-icon {
  cursor: pointer;
  @include themedTextColor($color-text);
}

.currency-switcher {
  display: inline-block;
  border-width: 0px;
  margin-left: math.div($padded-space, 2);
  overflow: hidden;
  @include themedTextColor($color-primary-light);
  vertical-align: middle;

  > span {
    cursor: pointer;
    display: inline-block;
    padding: 4px;
    line-height: 1em;
    font-weight: $font-weight-bold;
    text-align: center;
    border-radius: 2px;

    &.selected {
      @include themedBackgroundColor($color-primary);
      color: $common-color-white;
    }
  }
}
</style>
