<template>
  <div class="change-password-form">
    <ValidatedForm :fm="changePasswordForm" :validation.sync="changePasswordValidation" />
    <div class="form-actions text-right">
      <VButton
        :loading="requestManager.requestStates.saveModel === 'pending'"
        label="Update password"
        class="btn btn-primary"
        @click="saveModelRequest()"
      />
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { makeFormModel, setState, getChildModel } from 'ah-common-lib/src/form/helpers';
import { emailField, passwordField } from 'ah-common-lib/src/form/models';
import { required } from '@vuelidate/validators';
import { tap } from 'rxjs/operators';
import { AuthenticationServiceErrorCodes, SecurityErrorCodes } from 'ah-api-gateways';
import { FieldModel, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { optional, sameAsField } from 'ah-common-lib/src/form/validators';
import { useAuthStore } from '@/app/store/authStore';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { Observable } from 'rxjs';

const changePasswordFM = () =>
  makeFormModel({
    name: 'changePasswordForm',
    title: 'Change Password',
    fieldType: 'form',
    fields: [
      emailField('email', 'Email', { autocomplete: 'username', hidden: true, required: false }),
      passwordField('oldPassword', 'Old password', false, {
        allowShowPassword: true,
        autocomplete: 'current-password',
      }),
      passwordField('newPassword', 'New password', true),
      passwordField(
        'newPasswordConfirm',
        'Confirm new password',
        false,
        {
          autocomplete: 'new-password',
          errorMessages: {
            required: 'Required field',
          },
        },
        {
          required,
          passwordMatch: optional(sameAsField('newPassword')),
        }
      ),
    ],
  });

@Component({
  components: {
    RouteProtectorModal,
  },
})
export default class ChangePassword extends Mixins(WithRequestManager) {
  private changePasswordValidation: FormValidation | null = null;

  private changePasswordForm = changePasswordFM();

  get authStore() {
    return useAuthStore();
  }

  get passwordField() {
    return getChildModel(this.changePasswordForm, 'oldPassword') as FieldModel;
  }

  get anyDirty() {
    return (
      this.changePasswordValidation?.newPassword.$anyDirty ||
      this.changePasswordValidation?.newPasswordConfirm.$anyDirty
    );
  }

  saveModelRequest() {
    this.changePasswordValidation?.$touch();
    if (!this.changePasswordValidation?.$invalid) {
      setState(this.passwordField, 'errors', []);

      let request: Observable<void> = this.authStore.userData?.tfaEnabled
        ? this.$otp.requestOtp({
            request: (otp) =>
              this.$services.account.changePassword(
                this.changePasswordForm.oldPassword,
                this.changePasswordForm.newPassword,
                otp,
                {
                  errors: { silent: true },
                }
              ),
          })
        : this.$services.account.changePassword(
            this.changePasswordForm.oldPassword,
            this.changePasswordForm.newPassword,
            undefined,
            {
              errors: { silent: true },
            }
          );

      this.requestManager
        .sameOrCancelAndNew(
          'saveModel',
          request.pipe(
            tap(
              () => {
                this.changePasswordForm = changePasswordFM();
                this.changePasswordValidation?.$reset();
                this.$toast.success('Account password updated');
              },
              (error) => {
                if (error?.response?.data?.code === AuthenticationServiceErrorCodes.BAD_CREDENTIALS) {
                  setState(this.passwordField, 'errors', [
                    {
                      name: 'wrongLogin',
                      error: 'Password inserted does not match current value',
                    },
                  ]);
                } else if (error?.response?.data?.code === SecurityErrorCodes.EXPIRED_OTP) {
                  this.$toast.error('The access code is no longer valid. Please try again.');
                }
              }
            )
          )
        )
        .subscribe();
    }
  }
}
</script>
