<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="name">
      <RouterLink :to="redirectTo" v-if="baseUrl"> {{ name }} </RouterLink>
      <span v-else class="client-name">{{ name }}</span>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins } from 'vue-property-decorator';
import UserIcon from '@/app/components/user/UserIcon.vue';
import { Client } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component({
  components: {
    UserIcon,
    LoadingIcon,
  },
})
export default class ClientNameCell extends Mixins(WithRequestManager) {
  @Prop({ required: true }) clientId!: string;

  @Prop({ required: false }) clientName?: string;

  @Prop({ required: false }) baseUrl?: string;

  private client: Client | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getIndividual') {
      this.beforeMount();
    }
  }

  get name() {
    return this.clientName || this.client?.name;
  }

  get redirectTo() {
    return `${this.baseUrl}${this.clientId}`;
  }

  beforeMount() {
    if (!this.clientName) {
      this.requestManager
        .sameOrCancelAndNew(
          'getIndividual',
          this.$services.client.getClient(this.clientId, true, { errors: { silent: true } }),
          this.clientId
        )
        .subscribe((client) => {
          this.client = client;
        });
    }
  }
}
</script>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
