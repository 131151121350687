<template>
  <div>
    <h2>Notifications Preferences</h2>
    <p class="text-secondary mt-n4">
      Setup which events you'd like {{ isIndividualClient ? '' : 'your team' }} to be notified about.
    </p>
    <BoxGrid>
      <BoxGridBlock>
        <NotificationSettingsTable
          :loading="loadingNotifications"
          v-slot="{ isStackedView }"
          :hideTargets="isIndividualClient"
        >
          <NotificationGroupSettingEditForm :settings="notificationSettingConfigs" :stacked="isStackedView" />
        </NotificationSettingsTable>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { ClientType, ClientReference } from 'ah-api-gateways';
import NotificationSettingsTable from '@/app/components/settings/notifications/NotificationSettingsTable.vue';
import NotificationGroupSettingEditForm from './NotificationGroupSettingEditForm.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationSettingsStore } from '@/app/store/notificationSettingsModule';

@Component({
  components: { NotificationGroupSettingEditForm, NotificationSettingsTable },
})
export default class NotificationSettingsEditor extends Mixins(WithRequestManager) {
  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadNotifications' || k === 'loadNotificationsConfig') {
      this.created();
    }
  }

  created() {
    this.requestManager.currentOrNewPromise('loadNotificationsConfig', () =>
      this.notificationSettingsStore.loadNotificationConfig(true)
    );
    this.requestManager.currentOrNewPromise('loadNotifications', () =>
      this.notificationSettingsStore.loadNotificationSettings(true)
    );
  }

  get authStore() {
    return useAuthStore();
  }

  get notificationSettingsStore() {
    return useNotificationSettingsStore();
  }

  get notificationSettingConfigs() {
    return this.notificationSettingsStore.notificationConfig?.filter((config) => config.editable);
  }

  get client(): ClientReference | null {
    return this.authStore.loggedInIdentity?.client ?? null;
  }

  get isIndividualClient() {
    return this.authStore.isClientUser && this.client!.type === ClientType.INDIVIDUAL;
  }

  get notifications() {
    return this.notificationSettingsStore.notificationSettings;
  }

  get isStackedView() {
    return this.$mediaQuery.is('smDown');
  }

  get loadingNotifications() {
    return (
      Object.keys(this.notifications.notifications).length === 0 &&
      this.requestManager.requestStates.loadNotifications === 'pending'
    );
  }
}
</script>
