<template>
  <div class="change-contacts-form">
    <ValidatedForm :fm="personalDetailsForm" :validation.sync="personalDetailsValidation" />
    <div class="form-actions text-right">
      <div class="form-actions text-left">
        <VButton
          :loading="requestManager.requestStates.saveModel === 'pending'"
          :disabled="!anyDirty"
          label="Update details"
          class="btn btn-primary mr-2"
          @click="saveModelRequest"
        />
        <VButton
          class="btn btn-secondary mr-2"
          v-if="anyDirty"
          :disabled="requestManager.requestStates.saveModel === 'pending'"
          label="Reset"
          @click="reset"
        />
      </div>
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import {
  makeFormModel,
  batchSetState,
  toDataModel,
  updateModel,
  submitForm,
  resetForm,
} from 'ah-common-lib/src/form/helpers';
import { personalDetailsForm } from 'ah-common-lib/src/form/formModels';
import { mergeMap, catchError } from 'rxjs/operators';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { waitForCQRSEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { useAuthStore } from '@/app/store/authStore';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

const formModel = () => {
  const formModel = makeFormModel(personalDetailsForm(false, true));

  formModel.$fields = formModel.$fields.filter((f) => f.$name !== 'email' && f.$name !== 'phoneNumber');
  delete formModel.phoneNumber;
  delete formModel.email;

  batchSetState(
    formModel,
    'fieldWrapperClass',
    [
      'firstName',
      'lastName',
      'birthDate',
      'address.addressLine1',
      'address.addressLine2',
      'address.postalCode',
      'address.city',
    ],
    'col col-md-6 col-sm-12'
  );

  batchSetState(formModel, 'fieldType', ['birthDate'], 'text');

  batchSetState(formModel, 'title', {
    'address.addressLine1': 'Street Address',
    'address.addressLine2': 'Apt/Suite',
  });

  return formModel;
};

@Component({ components: { RouteProtectorModal } })
export default class PersonalDetailsEditor extends Mixins(WithRequestManager) {
  private personalDetailsForm = formModel();

  private personalDetailsValidation: FormValidation | null = null;

  created() {
    updateModel(this.personalDetailsForm, this.model);
  }

  get authStore() {
    return useAuthStore();
  }

  get model() {
    return this.authStore.loggedInIdentity!;
  }

  get anyDirty() {
    return this.personalDetailsValidation?.$anyDirty;
  }

  reset() {
    if (this.model) updateModel(this.personalDetailsForm, this.model);
    this.resetForm();
  }

  resetForm() {
    if (this.personalDetailsValidation) resetForm(this.personalDetailsValidation);
  }

  saveModelRequest() {
    if (this.personalDetailsValidation) {
      submitForm(this.personalDetailsValidation);

      if (this.personalDetailsValidation.$invalid) {
        return;
      }
    }

    const modelUpdated = toDataModel(this.personalDetailsForm);

    if (modelUpdated.workPhoneNumber === '') {
      delete modelUpdated.workPhoneNumber;
    }

    this.requestManager
      .sameOrCancelAndNew(
        'saveModel',
        this.$services.individual.updateIndividual(this.model.id, modelUpdated).pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(
              idEntity,
              () => this.$services.individual.getIndividual(idEntity.id, { errors: { silent: true } }),
              {
                checkProcessRestart: () => {
                  return this.$bvModal
                    .msgBoxConfirm(
                      'Your personal details were updated successfully, but our system may take some time to display it. Would you like to keep waiting?',
                      {
                        title: 'Continue loading?',
                        okTitle: 'Continue',
                        centered: true,
                      }
                    )
                    .then((value) => !!value);
                },
              }
            ).pipe(catchError(() => of(null)))
          )
        )
      )
      .subscribe((individual) => {
        if (individual) {
          this.authStore.setIdentity(individual);
          this.$toast.success('Personal Details updated.');
          this.resetForm();
        } else {
          this.$ahTradesState.toast.error('An unexpected problem has occurred. Please try again later.');
        }
      });
  }
}
</script>

<style lang="scss" scoped>
::v-deep .readonly {
  pointer-events: none;
}
</style>
