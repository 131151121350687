<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    class="individuals-table"
    items-label="groups"
    :data-load-state="dataLoadState"
    :data="data"
    :selectedItems="selectedItems"
    :fields="config.tableFields"
    v-on="$listeners"
    v-bind="$attrs"
    @sort="loadData"
  >
    <template #cell(members)="{ item }">
      <span v-if="!item.individuals || item.individuals.length === 0"> No members </span>
      <span v-else-if="item.individuals.length > 3"> {{ item.individuals.length }} members </span>
      <span v-else>
        <span v-for="(individual, index) in item.individuals" :key="individual.id">
          {{ individual.firstName }} {{ individual.lastName }}<span v-if="index < item.individuals.length - 1">,</span>
        </span>
      </span>
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Listing from 'ah-common-lib/src/common/components/listing/Listing.vue';
import { PaginatedQuery, IndividualGroup } from 'ah-api-gateways';
import { groupTableFieldsFull } from '@/app/components/groups/tableFields/groupTableFields';
import { useAuthStore } from '@/app/store/authStore';

@Component
export default class GroupListing extends Listing<IndividualGroup> {
  @Prop({ default: () => ({ tableFields: groupTableFieldsFull }) }) config!: { tableFields: [] };

  protected loadDataRequest(query: PaginatedQuery) {
    return this.groupService.listGroups(query, this.groupParentId);
  }

  get authStore() {
    return useAuthStore();
  }

  get groupService() {
    if (this.authStore.isClientUser) {
      return this.$services.clientGroup;
    } else {
      return this.$services.partnerGroup;
    }
  }

  get groupParentId() {
    if (this.authStore.isClientUser) {
      return this.authStore.loggedInIdentity!.client!.id;
    } else {
      // Partner group endpoints do not use id in path, when called from a partner user
      return undefined;
    }
  }
}
</script>
