<template>
  <div>
    <div class="row mb-3">
      <div class="col col-md-8 col-sm-12">
        <label> Total commission (Product)</label>
      </div>
      <div class="col col-md-4 col-sm-6">
        <CommissionPeriodSelector :filter.sync="periodFilter" />
      </div>
    </div>
    <ProductCommissionListing :filter="filter" />
  </div>
</template>

<script lang="ts">
import ProductCommissionListing from '@/app/components/commission/ProductCommissionListing.vue';
import { Component, Vue } from 'vue-property-decorator';
import CommissionPeriodSelector from './CommissionPeriodSelector.vue';

@Component({
  components: {
    ProductCommissionListing,
    CommissionPeriodSelector,
  },
})
export default class ProductCommissionListBlock extends Vue {
  private periodFilter: {} | null = null;

  /**
   * filter gives the start date and the type of commission wiht which the start date is linked with,
   * product in this case
   */
  get filter() {
    return { ...this.periodFilter, type: 'products' };
  }
}
</script>
