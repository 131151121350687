<script setup lang="ts">
import { BModal } from 'bootstrap-vue';
import { ref } from 'vue';

const props = defineProps({
  modalTitle: {
    type: String,
    default: '',
  },
  modalText: {
    type: String,
    default: 'Confirm?',
  },
});

const alertModal = ref<InstanceType<typeof BModal>>();

function showModal() {
  alertModal.value?.show();
}

defineExpose({ showModal: showModal});
</script>

<template>
  <BModal
    centered
    ok-only
    ok-title="Ok"
    ok-variant="primary"
    :title="modalTitle"
    body-class="px-5"
    footer-class="justify-content-end px-5 pb-3"
    ref="alertModal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot name="modal-message">
      <div v-html="modalText"></div>
    </slot>
  </BModal>
</template>
