<template>
  <div>
    <h3>Permissions</h3>
    <slot />
    <LoadingIcon v-if="requestManager.requestStates.loadRole === 'pending'" />
    <PermissionsForm
      v-else
      :authorities="authorities"
      :value="permissions"
      @permission-change="onPermissionsChange($event)"
    />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { Authority, IndividualType, Permission, UserEntityType, UserRole } from 'ah-api-gateways';
import PermissionsForm from './PermissionsForm.vue';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component({
  components: {
    PermissionsForm,
    LoadingIcon,
  },
})
export default class PermissionsEditor extends Mixins(WithRequestManager) {
  /**
   * Type of target - will affect the service that gets called to load/save data
   */
  @Prop({ required: true }) targetType!: 'user' | 'group';

  @Prop({ required: true }) targetId!: string;

  @Prop({ required: true }) role!: IndividualType;

  requestManagerConfig = {
    exposeToParent: ['loadTargetPermissions'],
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'loadTargetPermissions') {
      this.onTargetChange();
    }
  }

  /**
   * Copy of the targetId property
   *
   * Used to maintain target reference until the debounced update is flushed
   */
  private currTargetId = '';

  private authorities: Authority[] = [];

  private permissions: Permission[] = [];

  private get entityType() {
    if (this.targetType === 'group') {
      return UserEntityType.GROUP;
    }
    return UserEntityType.USER;
  }

  @Watch('targetType', { immediate: true })
  @Watch('role')
  onTargetTypeChange() {
    this.requestManager
      .cancelAndNew('loadRole', this.$services.authz.getRoleAuthorities(this.role as any as UserRole, this.entityType))
      .subscribe((authorities) => {
        this.authorities = authorities ?? [];
      });
  }

  @Watch('targetId', { immediate: true })
  onTargetChange() {
    this.currTargetId = this.targetId;
    this.requestManager
      .cancelAndNew(
        'loadTargetPermissions',
        this.targetType === 'user'
          ? this.$services.authz.getUserPermissions(this.targetId)
          : this.$services.authz.getGroupPermissions(this.targetId, { errors: { silent: true } })
      )
      .subscribe((permissions) => {
        this.permissions = permissions;
      });
  }

  onPermissionsChange(permission: Permission) {
    this.savePermissions(permission);
  }

  savePermissions(permission: Permission) {
    this.requestManager
      .cancelAndNew(
        'savePermissions',
        this.targetType === 'user'
          ? this.$services.authz.setUserPermissions(this.targetId, permission, { errors: { silent: true } })
          : this.$services.authz.setGroupPermissions(this.targetId, permission, { errors: { silent: true } })
      )
      .subscribe();
  }
}
</script>
