<template>
  <div class="permissions-form">
    <div v-for="group in groupedAuthoritites" :key="group.name" class="auth-section">
      <h3 class="text-secondary mb-1" v-if="shouldDisplayGroupTitle">{{ group.name }}</h3>
      <div
        :class="shouldDisplaySwitch ? 'justify-content-between' : 'justify-content-start'"
        class="d-flex"
        v-for="authority in group.authorities"
        :key="authority.id"
      >
        <div class="permission-switch" v-if="!shouldDisplaySwitch">
          <BFormCheckbox
            :checked="hasPermission(authority.id)"
            :name="`check-button-${authority.name}`"
            :disabled="shouldBeReadonly"
            @input="setPermission(authority.id, $event)"
          />
        </div>
        <div class="permission-description">
          <h4>{{ authority.name }}</h4>
          <p class="text-secondary">{{ authority.description }}</p>
        </div>
        <div class="permission-switch" v-if="shouldDisplaySwitch">
          <BFormCheckbox
            :checked="hasPermission(authority.id)"
            :name="`check-button-${authority.name}`"
            :disabled="shouldBeReadonly"
            switch
            @input="setPermission(authority.id, $event)"
          />
        </div>
      </div>
    </div>
    <p v-if="authorities.length === 0" class="text-secondary">No editable permissions available for this role</p>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Authority, Permission } from 'ah-api-gateways';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { cloneDeep } from 'lodash';

@Component
export default class PermissionsForm extends Vue {
  @Prop({ required: true }) authorities!: Authority[];

  @Prop({ required: true }) value!: Permission[];

  @Prop({ default: false }) hideSwitch!: boolean | string;

  @Prop({ default: false }) hideGroupTitle!: boolean | string;

  @Prop({ default: false }) readonly!: boolean | string;

  get shouldDisplaySwitch() {
    return this.hideSwitch === false;
  }

  get shouldBeReadonly() {
    return this.readonly !== false;
  }

  get shouldDisplayGroupTitle() {
    return this.hideGroupTitle === false;
  }

  getCurrentValue() {
    let out = cloneDeep(this.value);
    if (out.length === 0) {
      out = this.authorities.map((authority) => ({ authority: authority.id, allow: false }));
    }
    return out;
  }

  setPermission(id: string, value: boolean) {
    let tempVal = this.getCurrentValue();
    const permission = tempVal.find((permission) => permission.authority === id);
    if (permission) {
      permission.allow = value;
      this.$emit('permission-change', permission);
    }
    this.$emit('update:value', tempVal);
  }

  hasPermission(id: string) {
    const permission = this.value.find((permission) => permission.authority === id);
    return permission?.allow ?? false;
  }

  @Watch('authorities', { immediate: true })
  onAuthoritiesChange() {
    if (this.authorities.length && !this.value.length) {
      this.$emit('update:value', this.getCurrentValue());
    }
  }

  get groupedAuthoritites() {
    const out = groupBy(this.authorities, 'sectionName');

    return Object.keys(out)
      .map((group) => ({
        name: group,
        index: out[group][0].sectionIndex,
        authorities: sortBy(out[group], 'index'),
      }))
      .sort((a, b) => a.index - b.index);
  }
}
</script>
