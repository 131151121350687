<script lang="ts" setup>
import { makeFormModel, getChildModel, setState, makeValidation } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseFormModel, FormEvent } from 'ah-common-lib/src/form/interfaces';
import useVuelidate from '@vuelidate/core';
import { computed, onBeforeUnmount, reactive, watch } from 'vue';

const props = defineProps({
  stroked: {
    type: [Boolean, String],
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Search...',
  },
  search: {
    type: String,
    default: '',
  },
});

const emit = defineEmits({
  'update:search': (_value: string) => true,
});

const searchForm = reactive(
  makeFormModel({
    name: 'searchForm',
    fieldType: 'form',
    fields: [
      textField(
        'search',
        '',
        {
          // Setting input name directly to avoid pass manager autocomple nonsense
          inputName: `beneficiary-search-box`,
          placeholder: 'Search...',
          autocomplete: 'off',
        },
        {}
      ),
    ],
  })
);

/**
 * FIXME This direct vuelidate usage is kept but we should at a later date, move to using ValidatedForm components only
 * In order to do this, we need to allow overriding the Errors component (as we purposefully use an alternative in the Trade screens)
 */
const searchFormValidation = useVuelidate<{ searchForm: BaseFormModel }>(
  { searchForm: makeValidation(searchForm) },
  { searchForm }
);

const searchTextSubject = new Subject<string>();

const searchSubscription: Subscription = searchTextSubject.pipe(debounceTime(700)).subscribe((search: string) => {
  if (search === searchForm.search) {
    emit('update:search', search);
  }
});

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-set-value') {
    searchTextSubject.next(searchForm.search);
  }
}

watch(
  () => props.stroked,
  () => (searchForm.$fields[0].$state.inputGroupClass = props.stroked !== false ? '' : 'borderless'),
  { immediate: true }
);

watch(
  () => props.placeholder,
  (val) => searchModel.value && setState(searchModel.value, 'placeholder', val),
  { immediate: true }
);

watch(
  () => props.search,
  (val: string) => (searchForm.search = val),
  { immediate: true }
);

onBeforeUnmount(() => searchSubscription.unsubscribe());

function resetSearch() {
  searchForm.search = '';
  emit('update:search', searchForm.search);
}

const searchModel = computed(() => getChildModel(searchForm, 'search'));
</script>

<template>
  <FormElement
    class="search-input"
    :field="searchFormValidation.searchForm.search"
    :model="searchModel"
    @form-event="onFormEvent"
  >
    <template #searchForm.search:prepend>
      <BInputGroupText key="searchForm.search:prepend">
        <IconSearch />
      </BInputGroupText>
    </template>
    <template #searchForm.search:append v-if="searchForm.search">
      <BInputGroupText key="searchForm.search:append" class="reset-input" @click="resetSearch">
        <IconCircleX />
      </BInputGroupText>
    </template>
  </FormElement>
</template>

<style lang="scss" scoped>
.reset-input {
  cursor: pointer;

  svg {
    opacity: 0.4;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}
</style>
