<template>
  <div class="change-contacts-form">
    <ValidatedForm :fm="partnerDetailsForm" :validation.sync="partnerDetailsValidation" @form-event="onFormEvent" />
    <div class="form-actions text-right">
      <VButton
        class="btn btn-secondary mx-2"
        :disabled="!anyDirty || requestManager.requestStates.saveModel === 'pending'"
        label="Reset"
        @click="reset"
      />
      <VButton
        :disabled="!anyDirty"
        :loading="requestManager.requestStates.saveModel === 'pending'"
        label="Save"
        class="btn btn-success ml-2"
        @click="saveModelRequest()"
      />
    </div>
    <RouteProtectorModal
      :allowChange="!anyDirty"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import {
  makeFormModel,
  toDataModel,
  getChildModel,
  setState,
  updateModel,
  batchSetState,
  setApiErrorMessages,
} from 'ah-common-lib/src/form/helpers';
import { partnerInformationForm } from 'ah-common-lib/src/form/formModels';
import { FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { AuthorityType, Partner } from 'ah-api-gateways';
import { catchError, mergeMap } from 'rxjs/operators';
import { HttpError, waitForCQRSEntityChange } from 'ah-requests';
import { useSettingsStore } from '@/app/store/settingsModule';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import { isEqual } from 'lodash';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { of } from 'rxjs';
import { useAuthStore } from '@/app/store/authStore';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

const formModel = () => {
  const formModel = makeFormModel(partnerInformationForm(false));
  batchSetState(formModel, 'readonly', ['name', 'registrationNumber'], true);
  return formModel;
};

@Component({ components: { RouteProtectorModal } })
export default class PartnerInformationEditor extends Mixins(WithRequestManager) {
  @Prop({ required: true }) partner!: Partner;

  private partnerDetailsForm = formModel();

  private partnerDetailsValidation: FormValidation | null = null;

  beforeMount() {
    const countryCode = getChildModel(this.partnerDetailsForm, 'address.countryCode')!;
    if (countryCode) {
      setState(countryCode, 'readonly', true);
      this.settingsStore.loadCountries().then(() => {
        setState(
          countryCode,
          'options',
          this.settingsStore.allCountries.map((c) => ({
            value: c.cc,
            label: c.name,
          }))
        );
      });
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get canEditPartner() {
    return this.authStore.hasAuthorities(AuthorityType.UPDATE_PARTNER);
  }

  @Watch('canEditPartners', { immediate: true })
  onCanEditPartnersChange() {
    setState(this.partnerDetailsForm, 'readonly', !this.canEditPartner);
  }

  @Watch('partner', { immediate: true })
  onPartnerChange() {
    if (this.partner) {
      updateModel(this.partnerDetailsForm, this.partner);
    }
  }

  reset() {
    if (this.partner) updateModel(this.partnerDetailsForm, this.partner);
  }

  get settingsStore() {
    return useSettingsStore();
  }

  get anyDirty() {
    return !isEqual({ ...this.partner, ...toDataModel(this.partnerDetailsForm) }, this.partner);
  }

  onFormEvent = (formEvent: FormEvent) => {
    if (formEvent.event === 'form-field-set-value') {
      setState(formEvent.model, 'errors', []);
    }
  };

  saveModelRequest() {
    const partnerUpdate = toDataModel(this.partnerDetailsForm);

    this.requestManager
      .sameOrCancelAndNew(
        'saveModel',
        this.$services.partner.updatePartner(partnerUpdate).pipe(
          mergeMap((idEntity) =>
            waitForCQRSEntityChange(idEntity, () => this.$services.partner.getPartner({ errors: { silent: true } }), {
              checkProcessRestart: () => {
                return this.$bvModal
                  .msgBoxConfirm(
                    'Partner has been updated successfully, but our system may take some time to display it. Would you like to keep waiting?',
                    {
                      title: 'Continue loading?',
                      okTitle: 'Continue',
                      centered: true,
                    }
                  )
                  .then((value) => !!value);
              },
            }).pipe(catchError(() => of(null)))
          )
        )
      )
      .subscribe(
        (response) => {
          if (response) {
            this.$toast.success('Partner updated successfully.');
            this.$emit('update:partner', {
              ...this.partner,
              ...partnerUpdate,
            } as Partner);
          } else {
            this.$ahTradesState.toast.error('An unexpected problem has occurred. Please try again later.');
          }
        },
        (error: HttpError) => {
          const apiErrors = constructPayloadErrors<Partner>(error.response!.data);
          if (apiErrors.fields?.address) {
            setApiErrorMessages(apiErrors.fields.address, this.partnerDetailsForm.address);
          }
        }
      );
  }
}
</script>
