import { HttpService, HttpBasedService, HttpOptions, uploadFileWithUpdates, HttpRequestOptions } from 'ah-requests';
import {
  Individual,
  PaginatedResponse,
  standardiseQuerySort,
  ListQuery,
  ExportListType,
  IndividualFileCategories,
  BareIndividual,
  IndividualSettings,
  IndividualType,
  VersionedObject,
  IndividualListQueryParameters,
  DocumentExport,
} from '../models';
import { map } from 'rxjs/operators';
import { downloadFile, fileToBlob } from 'ah-requests/helpers/download';
import { UploadedFile, FileUploadRequest } from '../models/files';

enum IndividualCacheKeys {
  individual = 'individual',
  individualSettings = 'individualSettings',
}

export class IndividualService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'individualService' } },
      },
    });
  }

  public getIndividual(id: string, options?: Partial<HttpOptions<Individual>>, override?: HttpRequestOptions) {
    return this.get<Individual>(`${this.baseUrl}individuals/${id}`, {
      options: {
        ...options,
      },
      ...override,
    });
  }

  public deleteIndividual(id: string, options?: Partial<HttpOptions<void>>) {
    return this.delete<void>(`${this.baseUrl}individuals/${id}`, {
      options: {
        ...options,
      },
    });
  }

  public getIndividualSettings(id: string, options?: Partial<HttpOptions<IndividualSettings>>) {
    return this.get<IndividualSettings>(`${this.baseUrl}agents/${id}/settings`, {
      options: {
        cache: {
          type: 'use',
          cacheKey: IndividualCacheKeys.individualSettings,
          itemKey: id,
        },
        ...options,
      },
    });
  }

  public updateIndividualSettings(
    id: string,
    individualSettings: Partial<IndividualSettings>,
    cache: 'bypass' | 'override' = 'override'
  ) {
    return this.put<IndividualSettings>(`${this.baseUrl}agents/${id}/settings`, individualSettings, {
      options: {
        cache: {
          type: cache,
          cacheKey: IndividualCacheKeys.individualSettings,
          itemKey: id,
        },
      },
    });
  }

  public listIndividuals(
    query: IndividualListQueryParameters,
    options?: Partial<HttpOptions<PaginatedResponse<Individual>>>
  ) {
    query = { ...query };
    if (query.sort === 'name') {
      const sortDirection = query.sortDirection ? query.sortDirection.toLowerCase() : 'desc';
      query.sort = [`firstName,${sortDirection}`, `lastName,${sortDirection}`];
    } else {
      query = standardiseQuerySort(query);
    }
    return this.get<PaginatedResponse<Individual>>(`${this.baseUrl}individuals`, {
      options,
      axiosConfig: { params: query },
    });
  }

  public downloadIndividualList(query: ListQuery, fileFormat: ExportListType, documentTitle = 'Individuals List') {
    query = { ...query };
    if (query.sort === 'name') {
      const sortDirection = query.sortDirection ? query.sortDirection.toLowerCase() : 'desc';
      query.sort = [`firstName,${sortDirection}`, `lastName,${sortDirection}`];
    } else {
      query = standardiseQuerySort(query);
    }

    return this.get<DocumentExport>(`${this.baseUrl}individuals/export`, {
      axiosConfig: {
        params: {
          ...query,
          fileFormat,
          documentTitle,
        },
      },
    });
  }

  public updateIndividual(id: string, individual: Partial<Individual>) {
    return this.put<VersionedObject>(`${this.baseUrl}individuals/${id}`, individual, {
      options: { cache: { type: 'delete', cacheKey: IndividualCacheKeys.individual, itemKey: id } },
    });
  }

  public updateIndividualType(id: string, type: IndividualType) {
    return this.put<VersionedObject>(`${this.baseUrl}individuals/${id}/type`, { type });
  }

  public getDocuments(id: string, options?: Partial<HttpOptions<UploadedFile[]>>) {
    return this.get<UploadedFile[]>(`${this.baseUrl}individuals/${id}/documents`, { options });
  }

  public deleteDocument(individualId: string, fileId: string) {
    return this.delete<{}>(`${this.baseUrl}individuals/${individualId}/documents/${fileId}`);
  }

  public getDocumentUrl(individualId: string, file: UploadedFile) {
    return file.link ?? `${this.baseUrl}individuals/${individualId}/documents/${file.id}`;
  }

  public downloadSyncDocument(individualId: string, file: UploadedFile) {
    return this.downloadDocument(individualId, file).pipe(
      map((response) => {
        downloadFile(response);
      })
    );
  }

  private downloadDocument(individualId: string, file: UploadedFile) {
    return this.rawRequest<any>({
      axiosConfig: {
        method: 'get',
        responseType: 'arraybuffer',
        url: this.getDocumentUrl(individualId, file),
      },
    });
  }

  public getDocumentAsBlob(individualId: string, file: UploadedFile) {
    return this.downloadDocument(individualId, file).pipe(
      map((response) => {
        return fileToBlob(response);
      })
    );
  }

  public uploadDocument(individualId: string, category: IndividualFileCategories, file: File) {
    const formData = new FormData();

    formData.append(
      'model',
      JSON.stringify(<FileUploadRequest>{
        category,
        name: file.name,
      })
    );

    formData.append('file', file);

    return uploadFileWithUpdates<VersionedObject>(
      {
        axiosConfig: {
          url: `${this.baseUrl}individuals/${individualId}/documents`,
          method: 'POST',
          data: formData,
        },
      },
      (c) => this.request(c)
    );
  }

  public getIndividualPhotoLink(individualId: string) {
    return `${this.baseUrl}individuals/${individualId}/photo`;
  }

  public getInvite(token: string) {
    return this.get<Individual>(`${this.baseUrl}register/individual/invite`, {
      axiosConfig: {
        params: {
          token,
        },
      },
      options: {
        skipAuth: true,
      },
    });
  }

  public inviteIndividual(
    data: {
      firstName: string;
      lastName: string;
      phoneNumber?: string;
      jobTitle?: string;
      workPhoneNumber?: string;
      type: IndividualType;
      email: string;
      client?: { id: string };
      partner?: { id: string };
    },
    options?: Partial<HttpOptions<any>>
  ) {
    return this.post<VersionedObject>(`${this.baseUrl}register/individual/invite`, data, {
      options,
    });
  }

  public reinviteIndividual(id: string) {
    return this.put<VersionedObject>(`${this.baseUrl}register/individual/${id}/invite`);
  }

  public registerFromInvite(data: { token: string; id: string; identity: BareIndividual }) {
    return this.post<VersionedObject>(`${this.baseUrl}register/individual/${data.id}/invite/accept`, {
      token: data.token,
      ...data.identity,
    });
  }
}
