<template>
  <div id="user-menu-toggle">
    <div class="d-flex justify-content-center align-items-center cursor-pointer">
      <UserIcon :user="user" class="mr-3" />
      <p class="semi-bold m-0">{{ user.firstName }} {{ user.lastName }}</p>
      <IconChevronDown :class="['ml-3 menu-toggle', { open: show }]" />
    </div>
    <BPopover
      v-if="user"
      placement="bottomright"
      custom-class="arrowless"
      target="user-menu-toggle"
      triggers="click blur"
      :show.sync="show"
    >
      <UserMenu ref="userMenu" @logout="logout" />
    </BPopover>

    <!-- MODALS -->
    <BModal
      :centered="true"
      ok-title="Yes, log out"
      cancel-variant="secondary"
      body-class="py-5 px-5"
      footer-class="justify-content-start pl-5 pb-5"
      ref="deleteModal"
      >Are you sure you want to log out?
    </BModal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import UserMenu from './UserMenu.vue';
import { isDescendant } from 'ah-common-lib/src/helpers/dom';
import { BModal } from 'bootstrap-vue';
import { IndividualFileCategories } from 'ah-api-gateways';
import AsyncImage from '@/app/components/common/AsyncImage.vue';
import UserIcon from './UserIcon.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';

@Component({
  components: {
    UserMenu,
    AsyncImage,
    UserIcon,
  },
})
export default class UserIconMenu extends Vue {
  $refs!: {
    userMenu: UserMenu;
    deleteModal: BModal;
  };
  private show = false;

  private clickListener!: (event: MouseEvent) => void;

  created() {
    if (this.authStore.isClientUser) {
      this.individualSettingsStore.loadIndividualDocuments();
    }
  }

  mounted() {
    this.clickListener = (event: MouseEvent) => {
      if (
        this.show &&
        this.$refs.userMenu &&
        event.target &&
        !isDescendant((this.$refs.userMenu as Vue).$el, event.target as Element)
      ) {
        this.show = false;
      }
    };
    window.addEventListener('click', this.clickListener);
  }

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get photo() {
    return this.individualSettingsStore.getIndividualDocument(IndividualFileCategories.PROFILE_PICTURE);
  }

  logout() {
    this.$refs.deleteModal.show();
    this.$refs.deleteModal.$once('ok', () => this.authStore.logout());
  }

  @Watch('$route')
  onRouteChange() {
    this.show = false;
  }

  beforeDestroy() {
    window.removeEventListener('click', this.clickListener);
  }

  get initials() {
    return ((this.user!.firstName || ' ')[0] + (this.user!.lastName || ' ')[0]).toUpperCase();
  }

  get user() {
    return this.authStore.loggedInIdentity;
  }
}
</script>

<style lang="scss">
.b-popover {
  z-index: 100;
}

#user-menu-toggle {
  padding: 10px 15px;
}

.b-popover.arrowless .arrow {
  opacity: 0;
}

.menu-toggle {
  cursor: pointer;
  transition: transform 0.6s;
  width: 24px;
  height: 24px;

  &.open {
    transform: rotate(180deg);
  }
}
</style>
