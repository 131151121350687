<template>
  <div>
    <LoadingIcon v-if="requestManager.anyPending" />
    <template v-else-if="clientIndividuals">
      <template v-if="isShowingNames">
        <span class="client-name" v-for="(individual, index) in shownIndividuals" :key="individual.id">
          <slot name="individual-text" :individual="individual">
            {{ individual.firstName }} {{ individual.lastName }}
            <span v-if="index > shownIndividuals.length - 1">,</span>
          </slot>
        </span>
        <span v-if="showCount && extraIndividualCount > 0">+{{ extraIndividualCount }} others</span>
      </template>
      <span v-else-if="showCount">{{ extraIndividualCount }} Individuals</span>
    </template>
    <span v-else>N/A</span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import UserIcon from '@/app/components/user/UserIcon.vue';
import { Client, PaginatedResponse, Individual } from 'ah-api-gateways';
import { LoadingIcon } from 'ah-common-lib/src/icons/components';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component({
  components: {
    UserIcon,
    LoadingIcon,
  },
})
export default class ClientIndividualsList extends Mixins(WithRequestManager) {
  @Prop({ required: true }) client!: Client;

  /**
   * Filter out individuals without users
   */
  @Prop({ default: true }) hideUserlessIndividuals!: boolean;

  /**
   * Max number of items shown. If 0, shows only count of individuals
   */
  @Prop({ default: 0 }) maxItems!: number;

  /**
   * Whether to show the count of Individuals
   */
  @Prop({ default: true }) showCount!: boolean;

  clientIndividuals: PaginatedResponse<Individual> | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getIndividuals') {
      this.loadClientIndividuals();
    }
  }

  get extraIndividualCount() {
    if (this.clientIndividuals) {
      return this.clientIndividuals.total - Math.min(this.maxItems, this.clientIndividuals.list.length);
    }

    return 0;
  }

  get isShowingNames() {
    return this.shownIndividuals.length > 0;
  }

  get shownIndividuals() {
    if (this.clientIndividuals) {
      return this.clientIndividuals.list.slice(0, this.maxItems);
    }
    return [];
  }

  @Watch('client.id', { immediate: true })
  loadClientIndividuals() {
    this.requestManager
      .sameOrCancelAndNew(
        'getIndividuals',
        this.$services.individual.listIndividuals({ clientId: this.client.id, pageSize: Math.max(1, this.maxItems) }),
        this.client.id
      )
      .subscribe((clientIndividuals) => {
        // only display individuals that have an user associated.
        if (this.hideUserlessIndividuals)
          clientIndividuals.list = clientIndividuals.list.filter((individual) => individual.user.id);
        this.clientIndividuals = clientIndividuals;
      });
  }
}
</script>

<style lang="scss" scoped>
span:not(.client-name) {
  opacity: 0.5;
}
</style>
