<template>
  <PaginatedTable
    fixed
    hover
    nowrap
    animateCols
    class="individuals-table"
    items-label="individuals"
    :data-load-state="dataLoadState"
    :data="data"
    :tbody-tr-class="rowColorClass"
    :selectedItems="selectedItems"
    :fields="config.tableFields"
    v-on="$listeners"
    v-bind="$attrs"
    @sort="loadData"
  >
    <template #cell(name)="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
    <template #cell(status)="{ item }">
      <StatusPill v-if="isAdmin(item)" :label="item.owner ? 'Owner' : 'Admin'" color="success" class="text-small" />
      <StatusPill v-else-if="!isApproved(item)" v-bind="individualAttrs(item)" class="text-small" />
      <span v-else />
    </template>

    <!-- row details -->
    <template #row-details="row">
      <slot name="row-details" v-bind="row" />
    </template>
  </PaginatedTable>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Listing from 'ah-common-lib/src/common/components/listing/Listing.vue';
import { PaginatedQuery, Individual, IndividualType, RegistrationStatus } from 'ah-api-gateways';
import { companyMemberTableFieldsFull } from '@/app/components/individual/tableFields/companyMemberTableFields';
// FIXME importing a composition API component with multiple scripts from a Class Component fails, need to ignore TS checks
// @ts-ignore
import StatusPill from 'ah-common-lib/src/common/components/StatusPill.vue';

@Component({
  components: {
    StatusPill,
  },
})
export default class IndividualListing extends Listing<Individual> {
  @Prop({ default: () => ({ tableFields: companyMemberTableFieldsFull }) }) config!: { tableFields: [] };

  protected loadDataRequest(query: PaginatedQuery) {
    return this.$services.individual.listIndividuals({
      ...query,
    });
  }

  private isAdmin(item: Individual) {
    return [IndividualType.PARTNER_ADMIN, IndividualType.CLIENT_ADMIN].includes(item.type);
  }

  private rowColorClass(individual: Individual) {
    if (individual && !this.isAdmin(individual) && individual.status !== RegistrationStatus.APPROVED) {
      return 'warning';
    }
  }

  private individualAttrs(individual: Individual) {
    const label = individual.status === RegistrationStatus.INVITED ? 'Invited' : 'Proposed';
    return {
      label,
      color: 'warning',
    };
  }

  private isApproved(individual: Individual) {
    return individual.status === RegistrationStatus.APPROVED;
  }
}
</script>

<style lang="scss" scoped>
::v-deep .warning td::before {
  @include themedBorderColor($color-yellow-highlight, $color-yellow-highlight, '', ' !important');
}
</style>
