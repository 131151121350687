<script setup lang="ts">
import { AuthorityType, Client, FxMargins } from 'ah-api-gateways';
import ClientMarginEditModal from '../ClientMarginEditModal.vue';
import { computed, PropType, ref, watch } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';

const props = defineProps({
  client: {
    type: Object as PropType<Client>,
  },
});

const authStore = useAuthStore();

const settings = ref<FxMargins>();

const services = getServices();

const canEditMarkup = computed(() => authStore.hasAuthorities(AuthorityType.UPDATE_SPREADS));

const requestManager = useRequestManager({
  exposeToParent: true,
  onRetryFromParentManager: (k: string) => {
    if (k === 'getClientSettings') {
      onClientChange();
    }
  },
}).manager;

function onClientChange() {
  if (props.client) {
    requestManager
      .sameOrCancelAndNew('getClientSettings', services.spreads.getClientSpreads(props.client.id))
      .subscribe((response) => (settings.value = response));
  }
}

watch(() => props.client, onClientChange, { immediate: true });

function onClientUpdated(clientSettings: FxMargins) {
  settings.value = clientSettings;
}
</script>

<template>
  <div class="info-block" v-if="settings">
    <div class="rows-block">
      <h4>Markups</h4>
      <DataRow lg="4" md="6" label="Spot" class="text-wrap mb-2 mt-2 fx-pot">
        <span class="percentage-text text-nowrap">{{ settings.fxSpots.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Fixed Forward" class="text-wrap mb-2 fx-fixed-forward">
        <span class="percentage-text text-nowrap">{{ settings.fxForwards.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Flexible Forward" class="mb-2 fx-flexible-forward">
        <span class="percentage-text text-nowrap">{{ settings.flexibleForwards.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Window Forward" class="text-wrap mb-4 fx-window-forward">
        <span class="percentage-text text-nowrap">{{ settings.windowForwards.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Vanilla Option" class="text-wrap mb-4 fx-window-forward">
        <span class="percentage-text text-nowrap">{{ settings.vanillaOptions.profitMargin / 100 }}%</span>
      </DataRow>
    </div>

    <div class="text-center">
      <ClientMarginEditModal
        @update:client-settings="onClientUpdated($event)"
        :client="client"
        :settings="settings"
        v-slot="{ show }"
      >
        <VButton @click="show" class="btn-secondary" v-if="canEditMarkup">Edit Markups</VButton>
      </ClientMarginEditModal>
    </div>
  </div>
</template>
