<template>
  <div>
    <h2>Authorisations</h2>
    <p class="text-secondary mt-n4">These settings authorise {{ partnerName }} to act on your behalf</p>
    <BoxGrid>
      <BoxGridBlock>
        <AuthorisationsSettingsTable
          class="p-2"
          :loading="requestManager.anyPending"
          :hideHeaders="$mediaQuery.is('smDown')"
        >
          <div class="authorisations-block">
            <VRow
              align-v="start"
              class="mb-md-5 mb-sm-3"
              v-for="fieldData in fieldsData"
              :key="fieldData.data.authority"
            >
              <VCol cols="6" :class="columnClass('authority')">
                <div class="mb-md-0 mb-sm-2">
                  <h3 class="title d-inline">{{ fieldData.authorisation.name }}</h3>
                  <BFormCheckbox
                    v-if="$mediaQuery.is('smDown')"
                    :checked="fieldData.setting.allow"
                    class="float-right"
                    name="check-button"
                    @change="fieldData.setting.allow = $event"
                    switch
                  />
                </div>
                <p class="sub-title text-secondary">
                  {{ fieldData.authorisation.description }}
                </p>
              </VCol>
              <VCol cols="2" :class="columnClass('switch')" v-if="!$mediaQuery.is('smDown')">
                <BFormCheckbox
                  :checked="fieldData.setting.allow"
                  name="check-button"
                  @change="fieldData.setting.allow = $event"
                  switch
                />
              </VCol>
              <VCol :class="columnClass('date')">
                <ValidatedForm
                  :fm="fieldData.data.form"
                  @update:validation="fieldData.data.validation = $event"
                  :ref="fieldData.data.authority.toLowerCase()"
                />
                <p class="text-muted">Optional</p>
              </VCol>
            </VRow>
            <VRow>
              <VCol :class="columnClass('authority')">
                <ValidatedForm :fm="agreementConfirmForm">
                  <template #agreementConfirm.confirmed:checkbox-label>
                    I confirm that I have read and agree to the Legal Power of Attorney (LPOA) agreement
                    <a class="link" @click.stop.prevent="downloadAgreementLink" target="_blank">here</a>.
                  </template>
                </ValidatedForm>
              </VCol>
            </VRow>
          </div>
        </AuthorisationsSettingsTable>
        <div class="my-4 text-sm-center text-md-left">
          <VButton class="secondary mr-2" label="Cancel" @click="cancelChanges" />
          <VButton
            :disabled="!isFormValid"
            class="btn-success"
            label="Save"
            @click="saveChanges"
            :loading="requestManager.anyPending"
          />
        </div>
      </BoxGridBlock>
    </BoxGrid>
  </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator';
import {
  AuthorityType,
  Authority,
  ClientType,
  UserEntityType,
  Permission,
  LegalFileType,
  ExportFileType,
} from 'ah-api-gateways';
import AuthorisationsSettingsTable from '@/app/components/settings/authorisations/AuthorisationsSettingsTable.vue';
import { makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { checkboxField, dateField } from 'ah-common-lib/src/form/models';
import { ifTest, minDate, date } from 'ah-common-lib/src/form/validators';
import { Validation } from '@vuelidate/core';
import { tap, mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { cloneDeep } from 'lodash';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { useAuthStore } from '@/app/store/authStore';

function makeExpirationDateForm(name: string) {
  return makeFormModel({
    name,
    fieldType: 'form',
    fields: [
      dateField(
        'expirationDate',
        '',
        { fieldType: 'date' },
        { minDate: minDate(), date: ifTest(date, (val) => val instanceof Date) }
      ),
    ],
  });
}

@Component({
  components: { AuthorisationsSettingsTable },
})
export default class AuthorisationsSettingsEditor extends Mixins(WithRequestManager) {
  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'authorisations') {
      this.loadAuthorities();
    }
    if (k === 'loadClientPermissions') {
      this.loadPermissionSettings();
    }
    if (k === 'saveClientPermissions') {
      this.saveChanges();
    }
  }

  private fieldData = [
    {
      authority: AuthorityType.TRADE_ON_BEHALF_OF,
      form: makeExpirationDateForm('tradeExpiration'),
      validation: null as null | Validation,
    },
    {
      authority: AuthorityType.PAY_ON_BEHALF_OF,
      form: makeExpirationDateForm('paymentExpiration'),
      validation: null as null | Validation,
    },
  ];

  private authorisations: Authority[] = [];

  private permissions: Permission[] = [];

  private permissionsSaved: Permission[] = [];

  private agreementConfirmForm = makeFormModel({
    name: 'agreementConfirm',
    fieldType: 'form',
    fields: [
      checkboxField('confirmed', '', false, {
        required: false,
        errorMessages: {
          mustAccept: 'Must confirm this to continue',
        },
      }),
    ],
  });

  created() {
    this.loadData();
  }

  get authStore() {
    return useAuthStore();
  }

  loadData() {
    if (this.authStore.loggedInIdentity?.client) {
      forkJoin([this.loadPermissionSettings(), this.loadAuthorities()]).subscribe(() => {
        this.updateExpirationFormState();
        this.agreementConfirmForm.confirmed = this.permissions.findIndex((p) => p.allow) !== -1;
      });
    }
  }

  private loadAuthorities() {
    return this.requestManager.currentOrNew(
      'authorisations',
      this.$services.authz
        .getRoleAuthorities(
          this.authStore.loggedInRole!,
          this.authStore.loggedInIdentity!.client!.type === ClientType.INDIVIDUAL
            ? UserEntityType.INDIVIDUAL_CLIENT
            : UserEntityType.COMPANY_CLIENT
        )
        .pipe(
          tap((authorities) => {
            this.authorisations = authorities;
          })
        )
    );
  }

  private loadPermissionSettings() {
    return this.requestManager.currentOrNew(
      'loadClientPermissions',
      this.$services.authz.getClientsPermissions(this.authStore.loggedInIdentity!.client!.id).pipe(
        tap((permissions) => {
          this.setPermissions(permissions);
        })
      )
    );
  }

  private setPermissions(permissions: Permission[]) {
    this.permissionsSaved = cloneDeep(permissions);
    this.permissions = permissions;
  }

  @Watch('permissions', { immediate: true, deep: true })
  private updateExpirationFormState() {
    this.fieldsData.forEach((f) => {
      f.data.form.expirationDate = f.setting.expires;
      const expirationDate = getChildModel(f.data.form, 'expirationDate')!;
      setState(expirationDate, 'readonly', !f.setting.allow);
    });
  }

  get columnClass() {
    return (type: string) => {
      return this.$mediaQuery.is('mdUp') ? `${type}-col` : 'flex-fill';
    };
  }

  get fieldsData() {
    /**
     * Table rows information present in common-lib
     *
     * As for now this will return the payment and trading authorisation
     * titles and subtitles
     */
    return this.fieldData
      .map((p) => ({
        data: p,
        setting: this.permissions.find((setting) => setting.authority === p.authority) || {
          authority: p.authority,
          allow: false,
        },
        authorisation: this.authorisations.find((a) => a.id === p.authority)!,
      }))
      .filter((p) => !!p.authorisation);
  }

  downloadAgreementLink() {
    // Bypassing request manager as this request doesn't influence the component operations
    this.$services.customerReference.downloadLegalDocument(LegalFileType.LPOA, ExportFileType.PDF).subscribe();
  }

  get partnerName(): string {
    return this.$theme.val?.name || '';
  }

  get clientName(): string {
    return this.authStore.loggedInIdentity?.client?.name || '';
  }

  get isIndividual() {
    return this.authStore.isClientUser;
  }

  get isFormValid() {
    return this.agreementConfirmForm.confirmed && !this.fieldData.find((p) => p.validation?.$invalid);
  }

  private cancelChanges() {
    this.setPermissions(this.permissionsSaved);
  }

  private saveChanges() {
    /**
     * Save all changes done by client if validation valid
     */
    if (this.agreementConfirmForm.confirmed && this.authStore.loggedInIdentity?.client) {
      this.requestManager
        .new(
          'saveClientPermissions',
          forkJoin(
            this.fieldsData.map((f) =>
              this.$services.authz.setClientsPermissions(this.authStore.loggedInIdentity!.client!.id, {
                ...f.setting,
                expires: f.data.form.expirationDate,
              })
            )
          ).pipe(mergeMap(() => this.loadPermissionSettings()))
        )
        .subscribe((permissions) => {
          this.setPermissions(permissions);
          this.$toast.success('Your authorizations have been successfully updated.');
        });
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .field-group-wrapper {
  margin-bottom: 0;
}

.btn {
  min-width: 140px;
}

.link {
  text-decoration: underline;
  @include themedTextColor($color-text, $color-dark-text);
}
</style>
