<template>
  <div>
    <h3>My Accounts</h3>
    <PartnerClientsActivityChart :fromDate="fromDate" :toDate="toDate" />
    <div class="buttons text-right">
      <RouterLink class="button view-all transparent" :to="'/admin/activity/report'">
        <span> All Accounts </span>
        <IconArrowRight />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PartnerClientsActivityChart } from 'ah-reports';

@Component({
  components: {
    PartnerClientsActivityChart,
  },
})
export default class MyAccountsDashboardWidget extends Vue {
  @Prop({ required: true }) fromDate!: Date;

  @Prop({ required: true }) toDate!: Date;
}
</script>

<style lang="scss" scoped>
.buttons {
  @include tablet {
    margin-top: 10px;
  }
  @include upToResolution($tabletResolution) {
    margin: -30px 0 -10px 0;
  }
}
</style>
