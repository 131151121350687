import { render, staticRenderFns } from "./CollateralManagementView.vue?vue&type=template&id=0efabd37&scoped=true"
import script from "./CollateralManagementView.vue?vue&type=script&setup=true&lang=ts"
export * from "./CollateralManagementView.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CollateralManagementView.vue?vue&type=style&index=0&id=0efabd37&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0efabd37",
  null
  
)

export default component.exports