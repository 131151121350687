import { render, staticRenderFns } from "./IndividualInfo.vue?vue&type=template&id=0659b7ac&scoped=true"
import script from "./IndividualInfo.vue?vue&type=script&lang=ts"
export * from "./IndividualInfo.vue?vue&type=script&lang=ts"
import style0 from "./IndividualInfo.vue?vue&type=style&index=0&id=0659b7ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0659b7ac",
  null
  
)

export default component.exports