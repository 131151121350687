<template>
  <span>
    <BModal modal-class="side-modal" ref="modal" hide-footer hide-header>
      <div class="sidebar-wrapper">
        <div class="sidebar">
          <ul class="notifications">
            <UpdatesModal v-slot="{ show }">
              <span @click="show">
                <IconUpdatesActive v-if="hasUnreadUpdates" />
                <IconUpdates v-else />
              </span>
            </UpdatesModal>
            <XHedgeNotificationsModal />
          </ul>
          <UserIconMenuMobile class="mb-3 mt-5" />
          <ClientResposiveNav v-if="isClientUser" class="main-menu" />
          <AdminResposiveNav v-else class="main-menu" />
          <div>
            <slot name="nav-siblings" />
            <NeedHelpButton class="ml-2" />
          </div>
        </div>
      </div>
    </BModal>
    <slot v-bind="{ show }"> </slot>
  </span>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import UserIconMenuMobile from '@/app/components/user/UserIconMenuMobile.vue';
import ClientResposiveNav from '@/app/components/nav/ClientResponsiveNav.vue';
import AdminResposiveNav from '@/app/components/nav/AdminResponsiveNav.vue';
import NeedHelpButton from '../common/NeedHelpButton.vue';
import UpdatesModal from '@/app/components/updates/UpdatesModal.vue';
import { useAuthStore } from '@/app/store/authStore';
import { useNotificationsStore } from 'ah-notifications/src/store';
import XHedgeNotificationsModal from './XHedgeNotificationsModal.vue';

@Component({
  components: {
    UserIconMenuMobile,
    ClientResposiveNav,
    AdminResposiveNav,
    UpdatesModal,
    XHedgeNotificationsModal,
    NeedHelpButton,
  },
})
export default class SideNavMobile extends Vue {
  $refs!: {
    modal: BModal;
  };

  get authStore() {
    return useAuthStore();
  }

  get notificationsStore() {
    return useNotificationsStore();
  }

  get isClientUser() {
    return this.authStore.isClientUser;
  }

  get hasUnreadNotifications() {
    return this.notificationsStore.unreadCount > 0;
  }

  get hasUnreadUpdates() {
    return this.notificationsStore.updateUnreadCount > 0;
  }

  show() {
    this.$refs.modal.show();
  }

  logout() {
    this.authStore.logout();
  }

  goToHelpLink() {
    window.open('https://www.alt21.com/faq/x-hedge/', '_blank');
  }

  get user() {
    return this.authStore.loggedInIdentity;
  }

  @Watch('$route.fullPath')
  onRouteChange() {
    this.$refs.modal.hide();
  }
}
</script>

<style lang="scss" scoped>
::v-deep .side-modal .modal-body {
  padding: 0 !important;
}
.sidebar-wrapper {
  height: 100%;
  .sidebar {
    height: 100%;
    width: $side-nav-width-mobile;
    position: relative;

    @include themedBackgroundColor($color-sidebar-bg);
    @include themedTextColor;
    @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');

    .main-menu {
      width: 100%;
      min-height: 65vh;
      overflow-y: scroll;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      ::v-deep ul {
        .menu-link {
          overflow: hidden;
          padding-top: 0.6rem;
          cursor: pointer;
          min-height: $side-nav-width * 0.8;

          svg {
            width: 22%;
            height: $side-nav-width * 0.5;
            position: relative;
            top: -3%;
          }

          .menu-link-text {
            font-size: 0.8rem;
            line-height: 1.2em;
            font-weight: 400;
          }

          &:hover:not(.router-link-active) {
            @include themedBackgroundColor($color-highlight-grey);
          }

          &.router-link-active {
            @include themedBackgroundColor($color-primary);
            color: white;
            font-weight: bold;

            ::v-deep svg path {
              fill: white;
            }
          }
        }
      }
    }

    ::v-deep .router-view {
      padding-top: 1rem;
      h1 {
        display: none;
      }
      .menu-header {
        font-size: 0.6rem;
        margin-top: 1rem;
        text-transform: uppercase;
        padding-left: 5em;
        padding-bottom: 0.625em;
        font-weight: 700;
        opacity: 0.6;
        @include themedTextColor;
      }
      ul {
        list-style: none;
        padding: 0;

        .menu-link {
          padding-left: 3.2rem;
          font-size: 0.8rem;
          line-height: 1.7em;
          cursor: pointer;

          &:hover:not(.router-link-active) {
            @include themedBackgroundColor($color-highlight-grey);
            @include themedTextColor;

            ::v-deep svg path {
              @include themedPropColor('fill', $color-text);
            }
          }

          &.router-link-active {
            @include themedBackgroundColor($color-dark-header-bg, $color-dark-header-bg);
            color: white;

            ::v-deep svg path {
              fill: white;
            }
          }
        }
      }
    }
  }
}

.notifications {
  width: 100%;
  text-align: end;
  padding-top: 7%;
  padding-right: 11%;
  cursor: pointer;
  svg {
    font-size: 1.4em;
  }

  > span:first-child {
    margin-right: 15px;
  }
}
</style>
