<script lang="ts" setup>
import NotificationsModal from 'ah-notifications/src/components/notifications/NotificationsModal.vue';
import { useNotificationsStore } from 'ah-notifications/src/store';
import { VButton } from 'ah-common-lib/src/common/components';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import { FeatureFlag, Notification, NotificationType, profileLinkNotificationTypes } from 'ah-api-gateways';
import { useFeatureFlag } from 'ah-common-stores/src/stores/featureFlagStore';

const notificationsStore = useNotificationsStore();

const router = useRouter();

const hasUnreadNotifications = computed(() => notificationsStore.unreadCount > 0);

const { isActive: isVariationMarginFeatureActive } = useFeatureFlag({
  featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
});
function navigateToLimitsProfile(item: Notification) {
  if (isVariationMarginFeatureActive.value && profileLinkNotificationTypes.includes(item.type)) {
    router.push('/dashboard/trades/limits-collateral');
  } else {
    switch (item.type) {
      case NotificationType.CREDIT_PROFILE_CHANGED:
        router.push('/dashboard/trades/collateral-management');
        break;
      case NotificationType.LIMITS_PROFILE_CHANGED:
        router.push('/dashboard/trades/position-limits');
        break;
    }
  }
}
</script>

<template>
  <NotificationsModal>
    <template #default="{ show }">
      <slot v-bind="{ show }">
        <span @click="show" v-b-tooltip="'Notifications'">
          <IconNotificationsActive v-if="hasUnreadNotifications" />
          <IconNotifications v-else />
        </span>
      </slot>
    </template>
    <template #notification-actions="{ item, hide }">
      <div class="text-center mt-3" v-if="profileLinkNotificationTypes.includes(item.type)">
        <VButton
          @click="
            navigateToLimitsProfile(item);
            hide();
          "
        >
          View new limit
        </VButton>
      </div>
    </template>
  </NotificationsModal>
</template>
