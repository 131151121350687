<template>
  <div v-if="sortedList && countries.length">
    <div class="country-list" v-for="(fee, index) in sortedList" :key="fee.id">
      <div class="country-list-header px-2 mb-2" v-if="isFirstLetter(index)">
        {{ fee.countryName[0] }}
      </div>
      <VRow
        class="country-item mb-2 pb-1"
        :class="lastOfSection(index) ? 'last-index' : ''"
        align-h="between"
        cols="12"
      >
        <VCol md="9" sm="7"> {{ fee.countryName }} </VCol>
        <VCol md="3" sm="5"> {{ fee.countryCode }} </VCol>
      </VRow>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { FundingCharge } from 'ah-api-gateways';
import { useSettingsStore } from '@/app/store/settingsModule';

interface CountryCodePair {
  countryName: string;
  countryCode: string;
}

@Component
export default class AcoTiersList extends Vue {
  @Prop({ required: true }) fees!: FundingCharge[];

  created() {
    this.settingsStore.loadCountries();
  }

  get settingsStore() {
    return useSettingsStore();
  }

  get countries() {
    return this.settingsStore.allCountries;
  }

  get sortedList() {
    if (this.fees.length === 0) {
      return null;
    }
    const sectionTierList = [] as Array<CountryCodePair>;
    this.fees.forEach((fee) =>
      sectionTierList.push({ countryName: this.getCountryName(fee.countryCode), countryCode: fee.countryCode })
    );
    return sectionTierList.sort((a, b) => (a.countryName > b.countryName ? 1 : -1));
  }

  private isFirstLetter(index: number) {
    if (!this.sortedList) return false;
    const diff = index !== 0 && this.sortedList[index].countryName[0] !== this.sortedList[index - 1].countryName[0];
    return index === 0 || diff;
  }

  private lastOfSection(index: number) {
    if (!this.sortedList) return false;
    if (index === this.sortedList.length - 1) return true;
    const diff = this.sortedList[index].countryName[0] !== this.sortedList[index + 1].countryName[0];
    return this.sortedList.length > index + 1 && diff;
  }

  private getCountryName(cc: String) {
    const countryName = this.countries.find((country) => country.cc === cc);
    return countryName ? countryName.name : '';
  }
}
</script>

<style lang="scss" scoped>
.country-list-header {
  margin: 0 -8px 0 -8px;
  font-weight: $font-weight-bold;
  @include themedTextColor($color-dark-text, $color-dark-text);
  @include themedBackgroundColor($color-dark-primary, $color-primary);
}

.country-item {
  font-size: 0.9em;
  &:not(.last-index) {
    @include themedPropColor('border-bottom', $color-dark-main-bg, $color-main-bg, '', ' 0.5px solid');
  }
}
</style>
