<script setup lang="ts">
import CollateralTransactionExecutor from './CollateralTransactionExecutor.vue';
import { ClientCollateralState, callTriggeredStates } from 'ah-api-gateways';
import { makeFormModel } from 'ah-common-lib/src/form/helpers';
import { radioField } from 'ah-common-lib/src/form/models';
import { formatCurrencyValue } from 'ah-common-lib/src/helpers/currency';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';
import { computed, reactive, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';

/**
 * Collateral transaction component
 * Displays transaction instructions and the transaction executor component
 *
 * Re-emits all events from CollateralTransactionExecutor
 */

const props = defineProps<{
  mode?: 'withdraw' | 'post' | 'choose';
}>();

const actionTypeFormDef = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'actionTypeForm',
    fieldType: 'form',
    fields: [
      radioField(
        'action',
        '',
        [
          { label: 'Withdraw', value: 'withdraw' },
          { label: 'Post', value: 'post' },
        ],
        {
          defaultValue: 'withdraw',
          inline: true,
        }
      ),
    ],
  }),
  validation: null,
});

const emit = defineEmits<{
  (e: 'action-changed', value: 'withdraw' | 'post' | 'choose'): void;
}>();

const onBehalfOfClient = useOnBehalfOf();

const tradeState = useAhTradesState();

const collaterals = computed(() => {
  const clientId = onBehalfOfClient.value
    ? onBehalfOfClient.value.id
    : tradeState.store.useAuthStore().loggedInIdentity?.client?.id;

  if (clientId) {
    return tradeState.store.useWalletsStore().getCollaterals(clientId);
  }
  return null;
});

const isMarginCallTriggered = computed(
  () => collaterals.value && callTriggeredStates.includes(collaterals.value.state)
);

const isAtZero = computed(() => collaterals.value?.unutilizedCollateral === 0);

const actionType = computed(() => {
  if (props.mode) {
    return props.mode;
  }
  if (collaterals.value) {
    if (!isAtZero.value) {
      if (collaterals.value.effectiveCollateralUtilization === 0) {
        return 'choose';
      }
      if (
        [
          ClientCollateralState.BELOW_RECOMMENDED_RECAP_THRESHOLD,
          ClientCollateralState.ABOVE_RECOMMENDED_RECAP_THRESHOLD,
        ].includes(collaterals.value.state)
      ) {
        return 'withdraw';
      }
    }
  }
  return 'post';
});

const chosenAction = computed(() => {
  if (actionType.value === 'choose') {
    return actionTypeFormDef.form.action ?? 'withdraw';
  }
  return actionType.value;
});

const effectiveCollateralUtilizationAfterUcWithdrawPercentage = computed(() =>
  ((collaterals.value?.effectiveCollateralUtilizationAfterUcWithdraw ?? 0) * 100).toFixed(1)
);

const effectiveCollateralUtilizationAfterRecommendedWithdrawPercentage = computed(() =>
  ((collaterals.value?.effectiveCollateralUtilizationAfterRecommendedWithdraw ?? 0) * 100).toFixed(1)
);

watch(
  actionType,
  () => {
    emit('action-changed', actionType.value);
  },
  { immediate: true }
);
</script>

<template>
  <div class="collateral-post-form" v-if="collaterals">
    <template v-if="actionType === 'post'">
      <h3>Post collateral</h3>
      <p>Post Collateral from the Available Balance in your EUR, {{ collaterals.currency }} or USD Wallets.</p>
    </template>
    <template v-else-if="actionType === 'withdraw'">
      <h3>Withdraw collateral</h3>
      <p>Withdraw collateral into your EUR, {{ collaterals.currency }} or USD Wallets.</p>
    </template>
    <template v-else>
      <h3>Manage collateral</h3>
      <p>Post collateral from or withdraw collateral into your EUR, GBP or USD Wallets.</p>
      <ValidatedForm :fm="actionTypeFormDef.form" />
    </template>
    <div class="action-messages">
      <template v-if="chosenAction === 'withdraw'">
        <p v-if="collaterals.state === ClientCollateralState.BELOW_RECOMMENDED_RECAP_THRESHOLD">
          Withdrawing {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.recommendedWithdraw) }} will leave
          your Effective Collateral Utilisation at
          {{ effectiveCollateralUtilizationAfterRecommendedWithdrawPercentage }}%.
        </p>
        <p v-else-if="collaterals.state === ClientCollateralState.ABOVE_RECOMMENDED_RECAP_THRESHOLD">
          Withdrawing {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.unutilizedCollateral) }} will leave
          your Effective Collateral Utilisation at {{ effectiveCollateralUtilizationAfterUcWithdrawPercentage }}%.
        </p>
      </template>
      <template v-else-if="chosenAction === 'post'">
        <p v-if="isMarginCallTriggered">
          You MUST post {{ collaterals.currency }} {{ formatCurrencyValue(collaterals.recapitalizationAmount) }} or
          equivalent. However, it is recommended that you post at least {{ collaterals.currency }}
          {{ formatCurrencyValue(collaterals.recommendedRecapitalizationAmount) }} to avoid unexpected further Margin
          Calls.
        </p>
      </template>
    </div>
    <CollateralTransactionExecutor :mode="chosenAction" :collaterals="collaterals" v-on="$listeners" />
  </div>
</template>
