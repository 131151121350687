<script setup lang="ts">
import PersonalDetailsEditor from '@/app/components/settings/individual/PersonalDetailsEditor.vue';
import ProfilePhotoEditor from '@/app/components/settings/individual/ProfilePhotoEditor.vue';
import FileUploadCounter from 'ah-common-lib/src/common/components/upload/FileUploadCounter.vue';
import DocumentsUploader from '@/app/components/settings/client/DocumentsUploader.vue';
import { ClientFileCategories, UploadedFile } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { computed, ref } from 'vue';

const files = ref<UploadedFile[]>([]);

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const companyFileCategories = computed(() => [
  ClientFileCategories.PROOF_OF_ADDRESS,
  ClientFileCategories.PROOF_OF_FUNDS,
  ClientFileCategories.PHOTO_ID,
]);

const numberOfFiles = computed(
  () => files.value.filter((file) => companyFileCategories.value.includes(file.category as ClientFileCategories)).length
);

const isClientUser = authStore.isClientUser;

const isIndividualClientUser = computed(
  () => individualSettingsStore.client && !authStore.isAgent && !authStore.isCompanyClient
);

const client = computed(() => individualSettingsStore.client);
</script>

<template>
  <div class="padded-space" id="personal-settings-view">
    <div class="centered-space">
      <h2>Personal Info</h2>
      <BoxGrid align-h="start">
        <BoxGridBlock cols="12" xl="5" :md="isIndividualClientUser ? 12 : 7" sm="12">
          <h2>Personal Settings</h2>
          <PersonalDetailsEditor />
        </BoxGridBlock>
        <BoxGridBlock cols="6" xl="5" md="5" sm="12" v-if="isClientUser">
          <FileUploadCounter header-title="KYC Documents" :numberOfFiles="numberOfFiles">
            <DocumentsUploader
              :hideUpdatedBy="false"
              :show-titles="false"
              :files.sync="files"
              :client="client"
              force-individual
            />
          </FileUploadCounter>
        </BoxGridBlock>
        <BoxGridBlock cols="6" xl="2" md="5" sm="12">
          <h2>Profile photo</h2>
          <ProfilePhotoEditor />
        </BoxGridBlock>
      </BoxGrid>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
