<template>
  <div class="appearance-options">
    <VRow>
      <VCol>
        <label for="dark-mode-switch"> Dark Mode </label>
      </VCol>
      <VCol>
        <BFormCheckbox id="dark-mode-switch" v-model="settingsStore.darkMode" name="check-button" switch />
      </VCol>
    </VRow>
  </div>
</template>

<script lang="ts">
import { useSettingsStore } from '@/app/store/settingsModule';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppearanceOptions extends Vue {
  get settingsStore() {
    return useSettingsStore();
  }
}
</script>
