<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useFormElement } from '../../composables/useFormElement';
import { makeUseFormElementEmits, makeUseFormElementProps } from '../../composables/useFormElementInterfaces';
import { FieldModel } from '../../interfaces';
import { useBaseFieldValues } from '../../composables/useBaseFieldValues';
// importing directly to avoid non-extracted css issues from built code
import VueSlider from 'vue-slider-component';

const props = defineProps(makeUseFormElementProps<FieldModel, number>());

const emit = defineEmits(makeUseFormElementEmits<number>());

const inputValue = ref('');

const inputEl = ref<HTMLInputElement>();

const formElement = useFormElement({
  emit,
  props,
});

const { fieldErrorsShown, readonly, inputErrorClass, inputClass } = useBaseFieldValues(formElement);

const { modelStateRef, model, field, getSlotName, getModelState, setValue } = formElement;

const cappedValue = computed(() => {
  if (dataPoints.value) {
    return field.value.$model;
  }
  return Math.max(min.value, Math.min(max.value, field.value.$model));
});

const max = computed(() => {
  const max = getModelState('max', 100);
  return getModelState('maxValue', max);
});

const min = computed(() => {
  const min = getModelState('min', 0);
  return getModelState('minValue', min);
});

const valueToInputFn = modelStateRef('valueToInputFn', (val: number) => val.toString());

const inputToValueFn = modelStateRef('inputToValueFn', (val: string) => parseFloat(val));

const withMathFloor = modelStateRef('withMathFloor', true);

const interval = modelStateRef('interval', 1);

const dataPoints = modelStateRef('data', undefined);

function setInputValue(event: any) {
  if (!event.target) return;

  let value!: number;

  try {
    value = inputToValueFn.value(event.target.value);
  } catch (e) {
    return;
  }

  if (dataPoints.value) {
    if (dataPoints.value.includes(+value)) {
      setValue(+value);
    } else {
      const dataSorted = (dataPoints.value as number[]).sort();
      for (let i = 0; i < dataSorted.length; i += 1) {
        if (dataSorted[i] > +value || i === dataSorted.length - 1) {
          setValue(+dataSorted[i]);
          return;
        }
      }
    }
  } else {
    const constrainedValue = Math.max(min.value, Math.min(max.value, value));

    if (withMathFloor.value) {
      setValue(Math.floor((constrainedValue - min.value) / interval.value) * interval.value + min.value);
    } else {
      setValue(((constrainedValue - min.value) / interval.value) * interval.value + min.value);
    }
  }
}

function updateValueFromModel() {
  inputValue.value = valueToInputFn.value(field.value.$model);
}

watch(
  () => field.value.$model,
  () => {
    if (inputEl.value !== document.activeElement) {
      updateValueFromModel();
    }
  },
  { immediate: true }
);

updateValueFromModel();
</script>

<template>
  <div class="d-flex">
    <div class="slider-container">
      <VueSlider
        ref="slider"
        :value="cappedValue"
        :disabled="readonly"
        v-bind="model.$state"
        class="slider-input"
        @change="setValue($event)"
      >
        <template v-slot:label="{ active, label }">
          <slot :name="getSlotName('mark-label')" v-bind="{ field, model, active, label }">
            <div :class="['vue-slider-mark-label', 'custom-label', { active }]">
              {{ label }}
            </div>
          </slot>
        </template>
      </VueSlider>
      <slot :name="getSlotName('extra-content')" v-bind="{ field, model }" />
    </div>
    <div class="input-container">
      <input
        ref="input"
        v-model="inputValue"
        type="text"
        inputmode="numeric"
        :class="['field-group-field-input', inputClass, { [inputErrorClass]: fieldErrorsShown }]"
        @blur="updateValueFromModel"
        @input="setInputValue"
      />
    </div>
  </div>
</template>

<style lang="scss">
.slider-container {
  flex-grow: 1;
  margin-right: 1rem;
  position: relative;

  .slider-input {
    &.vue-slider {
      height: 2px !important;
      .vue-slider-rail {
        .vue-slider-process {
          background-color: $common-color-purple;
        }

        .vue-slider-dot {
          .vue-slider-dot-handle {
            background-color: $common-color-purple;
          }

          .vue-slider-dot-handle-focus {
            box-shadow: 0px 0px 1px 3px rgba($common-color-purple, 0.2);
          }

          .vue-slider-dot-tooltip-inner {
            border-color: $common-color-purple;
            background-color: $common-color-purple;
          }
        }
      }
    }
  }
}

.input-container {
  width: 5rem;
  margin-left: 1rem;

  @include desktop {
    width: 5rem;
  }
}

.zigzag-labels .slider-input.vue-slider {
  .vue-slider-mark:nth-child(even) {
    .vue-slider-mark-label {
      padding-top: 1.2em;
    }
  }

  &.with-spot-rate {
    .vue-slider-mark {
      &:first-child .active:not(.is-rate),
      &:last-child .active:not(.is-rate) {
        display: none;
      }
    }
  }
}
</style>
